@import 'src/styles/variables';

.container {
  flex: 1;

  &:global(.isError) {
    :global(.InputAutoSaveLabel) {
      border-bottom: 1px solid #ff3636;
    }
  }

  &:global(.isDisable) {
    & > * {
      cursor: not-allowed;
    }
  }

  @include phone {
    margin-top: 10px;
    width: 100%;
  }

  .item {
    display: block;
    &:global(.isHide) {
      display: none;
    }
  }
}
