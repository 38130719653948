@import 'src/styles/variables';

.tagWrapper {
  max-width: 100%;
  &:global(.isDisable) {
    .tagView {
      pointer-events: none;
      &:hover {
        background: #e3ecff;
        padding: 6px 8px;
        .close {
          display: none;
        }
      }
    }
  }
}

.tagView {
  background: $whiteblue;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  margin-right: 8px;
  border: 1px solid $whiteblue;
  position: relative;
  padding: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  line-break: anywhere;
  transition: 0.1s;

  &:last-of-type {
    margin-right: 0;
  }

  &:global(.isSaved) {
    background: #e6fce4;
    border: 1px solid #e6fce4;
  }

  &:global(.isBlueBorder) {
    border: 1px solid $blue;
  }

  &:global(.isShow) {
    display: none;
  }

  &:global(.isError) {
    background: transparent;
    border: 1px solid $red;
  }

  .close {
    border: none;
    display: block;
    position: static;
    transform: none;
    opacity: 0;
    transition: 0s;

    svg {
      color: $blue;
      cursor: pointer;
      vertical-align: middle;
    }
  }

  &:hover {
    background: transparent;
    padding: 6px;

    & .close {
      display: flex;
      align-items: center;
      opacity: 1;
    }

    & .main_value {
      opacity: 1;
    }

    & .plug_value {
      opacity: 0;
    }
  }

  & .main_value {
    opacity: 0;
    transition: 0s;
  }

  & .plug_value {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    opacity: 1;
    transition: 0s;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    pointer-events: none;
  }
}

.editWrapper {
  margin-right: 8px;
  display: none;
  &:global(.isShow) {
    display: flex;
  }
}
