@import 'src/styles/variables';

.form-group {
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 24px;

  & .zip {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f6f6;
    border-radius: 1px;
  }

  & > label {
    color: $text-hint;
    font-size: 12px;
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
  }

  & .preview {
    & .title {
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #828282;
    }
    & .params {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      &:global(._center) {
        align-items: center;
        display: flex;
      }

      &:global(._yandex) {
        display: grid;
        grid-template-columns: 56px 1fr auto;
        align-items: center;
        grid-gap: 0 16px;
      }

      & .image {
        width: 56px;
        height: 56px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 16px;
        background-color: #e2e2e2;

        &:global(.isLarge) {
          width: 85px;
        }

        & + .description {
          max-width: calc(100% - 220px);
        }
      }

      & .video {
        width: 150px;
        height: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 16px;
        position: relative;
        &__during {
          position: absolute;
          bottom: 8px;
          right: 8px;
          color: #fff;
          font-size: 10px;
        }
        &:global(.isLarge) {
          width: 250px;
        }
      }

      & .filename {
        font-size: 14px;
        line-height: 22px;
        color: $blue;
        max-width: 100%;
        display: block;
        word-break: break-all;

        span {
          display: inline;
        }

        svg {
          display: inline;
          position: relative;
          left: 4px;
          top: 2px;
        }
      }

      & .description {
        flex: 1 0 auto;
        max-width: calc(100% - 320px);
        margin-top: 4px;

        & .size {
          font-size: 14px;
          line-height: 22px;
          color: $black;
          margin-bottom: 4px;
        }
      }

      & .actions {
        flex: 1 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }

  & .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: fade-out($color: $white, $amount: 0.15);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  & .hintText {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
    line-height: 1.3;
    font-size: 13px;
    line-height: 20px;
    color: $black;

    svg {
      color: $black;
      margin-right: 6px;
      flex-shrink: 0;
    }
  }

  & .errorText {
    color: $red;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    line-height: 1.3;

    svg {
      color: $red;
      margin-right: 6px;
      flex-shrink: 0;
    }
  }

  & .link {
    &:global(._isError) {
      label {
        color: $red;
      }

      & .row {
        input {
          border: 1px solid $red;

          &:focus {
            border-color: $red;
          }
        }
      }
    }

    label {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #828282;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
    }

    & .row {
      display: flex;

      input {
        background: $white;
        border: 1px solid $grey;
        box-sizing: border-box;
        border-radius: 1px;
        height: 40px;
        flex: 1 0 auto;
        padding-left: 14px;
        padding-right: 48px;
        font-size: 14px;
        color: $black;
        margin-right: 16px;
        transition: border 0.1s;

        &:focus {
          outline: none;
          border: 1px solid $blue;
        }
      }
    }
  }

  & .upload {
    position: relative;
    display: inline-flex;
    overflow: hidden;

    button {
      position: relative;
      z-index: 1;
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 2;
    }

    input {
      display: none;
    }

    &:global(._isDisabled) {
      label {
        cursor: not-allowed;
      }
    }
  }

  :global {
    .react-tabs__tab-list {
      margin: 0;
      padding: 0;
    }
  }
}

.remove {
  display: flex;
  cursor: pointer;
  font-size: 13px;
  align-items: center;
  margin-top: 5px;
  width: fit-content;
  svg {
    margin-right: 8px;
  }
}