.screenshot_wrapper {
  height: 255px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  & .screen_img {
    min-height: 188px;
  }
  & .download {
    width: 100%;
  }
}
