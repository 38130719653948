@import 'src/styles/variables';

.fixedLoader {
  position: fixed;
  top: 106px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, 0.4);
}
.center {
  display: flex;
  justify-content: center;
}

.targeting {
  & .content {
    padding: 28px 0;
  }

  & .form {
    margin-bottom: 8px;

    & > div {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
