@import 'src/styles/variables';

.container {
  @include campaignGrid;
}

.header {
  background: $white;
  height: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 3;

  & .status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;

    & .title {
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
}
