@import 'src/styles/variables';

.history {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
    background-color: #fff;
  }
  &_update {
    position: relative;
    display: flex;
    cursor: pointer;
  }
  &_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  &_content {
    padding-right: 2px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  }
  &_row {
    font-size: 14px;
    line-height: 22px;
    border: 1px solid #149c0800;
    border-radius: 8px;
    padding: 11px 14px 8px 14px;
    margin-bottom: 4px;
    border-bottom: 1px solid #f2f2f2;

    &:last-of-type {
      border-bottom: 1px solid #149c0800;
      margin-bottom: 0;
    }
  }
  &_btn {
    display: block;
    cursor: pointer;
    font-size: 14px;
    line-height: 157%;
    color: #025dff;
    padding: 0 14px;
    padding-top: 10px;
  }
}

.row {
  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    position: relative;
    height: 16px;
    align-items: center;
  }
  &_email {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: #222222;
    width: 55%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    strong {
      font-weight: bold;
    }
    &:hover {
      width: 100%;

      & ~ .row_date {
        display: none;
      }
    }
  }
  &_date {
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    letter-spacing: 0.03em;
    color: #828282;
    display: flex;
    align-items: center;
    opacity: 1;
    transition: 0.2s;

    svg {
      margin-right: 10px;
    }
  }
  &_text {
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 150%;
    color: #222;

    height: auto;
    text-overflow: clip;
    overflow: inherit;
    display: block;
    white-space: pre-line;
    word-break: break-word;

    &:global(._isCollapseText) {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }

    strong {
      font-weight: 500;
    }
    span {
      color: #828282;
    }
  }
  &_action {
    cursor: pointer;
    color: $blue;
    font-size: 12px;
    line-height: 150%;
  }
}
