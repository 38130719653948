@import 'src/styles/variables';

.form-group {
  position: relative;
  display: inline-block;

  select {
    display: none;
  }

  &__select {
    position: relative;
    cursor: pointer;
  }

  &__active {
    background: $white;
    box-sizing: border-box;
    border-radius: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: $black;
    transition: border 0.1s;
    position: relative;

    &:global(._isOpened) {
      svg {
        transform: rotate(180deg);
      }
    }

    &:focus {
      outline: none;
      border: 1px solid $blue;
    }

    svg {
      position: absolute;
      top: calc(50% - 12px);
      right: -24px;
      transition: transform 0.1s;
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__dropdown {
    z-index: 10;
    position: absolute;
    top: calc(100% + 4px);
    left: -0px;
    width: 205px;
    background: $white;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    border-radius: 1px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.1s ease;

    &:global(._isOpened) {
      opacity: 1;
      visibility: visible;
    }
  }

  &__item {
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 14px;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    color: $black;
    background: $tr;
    transition: background 0.1s;
    text-transform: capitalize;

    &:global(._current) {
      color: #828282;
      cursor: default;

      &:hover {
        background: #fff;
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    svg {
      margin-right: 16px;
    }
  }

  & > div {
    position: relative;
  }

  &_error {
    label {
      color: $red;
    }

    input {
      border: 1px solid $red;

      &:focus {
        border-color: $red;
      }
    }
  }
}
