@import 'src/styles/variables';

$widthTitle: 600px;

.item {
  grid-template-columns: 40px $widthTitle auto;
  display: grid;
  align-items: center;
  grid-gap: 0 10px;
  cursor: pointer;
  position: relative;

  &:global(._isLastEdited) {
    @include last-edited-row-animation;

    & .indicator {
      @include last-edited-indicator-animation;
    }
  }

  & .indicator {
    @include indicator;
    left: 0;
  }

  &:hover {
    background-color: $backlightItems;
  }

  &:global(._isPreloader) {
    cursor: default;
  }

  &:global(._isDeleted) {
    pointer-events: none;
    opacity: 0.7;
  }

  @keyframes item-fadeIn {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  & > a {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  &:global(._isOpen) {
    grid-template-columns: 40px calc(100% - 400px) auto;

    .item__title {
      max-width: $widthTitle;
    }
  }

  & > div {
    transition: all 0.2s ease;

    &:last-child {
      justify-self: end;
    }
  }

  & .toggle__status {
    position: relative;
    z-index: 2;
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    max-width: $widthTitle;
  }

  &__logo {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;
    display: block;
    flex: none;
    margin-right: 16px;
  }

  &__defaultLogo {
    display: block;
    flex: none;
    margin-right: 16px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #cbcbcb;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;

    &:global(._isPreloader) {
      animation: load-item 1.1s infinite;
    }
  }

  &__title {
    max-width: $widthTitle;
    word-break: break-all;
    a {
      opacity: 1;
    }
    & .tooltip {
      margin-left: 0;
      z-index: 10;
      width: 100%;
    }

    strong {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #222222;
    }
  }

  &__subtitle {
    margin-top: 4px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__status {
    display: grid;
    grid-gap: 0 14px;
    align-items: center;
    justify-content: start;
    grid-auto-flow: column;

    div {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 2px solid #149c08;
      background: #c8fcc3;
    }

    span {
      color: #149c08;
      font-size: 14px;
      line-height: 22px;
    }

    &:global(._isStop) {
      div {
        background: #fff2c5;
        border-color: #ff9c08;
      }

      span {
        color: #ff9c08;
      }
    }
  }
}

.actions {
  display: flex;
  align-items: center;

  & > div {
    &:first-child {
      z-index: 4;
    }
  }
}

.dropdown {
  z-index: 2;
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  opacity: 1;
  visibility: visible;

  &:global(._isOpen) {
    & .dropdown__show {
      background: #f4f5f6;
    }

    & .dropdown__menu {
      a {
        &:hover {
          opacity: 1;
        }
      }
      & > div {
        animation: show-item 0.2s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;

        &:nth-last-child(1) {
          animation: none;
          opacity: 1;
        }

        @for $i from 2 to 10 {
          &:nth-last-child(#{$i}) {
            animation-delay: #{($i * 0.1) - 0.1}s;
          }
        }
      }

      .limits {
        svg {
          transform: rotate(90deg);
        }
      }

      @keyframes show-item {
        0% {
          transform: translateY(40px);
          opacity: 0;
        }
        100% {
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }
  }

  &:global(._isDeleted) {
    & .dropdown__menu {
      right: 55px;
    }
  }

  &:global(._isNoAnimate) {
    & .dropdown__menu {
      & > div {
        animation: show-item 0s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;

        @for $i from 2 to 10 {
          &:nth-last-child(#{$i}) {
            animation-delay: 0s;
          }
        }
      }
    }
  }

  & .dropdown__show {
    opacity: 1;
    z-index: 0;
  }

  & .dropdown__menu {
    opacity: 1;
    visibility: visible;
  }

  &__show {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #828282;

    &:disabled {
      cursor: default;
    }
  }

  &__menu {
    position: absolute;
    right: 60px;
    top: 0;
    bottom: 0;
    padding-right: 0px;
    display: grid;
    height: 100%;
    grid-auto-flow: column;
    grid-gap: 0 25px;
    align-items: center;
    justify-content: end;
    opacity: 0;
    visibility: hidden;
    z-index: -1;

    &:before {
      position: absolute;
      content: '';
      top: -20px;
      bottom: -20px;
      left: 70px;
      right: -110px;
      z-index: 0;
    }

    & > div {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      opacity: 0;

      &:last-child {
        animation: none;
        opacity: 1;
      }

      &:global(.disabled) {
        pointer-events: none;
        cursor: not-allowed !important;

        svg {
          color: #828282;
        }
      }

      span {
        display: block;
        padding: 4px 10px;
        background: #222222;
        position: absolute;
        bottom: 115%;
        left: 50%;
        white-space: nowrap;
        transform: translateX(-50%);
        color: #fff;
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        opacity: 0;
        visibility: hidden;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 8px 0 8px;
          border-color: #222222 transparent transparent transparent;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &:hover {
        span {
          opacity: 1;
          visibility: visible;
        }
        svg {
          color: #025dff;
        }
      }
    }
  }
}

@include loadItemKeyframe;
