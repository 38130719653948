@import 'src/styles/variables';

.limits_widget {
  .dropdown {
    padding: 6px 8px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 22px;
    position: relative;

    svg {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      stroke: #828282;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .name {
      color: #828282;
      font-size: 12px;
      line-height: 18px;
    }

    .value {
      text-align: right;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.actions {
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 5;
}

.update {
  display: flex;
  cursor: pointer;

  &_status {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background: #149c08;
    border: 1px solid #ffffff;
  }
}
