.error_wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  min-height: 248px;
  margin-top: -7px;

  & .text_content {
    display: flex;
    min-height: 110px;
    align-items: center;
    flex-direction: column;

    h4 {
      margin: 0;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
