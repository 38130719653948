@import 'src/styles/variables';

.form-group {
  position: relative;
  width: 100%;

  &:global(.horizontal) {
    display: flex;
    align-items: center;
    width: auto;
    flex-wrap: wrap;
    gap: 3px;

    @include phone {
      width: 100%;
      margin-top: 10px;
    }

    :global(.inputSelect__control) {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;

      &:global(--menu-is-open) {
        border-bottom: 1px solid $blue !important;
        :global(.inputSelect__indicator) {
          svg {
            color: $blue;
          }
        }
      }
    }

    :global(.ErrorText) {
      margin-top: 0px;
      width: 100%;
      justify-content: flex-end;
    }

    label {
      margin-bottom: 0;
      display: inline-block;
      margin-right: 24px;
      flex: none;
    }

    & .title {
      flex: none;
      padding: 0 0 0 16px;
      border-bottom: 1px solid $grey;
      line-height: 32px;
    }
  }

  label {
    color: $text-hint;
    font-size: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
    word-break: break-all;
    display: flex;
    align-items: center;
  }

  & .title {
    font-size: 14px;
    line-height: 22px;
    color: #222222;
    word-break: break-all;

    &:global(.isUppercase) {
      text-transform: uppercase;
    }
  }

  select {
    display: none;
  }

  &__select {
    display: block;
    position: relative;
    cursor: pointer;
  }

  &__active {
    background: $white;
    border: 1px solid $grey;
    box-sizing: border-box;
    border-radius: 1px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 14px;
    padding-right: 48px;
    font-size: 14px;
    line-height: 24px;
    color: $black;
    transition: border 0.1s;
    position: relative;

    &:global(._isOpened) {
      svg {
        transform: rotate(180deg);
      }
    }

    &:global(._isError) {
      border: 1px solid $red;
    }

    &:focus {
      outline: none;
      border: 1px solid $blue;
    }

    svg {
      position: absolute;
      top: calc(50% - 12px);
      right: 10px;
      transition: transform 0.1s;
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__dropdown {
    z-index: 1;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: $white;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    border-radius: 1px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.1s ease;

    &:global(._isOpened) {
      opacity: 1;
      visibility: visible;
    }
  }

  &__item {
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 14px;
    padding-right: 48px;
    font-size: 14px;
    color: $black;
    background: $tr;
    transition: background 0.1s;

    &:global(._current) {
      color: #828282;
      cursor: default;

      &:hover {
        background: #fff;
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  & > div {
    position: relative;
  }

  &_error {
    label {
      color: $red;
    }

    input {
      border: 1px solid $red;

      &:focus {
        border-color: $red;
      }
    }
  }

  &:global(.isDisabled) {
    cursor: not-allowed;

    .form-group {
      pointer-events: none;
      &__active {
        background: #f6f6f6;
        color: $text-hint;
      }
    }
  }

  &:global(.isUppercase) {
    text-transform: uppercase;
  }

  &:global(.error) {
    :global(.inputSelect__control) {
      border: 1px solid $red;
    }
  }

  :global(.inputSelect__input) {
    width: 100% !important;
    input {
      width: 100% !important;
    }
  }

  :global(.inputSelect__value-container) {
    & > div {
      width: 100%;
    }
  }
}

:global(.inputSelect__menu-portal) {
  z-index: 99999 !important;
}
