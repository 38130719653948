.owner {
  &:global(.isLocked) {
    cursor: not-allowed !important;
    filter: grayscale(100%);
    z-index: 2;

    .notEvents {
      pointer-events: none !important;
    }
  }

  &:global(.isHide) {
    display: none;
  }
}
