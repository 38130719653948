.charts-nav {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 8px;
  justify-content: flex-start;
  margin-bottom: 15px;

  & > button {
    padding: 0 10px;
    margin: 0;
    background: #f6f6f6;
    border-radius: 1px;
    font-size: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    min-width: 58px;
    color: #222222;
    letter-spacing: 0.3px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.7;
    }

    &:global(._isActive) {
      background: #e3ecff;
      color: #025dff;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.no-data {
  height: 252px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 28px;

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      max-width: 100%;
    }
  }

  & .text {
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #222222;
    text-align: center;
  }
}

.loader {
  background: #f4f5f6;
  height: 350px;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 5;
}

.update {
  display: flex;
  cursor: pointer;

  &_status {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background: #149c08;
    border: 1px solid #ffffff;
  }
}