@import 'src/styles/variables';

.card {
  padding: 0;
  position: relative;
}

.copy {
  position: absolute;
  top: 28px;
  right: 0px;

  & > div {
    margin-left: 0;
  }

  & .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    cursor: pointer;
  }
}

.tabs {
  margin-top: 24px;
  position: relative;
}

.download_btn {
  position: absolute;
  right: 22px;
  top: 28px;
  cursor: pointer;
  svg {
    transition: 0.3s;
    vertical-align: middle;
  }
  &:hover {
    svg {
      color: #025dff;
    }
  }
}

.panel {
  padding-top: 0;
}

.table {
  &:global(.isLinksTable) {
    .item {
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 24px 24px 12px auto;
      max-width: 100%;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -22px;
        right: -22px;
        border-bottom: 1px solid #f3f3f3;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      .number {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: $black;
        margin-top: 3px;
      }

      .type {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      }

      .status {
        margin-top: 6px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid $white;

        &:global(.bg-STOPPED) {
          background: $orange;
        }

        &:global(.bg-LAUNCHED) {
          background: $green;
        }

        &:global(.bg-DELETED) {
          background: $red;
        }
      }

      .data {
        margin-top: 2px;

        .title {
          margin-bottom: 16px;
          font-size: 14px;
          line-height: 22px;
          word-break: break-word;

          & > a {
            color: $blue;
          }
        }

        .links {
          .link {
            padding-top: 14px;
            display: grid;
            grid-gap: 16px;
            grid-template-columns: 128px 1fr;

            &:first-child {
              padding-top: 0;
            }

            .name {
              font-size: 12px;
              line-height: 16px;
              color: #828282;
              word-break: break-word;
            }

            .value {
              font-size: 12px;
              line-height: 16px;
              color: $black;
              word-break: break-all;
            }
          }
        }
      }
    }
  }

  &:global(.isApproveTable) {
    .item {
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 24px 24px 12px auto auto;
      max-width: 100%;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -22px;
        right: -22px;
        border-bottom: 1px solid #f3f3f3;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      .number {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: $black;
        margin-top: 3px;
      }

      .type {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      }

      .status {
        margin-top: 6px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid $white;

        &:global(.bg-STOPPED) {
          background: $orange;
        }

        &:global(.bg-LAUNCHED) {
          background: $green;
        }

        &:global(.bg-DELETED) {
          background: $red;
        }
      }

      .title {
        margin-top: 1px;
        font-size: 14px;
        line-height: 22px;
        word-break: break-all;

        & > a {
          color: $blue;
        }
      }

      .moderation {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding-top: 3px;

        .param {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 50px;
          width: 300px;

          &:first-child {
            margin-left: 0;
            margin-top: -3px;
            .name {
              display: none;
            }
          }

          &:last-child {
            width: 300px;
          }

          .name {
            font-size: 12px;
            line-height: 16px;
            color: #828282;
            flex: none;
          }

          .value {
            font-size: 12px;
            line-height: 16px;
            color: $black;
            margin-left: 4px;
            flex: auto;
            white-space: nowrap;

            .mod_status {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              &:global(.color-CONDITIONALLY_APPROVED),
              &:global(.color-DISAPPROVED) {
                color: $red;
              }
              &:global(.color-APPROVED) {
                color: $green;
              }
              &:global(.color-ON_MODERATION),
              &:global(.color-NOT_CHECKED),
              &:global(.color-WAIT_SENDING),
              &:global(.color-AWAITING_DATA_COMPLETION),
              &:global(.color-UNKNOWN),
              &:global(.color-READY),
              &:global(.color-NOT_IN_API) {
                color: $orange;
              }

              .text {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.EmptyLink {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    font-size: 16px;
    font-weight: 500;
  }
}
