@import 'src/styles/variables';

.widget {
  & .video {
    min-height: 180px;
    width: 100%;
    position: relative;
    background-size: cover;
    margin-bottom: 10px;
    &__duration {
      position: absolute;
      right: 4px;
      bottom: 4px;
      font-size: 12px;
      color: #fff;
    }
  }
  & .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
    margin-bottom: 28px;
  }

  .actions {
    button {
      display: block;
      width: 100%;
    }

    .copy_btns {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  & .hint {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    svg {
      flex: none;
    }

    span {
      flex: 1 0 auto;
      max-width: calc(100% - 32px);
      font-size: 13px;
      line-height: 20px;
      color: $black;
    }
  }
}
