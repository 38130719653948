@import 'src/styles/variables';

$heightSvg: 113px;
$svgMB: 10px;
$textMB: 24px;
$btnHeight: 48px;

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_text {
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #222;
    margin-bottom: $textMB;
  }

  svg {
    margin-bottom: $svgMB;
    height: $heightSvg;
    width: auto;
  }
}

.creating {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;

  &:global(._isNoText) {
    .creating_icon {
      margin-bottom: 0;
    }
  }

  &_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    // height: calc(#{$heightSvg} + #{$textMB} + #{$btnHeight});
    margin-bottom: $svgMB;
  }
}

.crated {
  display: flex;
  gap: 22px;

  .archive {
    color: #828282;
  }

  &_info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      text-overflow: ellipsis;
      a {
        color: #025dff;
      }
    }

    .uid {
      font-size: 12px;
      color: #828282;
    }
  }
}

.actions {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
}

.update {
  display: flex;
  cursor: pointer;

  &_status {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background: #149c08;
    border: 1px solid #ffffff;
  }
}
