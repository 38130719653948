@import 'src/styles/variables';

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  // z-index: 1;

  &:global(.isSearchOpened) {
    width: 100%;
  }
}

.search {
  margin-right: 0;
  padding: 0;

  &:global(.isActive) {
    padding-right: 10px;
  }
}

.buttons {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}
