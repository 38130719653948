@import 'src/styles/variables';

.header {
  display: flex;
  position: relative;
  transform: translate(0);
  z-index: 2;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;

  & .fixed {
    width: 240px;
    position: relative;
    z-index: 3;
    border-right: 1px solid #ebebeb;

    :global(._isAlignRight) {
      :global(.HeaderCell_button) {
        justify-content: flex-start;
      }
    }
  }

  & .scroll {
    width: calc(100% - 240px);
    display: flex;
    overflow: hidden;
    position: relative;
    justify-content: space-between;

    &::-webkit-scrollbar {
      display: none;
    }

    :global(.HeaderCell) {
      &:first-child {
        :global(.HeaderCell_text) {
          padding-left: 22px;
        }
      }
    }

    & .cell {
      min-width: 115px;
      .last-child {
        padding-right: 22px;
      }
    }
  }

  & .cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    height: 40px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $black;
    text-align: left;
    white-space: nowrap;
    position: relative;
    flex: none;
    z-index: 1;
    max-width: 100%;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
