@import 'src/styles/variables';

.filter {
  position: relative;

  & .active {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:global(.isOpened) {
      .chevron {
        transform: rotate(180deg);
      }
    }

    & .type {
      width: 12px;
      height: 12px;
      border-radius: 50%;

      &:global(.type-) {
        background: url('./all.svg');
        background-position: center;
        background-size: cover;
      }
      &:global(.type-LAUNCHED) {
        background: $blue;
        border: 1px solid #d1d0d0;
      }
      &:global(.type-STOPPED) {
        background: #b9b9b9;
        border: 1px solid #d1d0d0;
      }
      &:global(.type-DELETED) {
        background: #ebebeb;
        border: 1px solid #d1d0d0;
      }
    }

    & .chevron {
      display: block;
      flex: none;
      transition: transform 0.1s;
      width: 16px;
      height: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.list {
  position: fixed;
  z-index: -1;
  opacity: 0;
  background: $white;
  box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
  transform: translateY(-20px);
  transition: transform 0.3s;

  &:global(.isOpened) {
    transform: translateY(0);
    opacity: 1;
    z-index: 1000;
  }

  & .item {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    display: flex;
    align-items: center;
    padding: 8px 17px 8px 9px;
    cursor: pointer;

    &:hover {
      background: #f6f6f6;

      .text {
        color: #222;
      }

      & .toggle {
        :global(.text) {
          color: #222;
        }
      }
    }

    &:global(.isActive) {
      .type {
        background: $green;
      }
    }

    &:global(.isStopped) {
      .type {
        background: $orange;
      }
    }

    &:global(.isDeleted) {
      .type {
        background: $red;
      }
    }

    & .toggle {
      :global(.text) {
        text-transform: capitalize;
        color: #828282;
        margin-left: 9px;
      }
    }

    & .type {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: url('./all.svg');
      background-position: center;
      background-size: cover;
      margin-left: 4px;
      margin-right: 4px;
    }

    & .text {
      text-transform: capitalize;
      color: #828282;
      margin-left: 9px;
    }
  }
}
