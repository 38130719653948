@import 'src/styles/variables';

.item {
  background: $white;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  padding: 17px 26px 17px 22px;
  grid-template-columns: 280px 300px 300px auto;
  display: grid;
  align-items: center;
  grid-gap: 0 30px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;

  @include laptop {
    grid-template-columns: 280px auto auto auto;
  }

  & > a {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  &:global(._isOpen) {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;

    & > div {
      &:nth-child(3),
      &:nth-child(4) {
        display: none;
      }
    }

    & > a {
      display: none;
    }

    & .item__header {
      & > div {
        &:last-child {
          overflow: inherit;
        }
      }
    }
  }

  &:hover {
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }

  & > div {
    transition: all 0.2s ease;

    &:last-child {
      justify-self: end;
    }
  }

  &__header {
    display: grid;
    align-items: center;
    grid-gap: 0 16px;
    grid-auto-flow: column;
    justify-content: start;
    max-width: 280px;

    & > div {
      &:first-child {
        border-radius: 50%;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #3137bd;
        font-weight: 500;
        font-size: 14px;
      }

      &:last-child {
        overflow: hidden;

        strong {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        span {
          color: #828282;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  &__status {
    display: grid;
    grid-gap: 0 14px;
    align-items: center;
    justify-content: start;
    grid-auto-flow: column;

    div {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 2px solid #149c08;
      background: #c8fcc3;
    }

    span {
      color: #149c08;
      font-size: 14px;
      line-height: 22px;
    }

    &:global(._isStop) {
      div {
        background: #fff2c5;
        border-color: #ff9c08;
      }

      span {
        color: #ff9c08;
      }
    }
  }
}

.dropdown {
  z-index: 2;
  position: relative;
  cursor: default;

  &:global(._isOpen) {
    opacity: 1;
    visibility: visible;

    & .dropdown__show {
      background: #f4f5f6;
      color: #222222;

      svg {
        display: none;

        &:last-child {
          display: block;
        }
      }

      &:hover {
        color: #222222;
      }
    }

    & .dropdown__menu {
      opacity: 1;
      visibility: visible;
    }
  }

  &__show {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #828282;
    cursor: pointer;
    transition: all 0.1s ease;

    &:disabled {
      cursor: default;
    }

    svg {
      &:last-child {
        display: none;
      }
    }

    &:hover {
      color: #222222;
    }
  }

  &__menu {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding-right: 102px;
    display: grid;
    height: 100%;
    grid-auto-flow: column;
    grid-gap: 0 34px;
    align-items: center;
    justify-content: end;
    opacity: 0;
    visibility: hidden;
    width: 30%;
    background: #fff;
    z-index: -1;
    transition: all 0.2s ease;

    & > div {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      transition: all 0.1s ease;

      &:global(.disabled) {
        pointer-events: none;
        cursor: not-allowed !important;

        svg {
          color: #828282;
        }
      }

      span {
        display: block;
        padding: 4px 10px;
        background: #222222;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        opacity: 0;
        visibility: hidden;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 8px 0 8px;
          border-color: #222222 transparent transparent transparent;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &:hover {
        span {
          opacity: 1;
          visibility: visible;
        }
        svg {
          color: #025dff;
        }
      }
    }
  }
}
