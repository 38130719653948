@import 'src/styles/variables';

.item {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}

.title {
  cursor: pointer;
}

.filter {
  cursor: pointer;
  display: inline-flex;
  align-self: center;
  padding: 0;
  margin: 0 0 0 4px;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 10px;

  div {
    width: 8px;
    height: 10px;
    display: flex;
  }
}

:global(.full) {
  .filter {
    div,
    svg {
      width: 8px;
      height: 10px;
    }
  }
}
