@import 'src/styles/variables';

.error {
  display: block;

  &:global(._isToggle) {
    & .title {
      font-weight: 500;

      svg {
        transform: rotate(180deg);
      }
    }

    & .text {
      padding: 14px;
      border-top: 1px solid #ebebeb;
    }
  }

  & .title {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    cursor: pointer;

    span {
      padding-right: 8px;
      max-width: calc(100% - 16px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    svg {
      transition: transform 0.1s;
    }
  }

  & .text {
    padding: 0 14px;
    box-sizing: content-box;
    background: #f6f6f6;
    border-top: none;
    font-family: Source Code Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: $black;
    overflow: hidden;
  }
}
