@import 'src/styles/variables';

.dropdown {
  &:global(._isToggle) {
    .title {
      & .chevron {
        transform: rotate(180deg);
      }
    }
  }

  & .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    & .content {
      svg {
        transition: opacity 0.1s;
      }
    }

    & .chevron {
      transition: transform 0.1s;
    }
  }

  & .children {
    // Удалила для блока Модерация в крео
    // padding-bottom: 14px; 
    display: none;
  }
}

.spacer {
  flex: 1 0 auto;
}
