@import 'src/styles/variables';

.reports {
  position: relative;
  background: #f5f5f5;
  min-height: calc(100vh - 58px);

  & .header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:global(.isFixed) {
      position: fixed;
      top: 48px;
      width: 100%;
      z-index: 1000;
    }
  }
}

.content {
  padding-top: 28px;
  padding-bottom: 40px;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: none;

  & .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    // & .resize {
    //   cursor: pointer;
    // }

    // & .toggle-title {
    //   &:global(.isDisabled) {
    //     pointer-events: none;

    //     svg {
    //       color: #828282;
    //     }
    //   }

    //   & .tooltip {
    //     margin-left: 0;

    //     & > div {
    //       &:last-child {
    //         top: calc(50% - 19px);
    //       }
    //     }
    //   }
    // }

    & .update {
      &:hover {
        cursor: pointer;
      }

      svg {
        vertical-align: middle;
      }
    }

    & .showChart {

      &:global(._isActive) {
        svg {
          color: #025dff;
        }
      }

      &:hover {
        cursor: pointer;
      }

      svg {
        vertical-align: middle;
      }
    }
  }
}
