@import 'src/styles/variables';

.chart {
  padding: 28px 22px;
  width: 100%;

  &__legend {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: -16px;
  }

  &__item {
    margin-right: 24px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &:hover {
      div {
        svg {
          opacity: 1;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:global(.isOff) {
      .name {
        text-decoration: line-through;
      }
    }

    & .tooltip {
      &:hover {
        & > div:first-child {
          .name {
            color: $blue;
          }
        }
      }
      & > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
      & > div:last-child {
        width: auto;
        white-space: nowrap;
      }
    }
  }

  :global(.loader) {
    height: 326px;
    margin-top: 0 !important;
  }

  & .circle {
    width: 14px;
    height: 14px;
    background: #40baff;
    border-radius: 50%;
    margin-bottom: 2px;
    position: relative;
  }

  & .name {
    margin-left: 8px;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    transition: color 0.1s;
  }

  & .checkbox {
    width: 16px;
    height: 16px;
    background: $black;
    border-radius: 1px;
    position: relative;

    &:after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.44444 1.3335L3.94444 6.66683L1.44444 4.24259' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      position: absolute;
      z-index: 2;
      top: 0;
      left: 1px;
      bottom: 0;
      right: 0;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.table {
  position: relative;
  z-index: 4;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 21px 22px;
  }

  .result {
    & > * {
      font-weight: 500;
    }
  }

  & .row {
    display: flex;
    flex-wrap: nowrap;
    flex: none;
    width: 100%;
    justify-content: space-between;

    &:global(.level-1) {
      & .cell {
        &:first-child {
          padding-left: 54px;
        }
      }
    }

    &:global(.level-2) {
      & .cell {
        &:first-child {
          padding-left: 90px;
        }
      }
    }

    &:hover {
      background: #f6f6f6;
      & .cell {
        background: #f6f6f6;
      }
    }

    & .cell {
      width: 140px;
      display: flex;
      flex: none;
      flex-direction: row;
      align-items: center;
      padding: 0 12px;
      height: 40px;
      font-size: 14px;
      line-height: 22px;
      min-width: 115px;
      color: $black;
      text-align: left;
      background: $white;
      white-space: nowrap;
      position: relative;

      &:global(._isAlignRight) {
        justify-content: flex-end;
      }

      &:first-child {
        position: fixed;
        left: 0;
        z-index: 1;
        border-right: 1px solid #ebebeb;
        width: 240px;
        padding-left: 22px;

        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: calc(100% - 34px);
        }

        :global(.checkbox) {
          width: 18px;
          height: 18px;
          border-radius: 1px;
          border: 1px solid #cbcbcb;
          display: inline-block;
          vertical-align: middle;
          margin-right: 16px;
          position: relative;
          z-index: 1;
          transition: border 0.1s, background 0.1s;
          cursor: pointer;
          &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            position: absolute;
            z-index: 2;
            top: 0;
            left: 1px;
            bottom: 0;
            right: 0;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0;
            transition: opacity 0.1s;
          }

          &:global(.isChecked) {
            background-color: $blue;
            border-color: $blue;

            &:after {
              opacity: 1;
            }
          }

          &:global(.isChildChecked) {
            background-color: $blue;
            border-color: $blue;

            &:after {
              background-image: url("data:image/svg+xml,%3Csvg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              opacity: 1;
            }
          }

          &:global(.isDisabled) {
            background: #f6f6f6;
            border: 1px solid #828282;
            cursor: not-allowed;
          }
        }

        :global(.chart-color) {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 3px;
          background-color: #0a0a0a;
        }
      }

      &:last-child {
        padding-right: 22px;
      }
    }
  }

  &__hint {
    margin-left: 5px;
    vertical-align: middle;
    position: relative;

    &:hover {
      opacity: 1;

      svg {
        color: $blue;
      }

      div {
        display: flex;
      }
    }

    div {
      display: none;
      justify-content: center;
      align-items: center;
      width: 150px;
      min-height: 30px;
      padding: 0 10px;
      background: #222222;
      border-radius: 2px;
      position: absolute;
      bottom: calc(100% + 20px);
      right: -67px;

      &:after {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='31' height='14' viewBox='0 0 31 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.853 13.451L1.07724 1.76251C0.367431 1.16024 0.793331 0 1.72422 0H29.2758C30.2067 0 30.6326 1.16024 29.9228 1.76251L16.147 13.451C15.7738 13.7677 15.2262 13.7677 14.853 13.451Z' fill='%23222222'/%3E%3C/svg%3E%0A");
        width: 31px;
        height: 14px;
        top: calc(100% - 2px);
        left: calc(50% - 15px);
      }
    }
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
      text-transform: none;
    }
  }
}

.legend-dropdown {
  display: inline-block;
  position: relative;
  margin-right: 24px;
  margin-bottom: 16px;

  &:global(._isOpened) {
    & .selected {
      svg {
        transform: rotate(180deg);
      }
    }
    & .list {
      pointer-events: all;
      opacity: 1;
      transform: translateY(0px);
    }
  }

  & .selected {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    cursor: pointer;

    svg {
      margin-left: 8px;
      transition: transform 0.1s;
    }
  }

  & .list {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-20px);
    top: calc(100% + 8px);
    left: 0;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    border-radius: 1px;
    z-index: 2;
    background: $white;
    padding: 2px 0;
    transition: opacity 0.1s, transform 0.1s;
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $tr;
    }

    &::-webkit-scrollbar-thumb {
      background: #dddddd;
      border-radius: 15px;
    }

    & .item {
      font-size: 14px;
      line-height: 22px;
      color: $black;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 32px;
      padding: 0 15px;
      cursor: pointer;

      &:global(._isActive) {
        color: #828282;
      }

      &:hover {
        background: #f6f6f6;
      }

      span {
        white-space: nowrap;
      }
    }
  }
}
