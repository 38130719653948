@import 'src/styles/variables';

.header {
  background: $white;
  min-height: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 3;

  & .title {
    max-width: 100%;
    word-wrap: break-word;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
  }
}
