@import 'src/styles/variables';
.card {
  padding: 0;
  & .extends {
    margin-top: 30px;
    .title {
      color: rgb(20, 156, 8);
    }
  }

  & .tabs {
    margin-top: 28px;

    & .panel {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px;

      &:global(.react-tabs__tab-panel--selected) {
        padding-top: 28px;
      }
    }

    & .radio {
      margin-bottom: 24px;
    }

    & .options {
      margin-left: 22px;
      margin-right: 22px;
    }

    & .search {
      margin-left: 22px;
      margin-right: 22px;
      margin-bottom: 8px;
      position: relative;

      button {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0;
        cursor: pointer;

        & > svg {
          font-size: 24px;
          color: #828282;
        }

        &:hover {
          svg {
            color: #222;
          }
        }

        &:disabled {
          &:hover {
            color: #828282;
          }
        }
      }

      input {
        display: block;
        width: 100%;
        height: 40px;
        background: $white;
        border: 1px solid #e2e2e2;
        box-sizing: border-box;
        border-radius: 1px;
        color: $black;
        padding: 0 16px;
        font-size: 14px;
        line-height: 22px;

        &:active,
        &:focus {
          border: 1px solid $blue;

          &:hover {
            border: 1px solid $blue;
          }
        }

        &:hover {
          border: 1px solid #a7a7a7;
        }

        &::placeholder {
          color: #828282;
        }
      }
    }

    & .grid {
      border: 1px solid #e2e2e2;
      border-radius: 1px;
      max-height: 850px;
      overflow-y: auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 0 22px;
      padding: 22px 20px;
      gap: 20px;
      align-items: flex-start;

      &:global(._isHide) {
        opacity: 0;
        height: 0;
      }
    }

    & .list {
      margin-left: -22px;
      margin-right: -22px;

      h4 {
        padding: 0 22px;
        margin-bottom: 16px;
        font-weight: 500;
      }

      & .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        & > div {
          display: block;
          width: 100%;
        }

        label {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
}
