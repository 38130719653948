@import 'src/styles/variables';

:root {
  --widthStaticPart: 240px;
}

.table {
  margin-top: 20px;
  background-color: #fff;

  &_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 21px 22px;
    border-bottom: 1px solid #ebebeb;
  }

  &_body {
    overflow: auto;
    width: 100%;
    max-height: 55vh;

    table {
      width: 100%;
      table-layout: fixed;
    }

    @mixin th {
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #222;
      padding: 4px 0;
      max-width: 150px;
      background: #fff;
      text-align: center;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: #ebebeb;
      }
    }

    @mixin head_th {
      font-weight: 500;
      padding: 10px 0;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: #ebebeb;
      }
    }

    @mixin body_th_total {
      background: #f9f9f9;
      font-weight: 500;
    }

    @mixin body_th_first {
      text-align: left;
      padding: 4px 20px;
    }

    @mixin left_th {
      padding: 10px 20px;
      text-align: left;

      position: sticky;
      left: 0;
      z-index: 2;

      max-width: var(--widthStaticPart);
      width: var(--widthStaticPart) !important;

      vertical-align: baseline;

      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background: #ebebeb;
      }
    }

    thead {
      position: relative;

      & .extend {
        background: #ffffff;
        border: 1px solid #ebebeb;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        transition: background 0.1s, border 0.1s;
        position: fixed;
        left: 0px;
        top: 0px;
        z-index: 200;
        transform: translateX(-50%);

        &:global(._isHide) {
          display: none;
        }

        &:hover {
          background: #e3ecff;
          border: 1px solid $blue;

          svg {
            color: $blue;
          }
        }

        svg {
          color: $black;
        }
      }
      tr {
        position: sticky;
        top: 0;
        z-index: 3;

        th {
          @include th;
          @include head_th;

          &:first-child {
            @include left_th;
          }
        }
      }
    }
    tbody {
      tr {
        &.total {
          th {
            @include body_th_total;
          }
        }
        &.grey {
          th {
            background: #f9f9f9;
          }
        }
        th {
          @include th;

          &:first-child {
            &.first {
              @include left_th;
              @include body_th_first;
            }
          }

          .name {
            font-size: 16px;
            line-height: 137%;
            color: #222;
          }

          .id {
            font-size: 13px;
            line-height: 154%;
            color: #828282;
            text-transform: lowercase;
          }
        }

        &:last-child {
          th {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
