@import 'src/styles/variables';

.card {
  padding: 28px 24px;
}

.info {
  display: grid;
  grid-template-columns: 20px auto;
  gap: 0 10px;
  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    a {
      color: var(--primary-blue-20, #025dff);
    }
  }

  &_text {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px 0;

    ul {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px 0;
      list-style: initial;
      padding-left: 20px;
      li {
        list-style: initial;
      }
    }
  }
}
