@import 'src/styles/variables';

.switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  @include phone {
    justify-content: flex-start;
  }

  & .button {
    background: #f2f2f2;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    transition: background 0.1s;

    &:global(.isDisabled) {
      pointer-events: none;

      svg {
        color: #e0e0e0;
      }
    }

    &:hover {
      opacity: 1;
      background: $blue;

      svg {
        color: $white;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      color: #4f4f4f;
      transition: color 0.1s;
    }
  }

  & .lodaing {
    width: 96px;
    height: 40px;
  }
}
