@import 'src/styles/variables';

.limit__groups {
  & .header {
    background: #fff;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .body {
    .container {
      @include campaignGrid;

      @include tablet {
        grid-template-columns: 1fr;
      }
    }

    .card_title {
      margin-bottom: 24px;
    }

    .empty {
      padding: 24px 0;
      border-top: 1px solid #ebebeb;
    }

    .buttons {
      button {
        margin-left: auto;
      }
    }
  }
}
