@import 'src/styles/variables';

.table {
  display: block;
  width: 100%;
  overflow: hidden !important;

  & .header {
    display: flex;
    position: relative;
    z-index: 2;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;

    & .fixed {
      width: 240px;
      position: relative;
      z-index: 3;
      border-right: 1px solid #ebebeb;

      & .cell {
        padding-left: 22px;
        min-width: 240px;
      }
    }

    & .scroll {
      width: calc(100% - 240px);
      display: flex;
      overflow: hidden;
      position: relative;

      &::-webkit-scrollbar {
        display: none;
      }

      & .cell {
        min-width: 115px;

        .last-child {
          padding-right: 22px;
        }
      }
    }

    & .cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 16px;
      height: 40px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: $black;
      text-align: left;
      white-space: nowrap;
      position: relative;
      flex: none;
      z-index: 1;
      max-width: 100%;

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  & .body {
    position: relative;
    z-index: 1;
    padding-left: 240px;
    max-height: calc(100vh - 350px);
    overflow-y: auto;
    overflow-x: hidden;
    transform: translate(0);

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: $tr;
    }

    &::-webkit-scrollbar-thumb {
      background: #cbcbcb;
      border-radius: 26px;
    }

    & .content {
      overflow: hidden;

      &:global(.isHidden) {
        &::-webkit-scrollbar {
          display: initial;
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        display: none;
      }

      &::-webkit-scrollbar-track {
        background: $tr;
      }

      &::-webkit-scrollbar-thumb {
        background: #cbcbcb;
        border-radius: 26px;
      }
    }
  }

  & .cell {
    width: 200px;

    :global(.checkbox) {
      flex: none;
      width: 18px;
      height: 18px;
      border-radius: 1px;
      border: 1px solid #cbcbcb;
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
      position: relative;
      z-index: 1;
      transition: border 0.1s, background 0.1s;
      cursor: pointer;

      &:after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        position: absolute;
        z-index: 2;
        top: 0;
        left: 1px;
        bottom: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        transition: opacity 0.1s;
      }

      &:global(.isChildChecked) {
        background-color: $blue;
        border-color: $blue;

        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
          opacity: 1;
        }
      }

      &:global(.isChecked) {
        background-color: $blue;
        border-color: $blue;

        &:after {
          opacity: 1;
          background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
        }
      }

      &:global(.isDisabled) {
        background: #f6f6f6;
        border: 1px solid #828282;
        cursor: not-allowed;

        &:global(.isChildChecked) {
          background: #828282;

          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H9' stroke='%23DDDDDD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
          }
        }

        &:global(.isChecked) {
          background: #828282;
          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='%23DDDDDD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
          }
        }
      }
    }
  }
}

.scrollbar {
  &:global(.isHidden) {
    display: none;
  }

  .wrapper {
    height: 8px;
    overflow-y: hidden;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: $tr;
    }

    &::-webkit-scrollbar-thumb {
      background: #cbcbcb;
      border-radius: 26px;
    }
    .bar {
      width: 2000px;
      height: 8px;
    }
  }
}
