@import 'src/styles/variables';

.loading_moderation {
  .checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.loading_widget {
  .top {
    display: flex;
    justify-content: space-between;

    .left {
      max-width: calc(100% - 80px);
      width: 100%;
      padding-top: 6px;
    }
  }
}
