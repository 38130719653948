.card {
  .title {
    margin-bottom: 28px;
    display: flex;
    align-items: center;
  }

  .subtitle {
    color: var(--Neutral-Grey-50, #828282);
    font-size: 12px;
    line-height: 18px; /* 150% */
    margin-top: -28px;
    margin-bottom: 24px;
  }

  .string_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    strong {
      font-size: 12px;
      font-weight: 500;
    }
    span {
      font-size: 12px;
    }
  }

  .form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

.moderation {
  .actions {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
  }

  .update {
    display: flex;
    cursor: pointer;

    &_status {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 100%;
      width: 8px;
      height: 8px;
      background: #149c08;
      border: 1px solid #ffffff;
    }
  }

  .content {
    border-top: 1px solid #f3f3f3;
    padding: 12px 0 0 0;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
