.toggle {
  display: flex;
  align-items: center;
  height: 32px;
  &_label {
    flex: 1;
    color: #828282;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
  }
}

.form {
    flex: 1;
  }

.price {
  :global(.label) {
    width: 150px;
    // margin-right: 10px !important;
  }
  & > div {
    flex: 1;
    input {
      width: 100% !important;
    }
  }
}
