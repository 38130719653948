@import 'src/styles/variables';

.pointerEvents {
  pointer-events: none;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  margin-top: 28px;

  &:global(._isNoEditing) {
    cursor: not-allowed !important;

    & > * {
      pointer-events: none !important;
    }
  }

  & .label {
    color: #828282;
    font-size: 12px;
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
  }

  & .info {
    font-size: 13px;
    line-height: 20px;
    color: $black;

    &:global(.isUppercase) {
      text-transform: uppercase;
    }
  }

  & .item {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  & .field_item {
    display: flex;
    align-items: flex-start;
    gap: 12px;

    :global(.prompt) {
      display: flex;
      align-items: center;
      height: 32px;
    }

    :global(.PriceInput) {
      &:global(.horizontal) {
        flex: 1;
        display: grid;
        grid-template-areas:
          'PriceLabel PriceBody'
          'PriceError PriceError';
        grid-template-columns: 200px auto;
        :global(.label) {
          grid-area: PriceLabel;
        }
        :global(.PriceInput_body) {
          grid-area: PriceBody;
          input {
            width: 100%;
          }
        }
        :global(.ErrorText) {
          grid-area: PriceError;
        }
      }
    }
  }

  :global(.PriceInput_wrapper) {
    &:global(._isHorizontalView) {
      color: red;
      :global(.PriceInput) {
        display: grid;
        grid-template-columns: 200px auto;
        gap: 10px;
      }
    }
  }
}

.rows {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  .period {
    display: grid;
    grid-template-columns: 152px 16px 1fr 16px 1fr;
    align-items: center;
    gap: 25px;

    & > div {
      &:nth-child(2),
      &:nth-child(4) {
        text-transform: lowercase;
      }
    }

    &:global(._isErrors) {
      align-items: baseline;
    }

    .label_row {
      color: #828282;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.36px;
      text-transform: uppercase;
    }

    :global(.PriceInput.horizontal) {
      :global(.PriceInput_body) {
        input {
          width: 100%;
        }
      }
    }
  }
}
