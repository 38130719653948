@import 'src/styles/variables';

$formGap: 18px;

.createOrdCampaignModal {
  .subtitle {
    margin-bottom: 30px;
  }
  .form {
    display: grid;
    grid-template-rows: 1fr;
    gap: $formGap;

    .createNameOrd {
      display: grid;
      grid-template-columns: 150px auto;
      align-items: center;
      gap: 28px;
      &:global(._idEditMode) {
        display: block;
      }
      &:global(._isError) {
        margin-bottom: 25px;
      }
    }

    :global(.labelWrapper) {
      line-break: auto;
    }
    :global(.inputField) {
      align-items: center;
    }
  }

  :global(.inputField) {
    &:global(._isError) {
      margin-bottom: calc(20px - #{$formGap});
      .createNameOrd {
        align-items: start;
      }
    }
  }
}
