@import 'src/styles/variables';

.form-group {
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 24px;
}

.preview {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;

  & + * {
    margin-top: 16px;
  }

  & .image {
    width: 100px;
    height: 102px;
    background: #e3ecff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  & .description {
    flex: 1 0 auto;
    max-width: 400px;

    & .size,
    & .url {
      max-width: 100%;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      margin-bottom: 4px;
      text-overflow: ellipsis;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .filename {
      color: #025dff;
      max-width: 100%;
      word-break: break-all;

      span {
        margin-right: 6px;
        font-size: 14px;
        line-height: 30px;
      }

      svg {
        display: inline;
        vertical-align: bottom;
        transform: translateY(-50%);
      }
    }
  }
}
