@import 'src/styles/variables';

$fullWidth: 570px;
/* Для мобильной версии*/
$fullWidth_MB: 270px;

:global(.full) {
  .campstat {
    margin-bottom: 10px !important;

    & .item {
      padding: 0 6px 0 6px;
      justify-content: flex-end;

      @for $i from 1 to 100 {
        &:global(.size-#{$i}) {
          width: calc(28px + #{$i * 5}px);
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    & .fixed {
      & .toggle_status {
        margin-left: 8px;
      }

      & .img {
        margin-left: 14px;
        margin-right: 10px;
      }

      & .text {
        margin-left: 12px;
      }
    }
  }
}

.creatives {
  position: relative;
}

.campstat {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 16px !important;

  &:hover {
    &:global(.row) {
      background: $white !important;
    }
  }

  &:global(._isFullTitle) {
    & .fixed {
      width: $fullWidth !important;

      @include tablet {
        width: $fullWidth_MB !important;
      }
    }
  }

  &:global(.isTop) {
    // z-index: 10;
  }

  :global(.options) {
    background: $white;
    border-radius: 1px;
  }

  & .item {
    z-index: 1;
    flex: none;
    box-sizing: content-box;
    width: 115px;
    padding: 0 10px;
    align-self: stretch;
    display: flex;
    align-items: center;
    background: $white;
    font-weight: 700;
    justify-content: flex-end;

    @for $i from 1 to 100 {
      &:global(.size-#{$i}) {
        width: calc(40px + #{$i * 7}px);
      }
    }

    &:global(._isGrey) {
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      color: #828282;
    }

    .last-child {
      padding-right: 22px;
    }
  }

  & .fixed {
    background: $white;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 1;
    }

    & .toggle_status {
      margin-left: 12px;
    }

    & .img {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 22px;
      margin-right: 19px;
      flex-shrink: 0;
      position: relative;

      & > div {
        &:first-child {
          height: 20px;
          width: 20px;
          z-index: 1;
          position: relative;
        }
      }

      & > svg {
        color: #c6c6c6;
      }

      & .status {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid #fff;
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: 2;
      }
    }
    & .text {
      max-width: 100%;
      font-weight: 700;
      margin-left: 22px;
    }
  }
}

.preloader {
  padding-top: 30px;
}