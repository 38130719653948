@import 'src/styles/variables';

.settings {
  & .header {
    background: #fff;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .body {
    .container {
      @include campaignGrid;

      @include tablet {
        grid-template-columns: 1fr;
      }
    }

    & .rows {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }

    & .period {
      display: grid;
      grid-template-columns: 152px 16px 1fr 16px 1fr;
      align-items: center;
      gap: 25px;

      .label_row {
        color: #828282;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.36px;
        text-transform: uppercase;
      }

      :global(.PriceInput) {
        input {
          width: 100%;
        }
      }
    }

    & .actions {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
    }

    & .info {
      display: flex;
      gap: 10px;
      svg {
        flex: none;
      }
      &_text {
        p {
          font-size: 12px;
          line-height: 18px;
          word-break: break-all;
        }
        strong {
          word-break: break-all;
          font-weight: 700;
        }
      }
    }
  }
}
