@import 'src/styles/variables';

.limit_groups_item {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  transition: 0.3s;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border: 1px solid #025dff;
    cursor: pointer;
  }

  &:global(._isAdded) {
    background: #f4f5f6;
  }

  &:global(._isReadOnly) {
    background: #f4f5f6;
    &:hover {
      border: 1px solid #e2e2e2;
      cursor: default;
    }
  }

  &:global(._isJustAdded) {
    background: #e6fce4;
  }

  .link {
    display: flex;
    align-items: center;
    width: 15%;
    min-width: 110px;
    color: #025dff;
    font-size: 12px;
    line-height: 18px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .action_wrapper {
    display: flex;
    width: 85%;
    padding-top: 8px;
    padding-bottom: 8px;

    .name {
      font-size: 12px;
      line-height: 18px;
      max-width: max-content;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .actions {
      min-width: 25%;
      margin-left: auto;
      margin-right: 0px;
      text-align: right;
      &__add,
      &__remove,
      &__cancel {
        color: #222;
        cursor: pointer;
        font-size: 12px;
        line-height: 18px;
        height: 100%;

        svg {
          vertical-align: middle;

          &:hover {
            color: $blue;
          }
        }
      }
      &__cancel {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

.loading_item {
  @extend .limit_groups_item;
  background: #e7e7e7c0;
  height: 36px;
  animation: load-item 2s infinite;
  border: 1px solid #e7e7e7c0;
  
  &:hover {
    cursor: default;
    border: 1px solid #e7e7e7c0;
  }
  
  @for $i from 1 to 100 {
    &:nth-child(#{$i}) {
      animation-delay: calc(#{$i * 0.5}s)
    }
  }
}

@include loadItemKeyframe;
