@import 'src/styles/variables';

.container {
  max-width: 774px;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 41px;
}

.title {
  flex: 1 0 auto;
  max-width: calc(100% - 60px);
}

.close {
  margin-left: 24px;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f4f5f6;
  justify-content: center;
  align-items: center;
  transition: 0.3s;

  &:hover {
    background: darken(#f4f5f6, 5%);
  }

  svg {
    cursor: pointer;
    color: black;
  }
}

.form {
  .form_data {
    padding-bottom: 32px;
    border-bottom: 1px solid #f3f3f3;
    margin-bottom: 32px;

    .name_group {
      margin-bottom: 45px;
      :global(.labelWrapper) {
        width: 150px;
      }
      :global(.InputAutoSaveContainer) {
        width: 100%
      }
      :global(.inputField) {
        :global(.label) {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: normal;
        }
        :global(.error) {
          color: $red;
        }
      }
    }

    .row {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      &:global(.error) {
        color: $red;
        svg {
          color: $red;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .row_title_wrapper {
        display: flex;
      }

      .row_title {
        flex: none;
        margin-right: 5px;
        width: 180px;

        svg {
          vertical-align: middle;
          margin-left: 4px;
        }
      }

      .row_field {
        flex: 1;

        &:nth-child(2) {
          margin-right: 40px;
        }

        :global(.labelWrapper) {
          width: 80px;
        }
        :global(.InputAutoSaveContainer) {
          flex: 1;
        }
        input {
          width: 190px;
        }
      }
    }
  }
  .form_campaigns {
    .title {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 24px;
    }
    .search {
      position: relative;
      input {
        padding: 0 10px;
        height: 40px;
        border: 1px solid $grey;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        transition: all 0.1s;
        width: 100%;

        font-size: 14px;
        line-height: 22px;
        color: $black;

        &::placeholder {
          color: #828282;
        }

        &:focus {
          border: 1px solid #025dff;
        }
      }

      svg {
        height: 24px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        stroke: #b9b9b9;
        transition: 0.3s;

        &.cross {
          cursor: pointer;
          &:hover {
            stroke: #025dff;
          }
        }
      }
    }
    .search_items {
      display: flex;
      flex-direction: column;
    }
    .search_result { 
      margin: 10px 0;
      padding-right: 8px;
      max-height: 200px;
      overflow-y: scroll;
      padding-bottom: 4px;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      .empty_search {
        padding: 10px 0;
        color: #828282;
        margin-bottom: 10px;
        text-align: center;
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.loading {
  height: 200px;
  overflow-y: hidden !important;
  @extend .search_result;
}
