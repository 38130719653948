@import 'src/styles/variables';

.group {
  padding: 24px 0;
  border-bottom: 1px solid #ebebeb;

  &:global(.isLocked) {
    a {
      color: $text-hint;
    }
    .actions {
      .button {
        color: $text-hint;
      }
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
  }

  &_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &_parametrs {
    display: flex;
    align-items: center;
    color: #828282;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 12px;

    & > div {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &_items {
    margin-bottom: 24px;
  }

  .actions {
    display: flex;
    align-items: center;
    .button {
      margin-right: 24px;
      color: #025dff;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.loading {
  &_title {
    @extend .group_title;
    background: #e7e7e7;
    height: 22px;
    width: 100px;
    animation: load-item 2s infinite;
  }
  &_parametrs {
    @extend .group_parametrs;
    background: #e7e7e7;
    height: 18px;
    width: 400px;
    animation: load-item 2s infinite;
  }
  &_items {
    @extend .group_items;
  }
  &_actions {
    @extend .actions;
    background: #e7e7e7c0;
    height: 22px;
    width: 200px;
    animation: load-item 2s infinite;
  }
}

@include loadItemKeyframe;
