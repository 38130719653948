@import 'src/styles/variables';

.balance {
  & .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    & .title {
      flex: 1 0 auto;
    }

    & .actions {
    }
  }

  & .content {
    & .item {
      margin-top: 28px;

      &:first-child {
        margin-top: 0;
      }
      & .name {
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #828282;
      }
      & .value {
        margin-top: 8px;
        font-size: 24px;
        line-height: 30px;
        color: $black;

        &:global(.partner) {
          font-size: 14px;
          line-height: 22px;
        }

        &:global(.data) {
          display: inline-block;

          .value__item {
            & > div {
              font-weight: bold;
              font-size: 16px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  & .refill {
    display: flex;
    align-items: flex-end;

    .refill__input {
      padding: 10px;
    }

    .refill__btn {
      margin-left: 12px;
    }
  }
}
