@import 'src/styles/variables';

:global(.previewModal) {
  &:global(.ModalOverlay) {
    overflow: hidden;

    :global(.ModalOverlay_content) {
      max-height: 100vh;
      height: 100%;
      min-height: auto;
    }

    :global(.ModalOverlay_dialog) {
      min-height: 500px;
      overflow: hidden;
    }
  }
}

.modal {
  max-width: 70%;
  width: 100%;
  height: 100%;
  flex: none;

  .card {
    padding: 0;
    height: 100%;
  }

  .header {
    display: grid;
    grid-template-columns: 258px auto 22px;
    align-items: center;
    padding: 24px 31px;
    border-bottom: 1px solid #f2f2f2;
    height: 78px;

    &:global(._isDefault) {
      display: flex;
      justify-content: space-between;
    }

    .title {
      display: flex;
      align-items: center;

      img {
        width: 22px;
        flex: none;
        margin-right: 11px;
      }

      .internal_id {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        line-break: anywhere;
      }

      .name {
        margin-left: 10px;

        svg {
          vertical-align: middle;
        }
      }
    }

    .option {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 12px;
      }

      :global(.button) {
        height: 29px;
        padding: 0 10px;
        min-width: 87px;
      }
    }

    .close {
      cursor: pointer;

      svg {
        vertical-align: middle;
      }
    }
  }

  .body {
    display: grid;
    grid-template-columns: 258px auto;
    height: 100%;

    .side {
      height: 100%;
    }

    .scroll {
      min-height: min-content;
      height: 100%;
      min-width: min-content;
    }

    .main {
      width: 100%;
      height: 100%;
      display: grid;
      align-items: center;
      justify-content: center;
      overflow: scroll;
      background: #f4f5f6;
      padding: 40px;
      position: relative;

      &:global(._isHide) {
        height: 0;
        opacity: 0;
      }
      &:global(._isXml) {
        display: block;
        overflow: auto;
      }
    }

    .size {
      display: flex;
      font-size: 14px;
      line-height: 17px;

      &_icon {
        margin-right: 10px;
      }
    }

    ::-webkit-scrollbar-thumb {
      background: #025dff;
    }
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    .option {
      width: 550px;
      background: #fff;
      padding: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #000;
      flex: none;

      &:global(.rectangle) {
        &:global(.option1) {
          .option_image {
            margin-top: 15px;
          }
          .option_text {
            margin-top: 10px;
          }
          .option_btn {
            margin-top: 20px;
          }
        }
        &:global(.option2) {
          .option_header_title {
            margin-top: 20px;
          }
          .option_header_link {
            margin-top: 10px;
          }
          .option_text {
            margin-top: 25px;
          }
          .option_btn {
            margin-top: 10px;
          }
        }
      }

      &:global(.square) {
        width: 500px;
        height: 500px;

        &:global(.option1) {
          width: 400px;
          .option_header_link {
            margin-top: 10px;
          }
          .option_text {
            margin-top: 15px;
          }
          .option_image {
            margin-top: 10px;
            min-height: 270px;
          }
          .option_btn {
            margin-top: 20px;
          }
        }

        &:global(.option2) {
          width: 600px;
          height: auto;
          display: grid;
          grid-template-columns: 300px auto;
          gap: 0 20px;

          .option_image {
            min-height: 300px;
          }
          .option_vertical {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
          .option_header_link {
            margin-top: 10px;
          }
          .option_text {
            margin-top: 25px;
            flex: 1;
          }
          .option_btn {
            margin-top: 10px;
          }
        }
      }

      &_header {
        display: grid;
        grid-template-columns: 80px auto;
        align-items: center;
        gap: 0 16px;

        &_title {
          font-size: 17px;
          line-height: 20px;
          font-weight: 500;
        }
        &_link {
          font-size: 14px;
          line-height: 18px;
          color: #828282;
          line-break: anywhere;
        }
      }

      &_text {
        font-size: 14px;
        line-height: 18px;
        color: #000;
        word-break: break-all;
      }

      &_image {
        background-repeat: no-repeat;
        max-width: 100%;
        min-height: 250px;
        background-size: contain;
      }

      &_btn {
        height: 30px;
        border: 1px solid rgb(130, 130, 130);
        color: rgb(130, 130, 130);
        z-index: 10;
        position: relative;
        border-radius: 2px;
        width: fit-content;
      }
    }
  }

  .loader {
    height: calc(100% - 74px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f5f6;
    min-height: 375px;

    :global(.Loader) {
      background: #ffffff00;
    }

    .plug {
      svg {
        margin-bottom: 30px;
      }
      span {
        display: block;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

$widthClicableZone: 80px;

.modal_nav {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  pointer-events: all;
  min-width: 53px;
  width: $widthClicableZone;
  height: 100%;
  background: rgba(34, 34, 34, 0);
  padding: 0;
  transition: 0.3s;
  border: none;
  outline: none;
  svg {
    stroke-width: 1;
  }
}

.prev {
  left: -$widthClicableZone;
  &:hover {
    background: linear-gradient(
      270deg,
      rgba(33, 33, 33, 0.1) 0%,
      rgba(33, 33, 33, 0) 80%
    );
  }
}

.next {
  right: -$widthClicableZone;
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(33, 33, 33, 0.1) 0%,
      rgba(33, 33, 33, 0) 80%
    );
  }
}

.tabs {
  height: calc(100% - 78px);

  &_panel {
    padding-top: 0;
    height: 100%;
    overflow: auto;
    display: none;

    &:global(.react-tabs__tab-panel--selected) {
      display: block;
    }
  }
  &_tablist {
    margin: 0;
    flex-direction: column;
    padding: 0;
    border-bottom: none;

    &:global(.clickable) {
      .tabs_tab {
        cursor: pointer;

        &:hover {
          background: #91919108;
        }
      }
    }
  }
  &_tab {
    margin: 0;
    padding: 20px 30px;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    position: relative;

    &:global(.react-tabs__tab--selected) {
      &::after {
        opacity: 1;
        content: '';
        position: absolute;
        border-bottom: none;
        border-right: 2px solid #025dff;
        top: 0;
        bottom: 0;
        right: 0;
      }

      .size_icon {
        path {
          &:last-of-type {
            fill: #025dff;
          }
        }
      }
    }
  }
}
