@import 'src/styles/variables';

.client {
  min-height: 58px;
  display: flex;
  padding: 8px 14px;
  cursor: pointer;
  transition: background 0.1s;

  &:hover {
    background-color: $gray-bg;
  }
  & .checkbox {
    border: 1px solid #cbcbcb;
    border-radius: 1px;
    width: 18px;
    height: 18px;
    margin-right: 16px;
    transition: border 0.1s, background 0.1s;
    position: relative;

    &:global(.isActive) {
      background: #025dff;
      border-color: #025dff;

      &:after {
        opacity: 1;
        visibility: visible;
      }
    }

    &:after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      position: absolute;
      top: 0;
      left: 1px;
      right: 0;
      bottom: 0;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.1s;
    }
  }

  & .checkboxWrapper {
    min-height: 100%;
    display: flex;
    align-items: center;
  }

  & .icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    color: $white;
    background-position: center;
    background-size: cover;
    flex: none;

    &:global(._isDefault) {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $white;
    }
  }

  & .data {
    padding-left: 16px;
    width: calc(100% - 42px);
    padding-top: 2px;

    & .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      word-break: break-word;
    }

    & .type {
      font-size: 12px;
      line-height: 18px;
      color: $text-hint;
    }
  }
}
