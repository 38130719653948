@import 'src/styles/variables';

.card {
  padding: 20px 14px;

  .optimisation_btns {
    display: flex;
    align-items: center;
    gap: 0 16px;
  }

  .button {
    border-radius: 1px;
    width: 100%;
    transition: 0.3s;

    &:global(.green) {
      border: 1px solid var(--green, #149c08);

      &:hover {
        background: #149c08;
      }
      &:active {
        background: #0b8400;
      }

      svg {
        color: var(--green, #149c08);
      }

      span {
        color: var(--green, #149c08);
      }

      &:global(._isOn) {
        background: #149c08;

        &:hover {
          background: #0b8400;
        }

        &:active {
          background: #0a7a00;
        }
      }
    }

    &:global(.blue) {
      border: 1px solid var(--green, #025dff);

      &:hover {
        background: #013fdd;
      }
      &:active {
        background: #002b97;
      }

      svg {
        color: var(--green, #025dff);
      }

      span {
        color: var(--green, #025dff);
      }

      &:global(._isOn) {
        background: #025dff;

        &:hover {
          background: #013fdd;
        }

        &:active {
          background: #002b97;
        }
      }
    }

    &_body {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 10px;
      padding: 9px 0;
    }

    &:hover,
    &:active {
      cursor: pointer;

      svg,
      span {
        color: #fff;
      }
    }

    &:global(._isOn) {
      svg,
      span {
        color: #fff;
      }
    }

    svg {
      width: 24px;
      height: auto;
    }

    &:disabled {
      border-radius: 1px;
      border: 1px solid #828282;
      background: #e2e2e2;
      cursor: not-allowed;

      .button_body {
        pointer-events: none;
      }

      svg {
        color: #828282;
      }

      span {
        color: #828282;
      }
    }
  }
}
