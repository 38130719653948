@import 'src/styles/variables';

.black-header {
  display: block;
  position: relative;
  width: 100%;
  background-color: #1b1b1b;
  z-index: 1000;

  &._isNewYear {
    background-image: url(../../assets/headerSnow.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .black-header__logo {
      max-height: 100%;
      height: 100%;

      @include phone {
        max-height: 100%;
        height: 100%;
      }
    }
  }
  
  & + .fullscreen-error {
    .fullscreen-error__img {
      max-height: calc(100vh - 552px);
    }
  }

  .content {
    width: 100%;
    max-width: 100%;
    height: 58px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 53px;
    padding-left: 53px;
    margin: 0 auto;

    @include laptop {
      padding-right: 42px;
      padding-left: 42px;
    }

    @include phone {
      padding-left: 10px;
      padding-right: 10px;
      height: 70px;
    }
  }

  .spacer {
    flex: 1 0 auto;
  }

  .balance {
    margin-right: 40px;

    @include phone {
      margin-right: 10px;
    }
  }

  &__nav {
    &._isFixed {
      margin-bottom: 48px;
    }

    .container {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__notify {
    display: block;
    color: $white;
    margin-right: 32px;
    cursor: default;
    transition: opacity 0.1s;
  }

  &__menu {
    cursor: pointer;

    @include phone {
      flex: none;
    }
  }

  &__logo {
    margin-left: 26px;
    margin-right: 26px;
    display: block;
    max-height: 24px;

    @include phone {
      margin-left: 10px;
      max-height: 20px;
      margin-right: 10px;
    }
  }

  &__links {
    height: 48px;
    background: $black;
    transition: all 0.1s ease;

    &.isFixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.43);
    }

    .container {
      max-width: 1280px;
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-columns: 20% 60% 20%;
      align-items: center;

      &.isCenter {
        grid-template-columns: 1fr;
        justify-content: center;
      }

      .back {
        height: 100%;
        font-size: 12px;
        line-height: 14px;
        color: $white;
        display: flex;
        align-items: center;
        justify-self: start;
        padding-right: 4px;

        &:hover {
          background: #2f2f2f;
          opacity: 1;
        }

        &:active {
          background: #292929;
        }

        span {
          margin-left: 12px;
        }
      }

      .tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        & > a {
          font-size: 12px;
          line-height: 14px;
          color: $white;
          padding: 0 24px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background 0.1s;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 4px;
            background: #025dff;
            opacity: 0;
          }

          &:hover {
            background: #2f2f2f;
          }

          &:active {
            background: #292929;
          }

          &.isDisabled {
            color: #828282;
            background: #222;
            pointer-events: none;
          }

          &.isActive {
            background: #2f2f2f;

            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
