@import 'src/styles/variables';

.container {
  .incriment {
    width: fit-content !important;
    font-size: 14px !important;
    line-height: 20px;
    color: #222222;
    padding: 4px 8px !important;
    white-space: break-spaces !important;
    margin: 4px !important;
    background: #e3ecff !important;
    border-radius: 8px !important;
  }
}
