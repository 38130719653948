@import 'src/styles/variables';

.options {
  position: relative;
  display: inline-flex;
  cursor: pointer;

  & .button {
    box-sizing: border-box;
    border-radius: 1px;
    display: flex;
    width: 100%;
    font-size: 14px;
    color: $black;
    transition: border 0.1s;
    position: relative;

    &:global(._isOpened) {
      svg {
        color: $blue;
      }
    }
  }

  & .list {
    z-index: 1;
    position: absolute;
    padding: 2px 0;
    top: calc(100% + 4px);
    right: 0;
    min-width: 100%;
    background: $white;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    border-radius: 1px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.1s ease;

    &:global(._isOpened) {
      z-index: 10;
      opacity: 1;
      visibility: visible;
    }
  }

  & .item {
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 15px;
    font-size: 14px;
    color: $black;
    background: $tr;
    transition: background 0.1s;
    white-space: nowrap;

    &:global(._isCurrent) {
      color: #828282;
      cursor: default;

      &:hover {
        background: #fff;
      }
    }

    &:global(._isDisabled) {
      color: #828282;
      cursor: not-allowed;

      &:hover {
        background: #fff;
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  & > div {
    position: relative;
  }

  &:global(._isDisabled) {
    pointer-events: none;
    & .active {
      background: #f6f6f6;
      color: $text-hint;
    }
  }

  &:global(._isUppercase) {
    & .active,
    & .item {
      text-transform: uppercase;
    }
  }
}
