@import 'src/styles/variables';

.reports {
  position: relative;
  background: #f5f5f5;
  min-height: calc(100vh - 58px);

  & .header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:global(.isFixed) {
      position: fixed;
      top: 48px;
      width: 100%;
      z-index: 1000;
    }
  }
}

.content {
  padding-bottom: 40px;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: none;

  & .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & .resize {
      margin-right: 32px;
      cursor: pointer;
    }

    & .toggle-title {
      margin-right: 32px;

      &:global(.isDisabled) {
        pointer-events: none;

        svg {
          color: #828282;
        }
      }

      & .tooltip {
        margin-left: 0;

        & > div {
          &:last-child {
            top: calc(50% - 19px);
          }
        }
      }
    }

    & .range {
      margin-right: 16px;
    }

    & .update,
    & .showTree {
      margin-right: 16px;

      &:hover {
        cursor: pointer;
      }

      svg {
        vertical-align: middle;
      }
    }

    .showTree {
      svg {
        width: 24px;
        height: auto;
      }
      &:global(._isShow) {
        svg {
          color: $blue;
        }
      }
    }
  }
}

.filters {
  display: flex;
  width: 100%;
  margin-top: 20px;
  &_item {
    padding: 6px 8px;
    line-height: 20px;
    background-color: #e3ecff;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;
    &:global(._isNoActive) {
      color: #828282;
      background-color: #f6f6f6;
    }
  }
}
