@import 'src/styles/variables';

.xml_player {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;

  & > div {
    display: block;
  }

  :global(.mol-video-ad-container) {
    & > div {
      &:last-child {
        position: relative !important;
      }
    }
  }
}
