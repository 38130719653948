@import 'src/styles/variables';

.detail {
  :global(.loader) {
    margin: 32px auto 0;
  }

  & .wrapper {
    min-height: 102px;
  }
}

.header {
  background: $white;
  height: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:global(.isFixed) {
    position: fixed;
    top: 48px;
    height: 102px;
    left: 0;
    right: 0;
    z-index: 1001;
    box-shadow: 0 2px 28px rgba(0, 0, 0, 0.15);

    & .title {
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
    }
  }

  & .status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;

    & .title {
      max-width: calc(100% / 12 * 5);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  & .actions {
    display: flex;
    align-items: center;

    & .update {
      margin-right: 16px;

      &:hover {
        cursor: pointer;
      }
      
      svg {
        vertical-align: middle;
      }
    }
  }
}
