@import 'src/styles/variables';

.clients {
  position: relative;
  background: #f5f5f5;
  min-height: calc(100vh - 106px);
  padding-bottom: 40px;

  & .loader {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }

  & .header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    position: sticky;
    top: 48px;
    transition: 0.5s;

    &:global(.isToggleSearch) {
      .title {
        display: none;
      }
      .actions {
        flex: 1 0 auto;
      }
    }

    &:global(.isFixed) {
      box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.15);
      z-index: 12;
    }

    & .status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
