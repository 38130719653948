@import 'src/styles/variables';

.card {
  margin: 0 auto;
  max-width: 546px;
  width: 100%;
  background: $white;
  display: block;
  pointer-events: all;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;
  position: relative;

  & .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 28px;

    & .title {
      flex: 1 0 auto;
      max-width: calc(100% - 60px);
    }
  }

  & .text {
    padding: 0 28px;
    word-break: break-word;
    font-size: 14px;
    line-height: 22px;
    color: #0a0a0a;

    strong {
      font-weight: 500;
    }
  }

  & .loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: 0.3s;

    &:global(._isShow) {
      opacity: 1;
      z-index: 10;
    }

    :global(.Card_component) {
      height: 100%;
      justify-content: center;
    }
  }

  & .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 40px 28px 28px;
    gap: 20px;
  }
}

/* Непонятные стили остались*/
.close {
  margin-left: 24px;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f4f5f6;
  justify-content: center;
  align-items: center;

  svg {
    cursor: pointer;
    color: $black;
  }
}

.content {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 2;
  pointer-events: none;
  padding: 20px;
  overflow: auto;
}
