@import 'src/styles/variables';

.moderation {
  &:global(.isDisabled) {
    pointer-events: none;
    cursor: default;
    opacity: 0.9;
  }

  & .header {
    position: absolute;
    top: 23px;
    right: 20px;
    cursor: pointer;

    & .loader {
      position: relative;

      :global {
        .loader {
          margin-top: 0 !important;
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  & .code {
    max-width: 326px;
    margin-left: 25px;
    margin-top: 13px;
    margin-bottom: 13px;

    & .editor {
      & > div {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        max-width: 306px;
      }

      :global {
        .CodeMirror {
          font-family: 'Source Code Pro', monospace !important;
          height: auto;
        }

        .CodeMirror-scroll {
          height: auto !important;
          overflow: hidden !important;
          margin: 0 !important;
        }

        .CodeMirror-vscrollbar,
        .CodeMirror-hscrollbar {
          display: none !important;
        }

        .cm-string {
          color: #fe3636;

          &.cm-property {
            color: #0a0a0a;
          }
        }
        .cm-number {
          color: #149c0a;
        }
      }
    }
  }

  & .list {
    & .line {
      position: relative;
      // margin-top: 20px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -22px;
        right: -22px;
        border-top: 1px solid #f3f3f3;
      }

      // & .preloader {
      //   padding: 19px 0;

      //   & .string {
      //     display: block;
      //     width: 100%;
      //     height: 14px;
      //     border-radius: 1px;
      //     background: #e7e7e7;
      //     animation: load-item 1.1s infinite;

      //     & + .string {
      //       margin-top: 8px;
      //     }

      //     @keyframes load-item {
      //       0% {
      //         opacity: 1;
      //       }
      //       40% {
      //         opacity: 0.8;
      //       }
      //       70% {
      //         opacity: 0.7;
      //       }
      //       100% {
      //         opacity: 1;
      //       }
      //     }
      //   }
      // }

      & .title {
        min-height: 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $black;

        span {
          margin-right: 8px;
        }
      }

      & .description {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        & .icon {
          margin-right: 8px;
          position: relative;

          &:global(.isShowChecklist) {
            &:after {
              content: '';
              display: block;
              position: absolute;
              left: 11px;
              top: calc(100% - 2px);
              width: 1px;
              background: #ebebeb;
              height: 16px;
            }
          }
        }

        & .text {
          width: calc(100% - 30px);
          max-width: calc(100% - 30px);

          & .status {
            font-size: 14px;
            line-height: 22px;
            color: $black;
          }
          & .date {
            margin-top: 3px;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.03em;
            color: #828282;
          }

          & .hint {
            margin-top: 13px;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: $black;
            word-break: break-word;
            margin-bottom: 28px;

            .open {
              font-size: 13px;
              line-height: 20px;
              color: $blue;
              cursor: pointer;
              display: inline-block;
              font-weight: normal;
            }

            .block {
              display: block;
              margin-top: 10px;
            }
          }

          & .link {
            margin-top: 8px;
            font-size: 13px;
            line-height: 20px;
            color: $blue;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            svg {
              margin-left: 4px;
            }
          }
        }

        & .checklist {
          width: 100%;
          display: block;
          padding-top: 16px;
          padding-left: 22px;
          position: relative;

          & .item {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 16px;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              right: calc(100% + 2px);
              top: calc(-50% - 16px);
              width: 16px;
              height: calc(100% + 16px);
              border-left: 1px solid #ebebeb;
              border-bottom: 1px solid #ebebeb;
            }

            &:first-child {
              margin-top: 0;

              &:after {
                top: calc(-50% + 4px);
                height: calc(100% - 4px);
              }
            }

            & .icon {
              margin-top: 2px;
              margin-right: 8px;

              svg {
                color: #828282;
              }
            }

            & .text {
              font-size: 13px;
              line-height: 20px;
              color: $black;
              max-width: calc(100% - 24px);
              word-break: break-word;
            }
          }
        }

        & .more {
          & .link {
            cursor: pointer;
            transition: opacity 0.1s;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      & .button {
        width: 100%;
        margin-top: 13px;

        & > button {
          width: 100%;
        }
      }
    }
  }

  .CheckCircleIcon {
    color: $green;
    width: 22px;
    height: 22px;
    svg {
      width: 22px;
      height: 22px;
    }
  }
}

@include loadItemKeyframe;
