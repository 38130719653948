@import 'src/styles/variables';

.card {
  margin: 0 auto;
  max-width: 774px;
  width: 100%;
  background: $white;
  display: block;
  pointer-events: all;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;
}

.textarea {
  & .label {
    display: block;
    margin-bottom: 8px;
  }
  & .body {
    background: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 1px;
    min-height: 100px;
    width: 100%;
    font-size: 14px;
    line-height: 26px;
    color: #0a0a0a;
    transition: border 0.1s;
    padding: 13px 14px;
    resize: none;
    height: 184px;

    & ::placeholder {
      font-size: 14px;
      line-height: 22px;
      color: #b9b9b9;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 28px;

  & .title {
    flex: 1 0 auto;
    max-width: calc(100% - 60px);
  }

  & .close {
    margin-left: 24px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #f4f5f6;
    }

    svg {
      cursor: pointer;
      color: $black;
    }
  }
}

.content {
  padding: 0 28px;

  & .label {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #828282;
    margin-bottom: 8px;
  }

  & .name {
    margin-bottom: 18px;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 28px 28px;

  .main {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 24px;
    }
  }
}

.textarea {
  height: 244px;
}
