@import 'src/styles/variables';

.cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
  height: 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: $black;
  text-align: left;
  white-space: nowrap;
  position: relative;
  flex: none;
  z-index: 1;
  width: 140px;
  cursor: pointer;
  transition: background 0.1s;

  &:global(._isAlignRight) {
    .button {
      justify-content: flex-end;
    }
  }

  &:nth-child(1) {
    & .button {
      & .text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &:last-child:not(:global(._isFixed)) {
    & .button {
      & .more {
        & .list {
          transform: translateX(-100%);
        }
      }
    }
  }

  &:hover {
    background-color: #f6f6f6;
    .button {
      .more {
        svg {
          color: $black;
        }
      }
    }
  }

  &:global(._isFixed) {
    width: 100%;
    padding-left: 22px;
    padding-right: 30px;
  }

  &:global(._isOpened) {
    z-index: 2;
    background-color: #f6f6f6;
    .button {
      .more {
        svg {
          color: $black;
        }

        .list {
          pointer-events: all;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  & .button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    :global(.checkbox) {
      z-index: 2;
    }

    :global(.checkbox) {
      min-width: 18px;
      height: 18px;
      border-radius: 1px;
      border: 1px solid #cbcbcb;
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
      position: relative;
      z-index: 1;
      transition: border 0.1s, background 0.1s;
      cursor: pointer;

      &:after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        position: absolute;
        z-index: 2;
        top: 0;
        left: 1px;
        bottom: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        transition: opacity 0.1s;
      }

      &:global(.isChecked) {
        background-color: $blue;
        border-color: $blue;

        &:after {
          opacity: 1;
        }
      }

      &:global(.isChildChecked) {
        background-color: $blue;
        border-color: $blue;

        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          opacity: 1;
        }
      }

      &:global(.isDisabled) {
        background: #f6f6f6;
        border: 1px solid #828282;
        cursor: not-allowed;
      }
    }

    :global(.chart-color) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      background-color: #0a0a0a;
    }

    & .sortBtn {
      display: flex;
      align-items: center;
    }

    & .text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 90px;
    }

    & .sortIcon {
      width: 8px;
      margin-left: 6px;

      svg {
        display: block;
        color: $black;
      }
    }

    & .more {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      position: relative;
      width: 20px;

      svg {
        color: #828282;
      }

      & .list {
        position: fixed;
        top: calc(100% - 8px);
        left: 0;
        display: block;
        background: #ffffff;
        box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
        border-radius: 1px;
        padding: 1px 0;
        transform: translateY(-20px);
        opacity: 0;
        pointer-events: none;
        transition: transform 0.1s, opacity 0.1s;
        z-index: 111;

        & .item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0 16px;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: $black;
          text-transform: none;
          height: 32px;
          transition: background 0.1s;

          &:global(._isActive) {
            color: #828282;

            svg {
              color: #828282;
            }
          }

          &:hover {
            background: #f6f6f6;
          }

          svg {
            display: block;
            color: $black;
            margin-right: 8px;
            padding-bottom: 2px;
          }
        }
      }
    }
  }

  & .expand {
    position: absolute;
    right: -12px;
    top: calc(50% - 12px);
    z-index: 10;

    &:global(._isExpandStart) {
      &:after {
        position: absolute;
        content: '';
        background: $tr;
        top: -75px;
        left: -75px;
        right: -75px;
        bottom: -75px;
      }
    }

    & .button {
      background: #ffffff;
      border: 1px solid #ebebeb;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      transition: background 0.1s, border 0.1s;

      &:hover {
        background: #e3ecff;
        border: 1px solid $blue;

        svg {
          color: $blue;
        }
      }

      svg {
        color: $black;
      }
    }
  }
}
