.loader_wrap {
  margin-top: 80px;
  & .text_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
}
