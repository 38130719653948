@import 'src/styles/variables';
@import '~styles/main';

.actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.tabs {
  :global(.react-tabs__tab-list) {
    padding: 0;
    margin: 0;
  }
  :global(.InfoBlock) {
    padding: 12px 20px;
  }
  :global(.InfoBlock_text) {
    font-size: 14px;

    a {
      color: #025dff;
    }
  }
  :global(.react-tabs__tab-panel) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  :global(.react-tabs__tab) {
    display: flex;
    align-items: center;
    gap: 8px;
    .warning {
      padding-left: 0;
      margin-top: 0;

      svg {
        margin-right: 0;
      }
    }
  }
}

.add_panel {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 269px auto;
  align-items: start;
  gap: 18px;

  &::-webkit-scrollbar {
    width: 4px;
  }
}

.add_btn {
  @include dashedBtn;
}

.disable {
  color: grey;
  border-color: grey;
  pointer-events: none;

  svg {
    color: grey;
  }
}

.settings_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 4px;
  }
}

.error_block {
  border-radius: 1px;
  background: var(--status-alerts-and-notification-red-10, #ffe3e3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  gap: 10px;

  .left {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .close {
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      color: $blue;
    }
  }
}

.settings_item {
  display: grid;
  grid-template-columns: 20px 250px auto 60px;
  align-items: baseline;
  gap: 20px;
  padding: 5px 0;

  &:global(.isCreative) {
    grid-template-columns: auto 20px;

    :global(.horizontal) {
      :global(.label) {
        width: 150px;
        margin-right: 0;
      }
    }
    :global(.input-form-group.horizontal) {
      grid-template-areas:
        'label title'
        'label error';
      align-items: baseline;
    }
  }

  &_number {
  }
  &_select {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &_link {
  }
  &_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      vertical-align: middle;
      cursor: pointer;

      &:hover {
        color: #025dff;
      }
    }

    .clean {
      &:global(._isDisabled) {
        color: #828282;
        cursor: not-allowed;
        svg {
          cursor: not-allowed;

          &:hover {
            color: #828282;
          }
        }
      }
    }
  }
}

// :global(.TooltipPoratal) {
//   font-size: 14px;
//   line-height: 22px;
// }

.warning {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: -15px;
  padding-left: 150px;
  svg {
    vertical-align: middle;
    margin-right: 0;
  }
  span {
    color: #ff9c08;
  }
}
