@import 'src/styles/variables';
@import '~styles/main';

.wrapper {
  position: relative;
  background: $white;

  &:global(.isLastEdited) {
    @include last-edited-row-animation;

    & .indicator {
      @include last-edited-indicator-animation;
    }
  }

  & .indicator {
    @include indicator;
  }
}

.item {
  background: $tr;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0 20px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: $backlightItems;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  }

  @include tablet {
    padding: 0 16px;
  }

  .tabelHeaderLeft {
    @include leftPartForCampaignItem;

    & > a {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 1;

      @include tablet {
        display: none;
      }
    }
  }

  .valuesContainer {
    @include centerPartForCampaignItem;
    @include setGridTamplateForCampaignLine;

    @include tablet {
      height: 74px;
    }

    .value {
      @include calcSizeForCampaignItem;
    }
  }

  .tableHeaderRight {
    @include rightPartForCampaignItem;

    .actions {
      @include tablet {
        display: none;
      }
    }
  }

  &:global(._isFull) {
    .tabelHeaderLeft {
      width: $widthLeftPartCampaignItemFull;
      @include mobileLeftSize;
    }

    .item__header {
      /*16 px - gap, 34px - toggle, 42px - icon*/
      grid-template-columns: 42px calc(
          #{$widthLeftPartCampaignItemFull} - 16px - 34px - 16px - 42px - 16px
        );
      /*16 px - gap, 34px - toggle, 42px - icon*/
      width: calc(#{$widthLeftPartCampaignItemFull} - 16px - 34px - 16px);

      @include tablet {
        grid-template-columns: 100%;
        width: calc(#{$widthLeftPartCampaignItemMobile} - 16px - 34px - 16px);
      }

      .text {
        strong {
          max-width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          white-space: break-spaces;

          @include tablet {
            -webkit-line-clamp: 4;
          }
        }
      }
    }
  }

  & .toggle__status,
  .checkbox {
    position: relative;
    z-index: 2;
  }

  &:global(._isStatus) {
    .tableHeaderRight {
      justify-content: space-between;

      // & > div {
      //   &:first-child {
      //     opacity: 1;
      //   }
      // }
    }
  }

  &:global(._isNew) {
    animation: new-item 1s ease-out forwards;
  }

  &:global(._isOpen) {
    .valuesContainer {
      opacity: 0;
    }

    .tabelHeaderLeft {
      & > a {
        display: none;
      }
    }

    .tableHeaderRight {
      .value {
        opacity: 0;
      }

      .actions {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
      }
    }

    & .dropdown {
      &:global(._isNoAnimate) {
        .dropdown__menu {
          & > div {
            animation: show-item 0s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;
          }
        }
      }

      &__menu {
        & > div {
          animation: show-item 0.2s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;
          margin-left: 15px;

          & > div {
            margin-left: 0;
          }

          &:nth-last-child(1) {
            animation: none;
            opacity: 1;
          }

          @for $i from 2 to 10 {
            &:nth-last-child(#{$i}) {
              animation-delay: calc(#{$i * 0.1}s - 0.1s);
            }
          }
        }

        @keyframes show-item {
          0% {
            transform: translateY(40px);
            opacity: 0;
          }

          100% {
            transform: translateY(0px);
            opacity: 1;
          }
        }
      }
    }
  }

  &:global(._isDeleted) {
    pointer-events: none;
    opacity: 0.7;
  }

  & .value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    &:global(._isWheek) {
      justify-content: space-between;
    }

    &:global(._isGrey) {
      font-size: 12px !important;
      line-height: 12px !important;
      font-weight: 500;
      color: #828282;

      @include tablet {
        display: none;
      }
    }

    &:global(.center) {
      justify-content: center;
    }
  }

  &__header {
    flex: none;
    display: grid;
    /*16 px - gap, 34px - toggle, 42px - icon*/
    grid-template-columns: 42px calc(
        #{$widthLeftPartCampaignItem} - 16px - 34px - 16px - 42px - 16px
      );
    z-index: 1;
    gap: 0 16px;
    /*16 px - gap, 34px - toggle, 42px - icon*/
    width: calc(#{$widthLeftPartCampaignItem} - 16px - 34px - 16px);

    @include tablet {
      grid-template-columns: 100%;
      width: calc(#{$widthLeftPartCampaignItemMobile} - 16px - 34px - 16px);
    }

    & .img {
      border-radius: 100%;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #e3ecff;
      font-weight: 500;
      font-size: 14px;
      z-index: 3;
      position: relative;
      transition: 0.3s;

      &_wrapper {
        max-width: 100%;
        max-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 100%;
      }

      &:hover {
        background: darken($color: #e3ecff, $amount: 10%);
      }

      &:active {
        background: darken($color: #e3ecff, $amount: 20%);
      }

      &:global(._thereIsNoIcon) {
        --color-primary-darker: color-mix(
          in srgb,
          var(--color-for-img),
          #000 15%
        );
        background-color: var(--color-for-img);

        &:hover {
          background-color: var(--color-primary-darker);
        }
      }

      @include tablet {
        display: none;
      }

      & .status {
        width: 12px;
        height: 12px;
        border: 2px solid $white;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2;
      }
    }

    & .text {
      padding-right: 10px;

      strong {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;

        @include tablet {
          max-width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: break-spaces;
        }
      }
    }

    & .hint {
      color: #828282;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > * {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }
      }

      span {
        &:nth-child(2) {
          user-select: all;
          cursor: default;
        }
      }

      & .advertiser {
        display: block;
        max-width: 450px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-transform: uppercase;
        white-space: nowrap;

        @include laptop {
          max-width: 400px;
        }
      }

      & .prices {
        width: 100%;
      }
    }

    & .tooltip {
      margin-left: 0;
      z-index: 10;
      width: 100%;
    }
  }

  &__status {
    display: grid;
    grid-gap: 0 14px;
    align-items: center;
    justify-content: start;
    grid-auto-flow: column;

    div {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 2px solid #149c08;
      background: #c8fcc3;
    }

    span {
      color: #149c08;
      font-size: 14px;
      line-height: 22px;
    }

    &:global(._isStop) {
      div {
        background: #fff2c5;
        border-color: #ff9c08;
      }

      span {
        color: #ff9c08;
      }
    }

    &:global(._isDeleted) {
      div {
        background: #ffdbdb;
        border-color: #ff3636;
      }

      span {
        color: #ff3636;
      }
    }
  }
}

.dropdown {
  z-index: 2;
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  opacity: 1;
  visibility: visible;

  &:global(._isOpen) {
    opacity: 1;
    visibility: visible;

    & .dropdown__show {
      opacity: 1;
      z-index: 0;
      background: #f4f5f6;
    }

    & .dropdown__menu {
      opacity: 1;
      visibility: visible;
    }
  }

  &:global(._isDeleted) {
    & .dropdown__menu {
      right: 55px;
    }
  }

  &__show {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #828282;
    cursor: pointer;
    transition: all 0.1s ease;

    &:disabled {
      cursor: default;
    }
  }

  &__menu {
    position: absolute;
    right: 55px;
    top: 0;
    bottom: 0;
    display: grid;
    height: 100%;
    grid-auto-flow: column;
    align-items: center;
    justify-content: end;
    opacity: 0;
    visibility: hidden;
    z-index: 2;

    &:before {
      position: absolute;
      content: '';
      top: -20px;
      bottom: -20px;
      left: 70px;
      right: -77px;
      z-index: 0;
    }

    & > div {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      opacity: 0;

      & > span {
        display: block;
        padding: 4px 10px;
        background: #222222;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        opacity: 0;
        visibility: hidden;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 8px 0 8px;
          border-color: #222222 transparent transparent transparent;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &:hover {
        span {
          opacity: 1;
          visibility: visible;
        }

        svg {
          color: #025dff;
        }
      }
    }

    .disabled {
      pointer-events: none;
    }
  }

  & .dropdown__show {
    opacity: 1;
    z-index: 0;
  }

  & .dropdown__menu {
    opacity: 1;
    visibility: visible;

    & > div {
      &:last-child {
        opacity: 1;
      }
    }
  }
}

.status {
  z-index: 2;

  &:global(._isHide) {
    opacity: 0;
    pointer-events: none;
  }

  svg {
    vertical-align: middle;
  }
}

:global(.TooltipPoratal) {
  .statusRaw {
    position: relative;
    padding-left: 20px;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 0.5px solid #ebebeb;

    &:last-child {
      border: none;
      margin-bottom: 0px;
      padding-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #ff3636;
    }

    &:global(._isGreen) {
      &::before {
        background-color: #149c08;
      }
    }

    &:global(._isYelow) {
      &::before {
        background-color: #ff9c08;
      }
    }
  }

  .checkCircle {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      color: #149c08 !important;
      height: 20px;
      width: 20px;
    }
  }
}

.statusHint {
  z-index: 101;
}

@keyframes new-item {
  0% {
    box-shadow: 0px 0px 00px rgba(2, 85, 255, 0);
  }

  30% {
    box-shadow: 0px 6px 60px rgba(2, 85, 255, 0.22);
  }

  100% {
    box-shadow: 0px 0px 0px rgba(2, 85, 255, 0);
  }
}

// @keyframes last-edited-row {
//   0% {
//     background: #f4f5f6;
//   }

//   50% {
//     background: $white;
//   }

//   100% {
//     background: $white;
//   }
// }

// @keyframes last-edited-indicator {
//   0% {
//     opacity: 1;
//     top: 3px;
//     bottom: 3px;
//   }

//   10% {
//     opacity: 1;
//     top: 2px;
//     bottom: 2px;
//   }

//   100% {
//     opacity: 0;
//     top: 50%;
//     bottom: 50%;
//   }
// }
