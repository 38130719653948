@import 'src/styles/variables';

.project {
  position: relative;
  background: #f5f5f5;
  min-height: calc(100vh - 58px);

  & > .header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .content {
    padding-top: 28px;
    padding-bottom: 40px;

    & .table {
      display: block;

      & .header {
        display: grid;
        padding: 0 20px;
        grid-template-columns: 280px 200px 1fr 1fr auto;
        grid-gap: 0 15px;
        align-items: center;

        @include laptop {
          grid-template-columns: 320px auto auto auto auto;
        }

        div {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          letter-spacing: 0.03em;
          padding-bottom: 16px;
        }
      }

      & .body {
        & > div {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.item {
  background: $white;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  padding: 17px 26px 17px 22px;
  grid-template-columns: 280px 200px 1fr 1fr auto;
  display: grid;
  align-items: center;
  grid-gap: 0 15px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;

  @include laptop {
    grid-template-columns: 320px auto auto auto auto;
  }

  & > a {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  &:hover {
    background-color: $backlightItems;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    z-index: 10;
  }

  & .value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    flex-wrap: wrap;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-self: start;
    justify-content: flex-start;
    align-items: center;
    max-width: 280px;
    z-index: 1;

    & .img {
      border-radius: 50%;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #3137bd;
      font-weight: 500;
      font-size: 14px;
      margin-right: 16px;
      z-index: 1;
      position: relative;
    }

    & .text {
      width: calc(100% - 58px);

      & .tooltip {
        margin-left: 0;
        z-index: 10;
        width: 100%;
      }

      strong {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}
