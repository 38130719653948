@import 'src/styles/variables';

.title {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #222;
  cursor: pointer;
  transition: margin-bottom ease-in-out 0.5s;

  .collapse {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  &:global(._openWidget) {
    margin-bottom: 23px;

    .collapse {
      transform: rotate(90deg);
    }
  }
}

@keyframes collapse {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}

.childrenWrapper {
  max-height: 800px;
  visibility: visible;
  opacity: 1;
  overflow: hidden;
  transition: all ease-in-out 0.4s;

  &:global(._isOpen) {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: all ease-in-out 0.4s;
  }
}
