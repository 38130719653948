@import 'src/styles/variables';

$widthTitle: 600px;

.wrapper {
  position: relative;
  background: $white;

  &:global(.isLastEdited) {
    animation: last-edited-row 3s ease-out forwards;

    & .indicator {
      animation: last-edited-indicator 3s ease-out forwards;
    }
  }

  & .indicator {
    position: absolute;
    top: 50%;
    left: 2px;
    width: 4px;
    bottom: 50%;
    border-radius: 2px;
    background: $green;
    display: block;
    opacity: 0;
  }
}

.item {
  background: $tr;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  padding: 17px 26px 17px 22px;
  grid-template-columns: 40px $widthTitle auto;
  display: grid;
  align-items: center;
  grid-gap: 0 15px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;

  @include tablet {
    grid-template-columns: 40px auto auto;
  }

  &:hover {
    background: $backlightItems;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  }

  & > a {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  &:hover {
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  }

  & > div {
    transition: all 0.2s ease;

    &:last-child {
      justify-self: end;
    }
  }

  &:global(._isNew) {
    animation: new-item 1s ease-out forwards;
  }

  &:global(._isDeleted) {
    pointer-events: none;
    opacity: 0.7;
  }

  &:global(._isOpen) {
    grid-template-columns: 40px $widthTitle auto;

    @include tablet {
      grid-template-columns: auto auto;
    }

    & > div {
      &:last-child {
        display: flex;
        justify-self: end;
      }
    }

    & > a {
      display: none;
    }

    & .item__header {
      display: flex;

      @include tablet {
        display: none;
      }

      & > div {
        &:last-child {
          overflow: inherit;
        }
      }
    }

    & .dropdown {
      &:global(._isNoAnimate) {
        .dropdown__menu {
          & > div {
            animation: show-item 0s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;
          }
        }
      }

      &__menu {
        & > div {
          animation: show-item 0.2s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;

          &:nth-last-child(1) {
            animation: none;
            opacity: 1;
          }

          @for $i from 2 to 10 {
            &:nth-last-child(#{$i}) {
              animation-delay: calc(#{$i * 0.1}s - 0.1s);
            }
          }
        }

        @keyframes show-item {
          0% {
            transform: translateY(40px);
            opacity: 0;
          }
          100% {
            transform: translateY(0px);
            opacity: 1;
          }
        }
      }
    }
  }

  & .toggle__status {
    position: relative;
    z-index: 2;
  }

  & .value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    flex-wrap: wrap;
    z-index: 2;

    &:global(.isGroups) {
      margin: -4px;
    }

    & .group {
      display: inline-block;
      padding: 4px 8px;
      background: #f4f5f6;
      border-radius: 1px;
      margin: 4px;
    }

    & .login {
      display: block;
      margin-left: 0;
      z-index: 10;
      width: 100%;

      span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }

      & .tooltipHint {
        white-space: normal;
        text-overflow: none;
        overflow: auto;
        word-break: break-all;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: $widthTitle;
    position: relative;
    z-index: 1;

    & > a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    & .img {
      border-radius: 50%;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: $tr;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      font-weight: 500;
      font-size: 14px;
      margin-right: 16px;

      & .status {
        width: 12px;
        height: 12px;
        border: 2px solid $white;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2;
      }
    }

    & .text {
      width: calc(100% - 58px);

      & .tooltip {
        margin-left: 0;
        width: 100%;
      }

      strong {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }

    & .hint {
      color: #828282;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & > * {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }
      }

      & .type {
        display: block;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  @keyframes new-item {
    0% {
      box-shadow: 0px 0px 00px rgba(2, 85, 255, 0);
    }
    30% {
      box-shadow: 0px 6px 60px rgba(2, 85, 255, 0.22);
    }
    100% {
      box-shadow: 0px 0px 0px rgba(2, 85, 255, 0);
    }
  }
}

.actions {
  display: flex;
  align-items: center;

  @include tablet {
    height: 46px;
  }

  & > div {
    &:first-child {
      margin-right: 18px;
      z-index: 4;

      @include tablet {
        margin-right: 0;
      }
    }
  }
}

.dropdown {
  z-index: 2;
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  opacity: 1;
  visibility: visible;

  &:global(._isOpen) {
    & .dropdown__show {
      background: #f4f5f6;
    }

    & .dropdown__menu {
      &::before {
        left: 0;
      }
    }
  }

  &:global(._isDeleted) {
    & .dropdown__menu {
      right: 55px;
    }
  }

  & .dropdown__show {
    opacity: 1;
    z-index: 0;
  }

  & .dropdown__menu {
    opacity: 1;
    visibility: visible;
  }

  &__show {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #828282;
    cursor: pointer;
    transition: all 0.1s ease;

    &:disabled {
      cursor: default;
    }
  }

  &__menu {
    position: absolute;
    right: 60px;
    top: 0;
    bottom: 0;
    display: grid;
    height: 100%;
    grid-auto-flow: column;
    grid-gap: 0 25px;
    align-items: center;
    justify-content: end;
    opacity: 0;
    visibility: hidden;
    z-index: 2;

    &:before {
      position: absolute;
      content: '';
      top: -20px;
      bottom: -20px;
      left: 70px;
      right: -110px;
      z-index: 0;
    }

    & > div {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      opacity: 0;

      &:last-child {
        animation: none;
        opacity: 1;
      }

      & > span {
        display: block;
        padding: 4px 10px;
        background: #222222;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        opacity: 0;
        visibility: hidden;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 8px 0 8px;
          border-color: #222222 transparent transparent transparent;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &:hover {
        span {
          opacity: 1;
          visibility: visible;
        }
        svg {
          color: #025dff;
        }
      }
    }

    svg {
      width: 24px;
      height: auto;
    }
  }
}

@keyframes last-edited-row {
  0% {
    background: #f4f5f6;
  }
  50% {
    background: $white;
  }
  100% {
    background: $white;
  }
}

@keyframes last-edited-indicator {
  0% {
    opacity: 1;
    top: 3px;
    bottom: 3px;
  }
  10% {
    opacity: 1;
    top: 2px;
    bottom: 2px;
  }
  100% {
    opacity: 0;
    top: 50%;
    bottom: 50%;
  }
}
