@import 'src/styles/variables';

.additional {
  & .header {
    height: 102px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: $white;

    & .status {
      min-height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      & .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #222222;
        word-break: break-all;
      }
    }
  }

  & .content {
    padding: 28px 0;

    & > .container {
      display: grid;
      grid-gap: 16px 0;
    }

    & .card {
      padding: 28px 22px;

      & .header {
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        & > h3 {
          flex: 1 0 auto;
        }

        & .actions {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          button {
            margin-right: 8px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      & .tabs {
        margin-top: 28px;

        & .panel {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 24px;

          &:global(.react-tabs__tab-panel--selected) {
            padding-top: 28px;
          }
        }

        & .radio {
          margin-bottom: 24px;
        }

        & .options {
          margin-left: 22px;
          margin-right: 22px;
        }

        & .search {
          margin-left: 22px;
          margin-right: 22px;
          margin-bottom: 8px;
          position: relative;

          button {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 0;
            cursor: pointer;

            & > svg {
              font-size: 24px;
              color: #828282;
            }

            &:hover {
              svg {
                color: #222;
              }
            }

            &:disabled {
              &:hover {
                color: #828282;
              }
            }
          }

          input {
            display: block;
            width: 100%;
            height: 40px;
            background: $white;
            border: 1px solid #e2e2e2;
            box-sizing: border-box;
            border-radius: 1px;
            color: $black;
            padding: 0 16px;
            font-size: 14px;
            line-height: 22px;

            &:active,
            &:focus {
              border: 1px solid $blue;

              &:hover {
                border: 1px solid $blue;
              }
            }

            &:hover {
              border: 1px solid #a7a7a7;
            }

            &::placeholder {
              color: #828282;
            }
          }
        }

        & .grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          overflow-y: scroll;
          max-height: 248px;
        }

        & .list {
          margin-left: -22px;
          margin-right: -22px;

          h4 {
            padding: 0 22px;
            margin-bottom: 16px;
          }

          & .item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-left: 22px;
            padding-right: 22px;

            &:hover {
              background: #f6f6f6;
            }

            & > div {
              display: block;
              width: 100%;
            }

            label {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              min-height: 40px;
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
}
