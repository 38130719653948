@import 'src/styles/variables';

.widget {
  min-height: 350px;
  &_header {
    display: flex;
    margin-bottom: 20px;
    & .title {
      flex: 1 0 auto;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: $black;
      display: flex;
      align-items: center;

      span {
        margin-right: 10px;
      }

      & .alertCircle {
        cursor: pointer;
      }
    }
    & .actions {
      width: 60px;
      display: flex;
      justify-content: space-between;
      & .update,
      .screen {
        cursor: pointer;
      }
      .screen:hover {
        svg path{
          stroke: #025dff;
        }
      }
      .disabled {
        svg path{
          stroke: #828282;
        }
      }
      .disabled:hover {
        svg path{
          stroke: #828282;
        }
      }
    }
  }
}
