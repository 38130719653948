@import 'src/styles/variables';

.player {
  figure {
    max-width: 100%;
    height: auto;
    background: $black;

    &[data-fullscreen='true'] {
      max-width: 100%;
      width: 100%;
      margin: 0;
      padding: 0;

      video {
        height: auto;
      }

      video::-webkit-media-controls {
        display: none !important;
      }

      .controls {
        position: absolute;
        bottom: 2%;
        width: 100%;
        z-index: 2147483647;

        li {
          width: 5%;
        }
      }
      .progress {
        width: 68%;
      }
    }
  }
  video {
    max-width: 100%;
    height: auto;
  }
}

.controls {
  padding: 0;
  margin: 0;
  display: none;
  list-style-type: none;
  overflow: hidden;
  background: transparent;

  li {
    padding: 0;
    margin: 0;
    float: left;
    width: 10%;
    margin-left: 0.3%;
    :first-child {
      margin-left: 0;
    }
  }

  button {
    width: 100%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.progress {
  width: 38%;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 20px;
  height: 1.25rem;
  margin-top: 2px;
  margin-top: 0.125rem;
  border: 1px solid #aaa;
  overflow: hidden;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  span {
    width: 0%;
    height: 100%;
    display: inline-block;
    background-color: #2a84cd;
  }
}
