@import 'src/styles/variables';

$sideSpace: 22px;

.body {
  &:global(.isLocked) {
    .quick {
      cursor: not-allowed;

      & > * {
        pointer-events: none;
      }
    }
  }

  & .title {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 22px;
    padding: 0 $sideSpace;
  }

  & .subtitle {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #828282;
    margin-bottom: 20px;
  }

  & .quick {
    padding: 0 $sideSpace;
    .items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 24px;

      .item {
        background: #f6f6f6;
        border-radius: 12px;
        padding: 5px 10px;
        cursor: pointer;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #222222;

        &:global(.active) {
          background: #e3ecff;
        }
      }
    }
  }

  & .actions {
    padding: 0 $sideSpace;
    padding-bottom: 13px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;

    .search {
      width: 30%;
      position: relative;

      .inputActions {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
      }

      .arrow {
        cursor: pointer;

        & > div {
          display: flex;
          align-items: center;
        }

        path {
          stroke: #e0e0e0;
        }

        &:global(.isActive) {
          path {
            stroke: #025dff;
          }
        }
      }

      .values {
        font-size: 14px;
        line-height: 24px;
      }

      .arrowLeft {
        margin-right: 10px;
      }

      .arrowRight {
        margin-left: 10px;
        margin-right: 10px;
      }

      .cross {
        cursor: pointer;

        & > div {
          display: flex;
          align-items: center;
        }
      }
    }

    .input {
      height: 32px;
    }

    .buttons {
      display: flex;
      align-items: center;

      & > div {
        margin-left: 14px;

        &:first-child {
          margin-left: 0;
        }
      }

      .clear {
        width: fit-content;
        padding: 5px 16px;
        background: #f4f5f6;
        border-radius: 1px;
        cursor: pointer;
        font-size: 13px;
        line-height: 20px;
        color: #222222;
        transition: 0.2s;
        &:hover {
          color: lighten(#222222, 20);
          background: darken(#f4f5f6, 5);
        }
      }
    }

    .update {
      cursor: pointer;
      svg {
        transition: 0.3s;
        vertical-align: middle;
      }
      &:hover {
        svg {
          color: #025dff;
        }
      }
      &:global(._isDisabled) {
        cursor: not-allowed;
        svg {
          pointer-events: none;
          color: #828282;
        }

        :global(.tooltipComponent) {
          &:hover {
            :global(.tooltipComponentTitle) {
              svg {
                color: #828282;
              }
            }
          }
        }
        :global(.tooltipComponentHint) {
          display: none;
        }
      }
    }

    .limits {
      cursor: pointer;
      opacity: 1;
      pointer-events: all;

      &:global(._isDisabled) {
        cursor: not-allowed;
        svg {
          pointer-events: none;
          color: #828282;
        }

        :global(.tooltipComponent) {
          &:hover {
            :global(.tooltipComponentTitle) {
              svg {
                color: #828282;
              }
            }
          }
        }
        :global(.tooltipComponentHint) {
          display: none;
        }
      }

      &:global(.isActive) {
        color: #025dff;
      }

      svg {
        vertical-align: middle;
      }
    }
  }

  & .list__wrap {
    .list {
      border-radius: 1px;
      padding-top: 10px;
      margin-bottom: 22px;
      overflow: auto;

      .clear {
        position: absolute;
        top: 18px;
        right: 18px;
        padding: 5px 16px;
        background: #f4f5f6;
        border-radius: 1px;
        cursor: pointer;
        font-size: 13px;
        line-height: 20px;
        color: #222222;
        transition: 0.2s;
        &:hover {
          color: lighten(#222222, 20);
          background: darken(#f4f5f6, 5);
        }
      }
    }
  }

  & .selected {
    padding: 24px $sideSpace;
    border-top: 1px solid #ebebeb;

    &_title {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #828282;
      margin-bottom: 12px;
    }

    .items {
      &:global(.isEditing) {
        margin-bottom: 20px;
      }
    }
  }
}

.loader {
  margin-top: 0;
  padding: 32px 0;
}

.list {
  position: fixed;
  z-index: -1;
  opacity: 0;
  background: $white;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  transform: translateY(-20px);
  transition: transform 0.3s;
  padding: 30px 16px;
  border-radius: 1px;

  &:global(.isOpened) {
    transform: translateY(0);
    opacity: 1;
    z-index: 1000;
  }
}

.fixed_tree {
  &:global(.isEmpty) {
    &::-webkit-scrollbar-thumb {
      display: inherit !important;
    }
  }
}
