@import 'src/styles/variables';

$diff: 30px;

@mixin checkboxBlock {
  .creatives {
    &_header {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;

      .total {
        flex: 1;
      }
    }
    &_body {
      border: 1px solid #e2e2e2;
      border-radius: 1px;
      max-height: 270px;
      overflow-y: auto;
      padding: 22px 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      align-items: flex-start;

      &:global(.isLoading) {
        overflow: hidden;
      }
      &:global(.isEmpty) {
        grid-template-columns: 1fr;
      }

      .empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
          margin-bottom: 14px;
        }
      }
    }
  }
  .search {
    height: 40px;
    padding: 0 10px;
    border: 1px solid #e2e2e2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.1s;
    max-width: 734px;
    flex-grow: 1;
    flex-shrink: 1;
    pointer-events: all;

    .icon {
      height: 24px;
    }

    & .input {
      flex: 1 0 auto;
      display: block;

      input {
        box-sizing: border-box;
        border-radius: 1px;
        padding: 0 12px;
        border: none;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        width: 100%;

        &::placeholder {
          color: #828282;
        }
      }
    }
  }
}

.content {
  .header {
    background: #fff;
    height: 102px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &_plag {
      &:global(._isFixedScroll) {
        height: calc(102px + #{$diff});
      }
    }

    &:global(._isFixed) {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 10000;
    }

    &_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .buttons {
      & > * {
        margin-left: 26px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .filters {
    padding: 20px;
    &_plag {
      &:global(._isFixedScroll) {
        height: calc(532px + #{$diff});
      }
    }
    &:global(._isFixed) {
      position: fixed;
      display: none;
    }
    &:global(._isShow) {
     display: block;
      left: 50%;
      top: 70px;
      transform: translate(-50%, 0);
      max-width: 1174px;
      width: 100%;
      box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
      z-index: 20000;

      .close {
        cursor: pointer;
      }

      .filters_header {
        justify-content: space-between;
      }
    }
    &_header {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #222;
      transition: margin-bottom ease-in-out 0.2s;
      height: 30px;

      .title {
        display: flex;
        align-items: center;
        flex: 1;
      }
      &_icon {
        display: flex;
        align-items: center;
        margin-right: 8px;
      }

      &:global(._openWidget) {
        margin-bottom: 25px;

        .filters_header_icon {
          transform: rotate(180deg);
        }
      }
    }
    &_body {
      visibility: visible;
      opacity: 1;
      transition: all ease-in-out 0.2s;

      &:global(._isClose) {
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        transition: all ease-in-out 0.2s;
      }
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .field {
      &_label {
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #828282;
        margin-bottom: 8px;
      }
    }

    @include checkboxBlock;
    
    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 30px;
    }
    .partners {
      flex: 1;
      margin-right: 30px;
      width: 100%;
      overflow: hidden;

      :global(.select_partner__control) {
        border: 1px solid #cccccc !important;
        min-height: 48px !important;
        cursor: pointer;

        &:global(.select_partner__control--is-focused) {
          border: 1px solid #025dff;
        }
      }
      :global(.select_partner__menu) {
        left: 1px;
        z-index: 11;
      }
      :global(.partner_option_left) {
        padding-right: 10px;

        :global(.title) {
          max-width: 320px;
        }
      }
    }
    .targets {
      max-width: 358px;
      width: 100%;
    }
    .list_field {
      height: 218px;
    }

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: #dddddd;
    }
  }

  .body {
    padding-top: 30px;
    padding-bottom: 30px;
    .empty {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 60px;
      &_text {
        max-width: 270px;
        margin-left: 60px;
        strong {
          display: block;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 16px;
        }
        span {
          display: block;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    .table {
      &_header {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-bottom: 20px;

        &_plag {
          &:global(._isFixedScroll) {
            height: $diff;
          }
        }

        .wrapper {
          display: grid;
          grid-template-columns: 0.96fr 0.49fr 1fr 0.75fr 0.75fr;

          & > div {
            &:first-child {
              margin-left: 20px;
            }
          }
        }

        &:global(._isFixed) {
          position: fixed;
          left: 0;
          top: 102px;
          width: 100%;
          background: #fff;
          box-shadow: 0px 2px 28px #00000026;
          padding-bottom: 13px;
        }
      }

      &_body {
      }

      &_row {
        display: grid;
        grid-template-columns: 0.96fr 0.49fr 1fr 0.75fr 0.75fr;
        align-items: center;
        background: #ffffff;
        border-radius: 1px;
        margin-bottom: 10px;
        height: 42px;

        & > div {
          padding-right: 10px;
          max-width: 100%;
          white-space: nowrap;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          &:first-child {
            margin-left: 20px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  :global(.react-tabs__tab-list) {
    border-bottom: none;
  }
  :global(.react-tabs__tab) {
    .help {
      display: none;
    }
  }
  :global(.react-tabs__tab--selected) {
    .help {
      position: absolute;
      display: inline-block;
      top: -4px;
      right: -32px;
      margin-left: 0;
    }
  }
}

.loading_item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:nth-child(9) {
    margin-bottom: 0;
  }

  &:nth-child(10) {
    margin-bottom: 0;
  }

  &:nth-child(11) {
    margin-bottom: 0;
  }

  &:nth-child(12) {
    margin-bottom: 0;
  }
}

.filterTooltip {
  &:hover {
    cursor: pointer;
  }
}

.download_btn {
  cursor: pointer;
  margin-left: 23px;
  svg {
    transition: 0.3s;
  }
  &:hover {
    svg {
      color: #025dff;
    }
  }
}
