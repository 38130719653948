@import 'src/styles/variables';

.form-group {
  position: relative;

  & > label {
    color: $text-hint;
    font-size: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
  }

  &:global(._isError) {
    label {
      color: $red;
    }

    input {
      border: 1px solid $red;

      &:focus {
        border-color: $red;
      }
    }
  }

  & .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: fade-out($color: $white, $amount: 0.15);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  & .errorText {
    color: $red;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    line-height: 1.3;

    svg {
      color: $red;
      margin-right: 6px;
      flex-shrink: 0;
    }
  }

  & .hintText {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
    line-height: 1.3;
    font-size: 13px;
    line-height: 20px;
    color: $black;

    svg {
      color: $black;
      margin-right: 6px;
      flex-shrink: 0;
    }
  }

  & .button {
    position: relative;
    display: inline-flex;
    overflow: hidden;

    &:global(.isDisabled) {
      label {
        cursor: not-allowed;
      }
    }

    button {
      position: relative;
      z-index: 1;
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 2;
    }

    input {
      display: none;
    }
  }
}
