@import 'src/styles/variables';
.download {
  cursor: pointer;

  &:global(.disabled) {
    .download__btn {
      pointer-events: none;
    }
  }

  &__btn {
    min-width: auto;
    margin: 0;
    padding: 0;
  
    svg {
      color: #222;
    }
  }  
}