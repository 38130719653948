@import 'src/styles/variables';

.card {
  margin: 0 auto;
  max-width: 774px;
  width: 100%;
  background: $white;
  display: block;
  pointer-events: all;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;

  &:global(._isDel) {
    max-width: 418px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 28px;

  & .title {
    flex: 1 0 auto;
    max-width: calc(100% - 60px);
  }

  & .close {
    margin-left: 24px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    justify-content: center;
    align-items: center;

    svg {
      cursor: pointer;
      color: $black;
    }
  }
}

.content {
  padding: 0 28px;

  & .search {
    border: 1px solid #ebebeb;
    border-bottom: none;

    label {
      input {
        border: none;
        height: 40px;
        display: block;
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        padding: 0 15px;

        &::placeholder {
          color: #828282;
        }
      }
    }
  }

  & .list {
    border: 1px solid #ebebeb;
    height: 363px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $tr;
    }

    &::-webkit-scrollbar-thumb {
      background: #dddddd;
      border-radius: 15px;
    }

    & .item {
      min-height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      background: $tr;
      transition: background 0.1s;
      padding: 0 14px;
      cursor: pointer;

      &:hover {
        background: #f4f5f6;
      }

      & > div {
        width: 100%;

        label {
          display: block;
          padding: 9px 0;
          width: 100%;
        }
      }

      &:global(.context) {
        display: block;
      }
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 28px 28px;

  & > * {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
  & .col {
    flex: 1 0 auto;

    &.fixWidth {
      max-width: 184px;
      width: 100%;
      display: flex;
      align-items: center;

      &:global(.error) {
        color: $red;
        svg {
          color: $red;
        }
      }

      @include phone {
        max-width: fit-content;
      }
    }

    .title {
      display: flex;
      align-items: center;
      padding-right: 10px;
      font-size: 14px;
      line-height: 22px;

      svg {
        flex: none;
        margin-left: 10px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @include phone {
      margin-right: 0;
      max-width: 100%;
      flex: 1;
      width: 100%;
      margin-bottom: 10px;

      &.fixWidth {
        width: 100%;
        text-align: center;
        font-weight: 500;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    :global(.horizontal) {
      input {
        width: 190px;
      }
    }
  }
}
