@import 'src/styles/variables';

.card {
  position: relative;
  z-index: 2;
  background: $tr;
  max-width: 1174px;
  width: 100%;
}

.content {
  position: relative;
  z-index: 1;
  padding-top: 24px;
  padding-bottom: 8px;
  background: $white;

  & > div > div {
    border: none !important;
  }

  :global(.CodeMirror) {
    height: calc(100vh - 100px);
  }
}

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 2;

  &:hover {
    cursor: pointer;

    & .button {
      background: #e2e2e2;

      svg {
        color: $black !important;
      }
    }
  }

  & .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    transition: background 0.1s;
  }
}
