@import 'src/styles/variables';

.tags-select {
  &__control {
    border-radius: 1px !important;
    min-height: 48px !important;

    &--is-focused {
      border-color: $blue !important;
      box-shadow: none !important;
    }
  }
  &__value-container {
    padding: 2px 14px !important;
  }
  &__placeholder {
    font-size: 14px !important;
    color: #828282 !important;
  }
  &__input {
    input {
      color: $black !important;
      font-size: 14px !important;
    }
  }
  &__menu {
    border-radius: 1px !important;
    border: none !important;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22) !important;

    &-list {
      padding: 0 !important;
      &::-webkit-scrollbar {
        width: 4px !important;
        height: 4px !important;
      }

      &::-webkit-scrollbar-track {
        background: $tr !important;
      }

      &::-webkit-scrollbar-thumb {
        background: #dddddd !important;
        border-radius: 15px !important;
      }
    }
  }
  &__option {
    color: $black !important;
    font-size: 14px !important;
    padding: 17px 14px !important;
    cursor: pointer !important;
    &:active {
      background: rgba(0, 0, 0, 0.05) !important;
    }
    &--is-focused {
      background: rgba(0, 0, 0, 0.05) !important;
    }
    &--is-active {
      background: rgba(0, 0, 0, 0.05) !important;
    }
    &--is-selected {
      color: #828282 !important;
      cursor: default !important;
      background: #fff !important;
    }
  }
}
