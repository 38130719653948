@import 'src/styles/variables';

.notification {
  background: #e3ecff;

  & > div {
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
      display: inline-block;
      border: none;
      background: transparent;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.9;
      }

      svg {
        font-size: 20px;
      }
    }
  }

  & .content {
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      line-height: 22px;
      margin-left: 16px;

      a {
        color: $blue;
        transition: color 0.1s;

        &:hover {
          opacity: 1;
          color: $dark-blue;
        }
      }
    }
  }
}
