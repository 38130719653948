@import 'src/styles/variables';

.dashbord {
  svg {
    color: $blue;
  }
  &:global(._isNotNullStatistics) {
    &:hover {
      svg {
        color: inherit;
      }
    }
    svg {
      color: inherit;
    }
  }
}
