.card {
  .title {
    margin-bottom: 28px;
    display: flex;
    align-items: center;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .hint {
    margin-top: 20px;
  }
}
