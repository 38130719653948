@import 'src/styles/variables';

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 16px;
    line-height: 22px;
  }

  svg {
    color: #222222;
    width: 16px;
    height: auto;
  }
}
