@import 'src/styles/variables';

.page {
  &:global(._isFixedHeader) {
    .header {
      position: fixed;
      left: 0;
      top: 48px;
      width: 100%;
      z-index: 500;
    }
    .list_header {
      position: fixed;
      left: 0;
      top: calc(102px + 48px);
      width: 100%;
      z-index: 499;
      box-shadow: 0px 2px 28px #00000026;
      background-color: #fff;
      padding: 0px 0px 10px 0px;
    }
    .list_headerWrapper {
      max-width: $containerWidth;
      margin: 0 auto;
      padding-right: calc(22px + #{$containerPaddingRight});
      padding-left: calc(22px + #{$containerPaddingRight});
      @include desktopXL {
        max-width: $containerWidth-mob;
        padding-right: calc(22px + #{$containerPaddingRight-mob});
        padding-left: calc(22px + #{$containerPaddingRight-mob});
      }
    }
  }

  &:global(._isShowDeletedAlert) {
    .alert {
      transform: translateX(-50%) translateY(0%);
    }
  }
}

.header {
  background: #fff;
  height: 102px;
  display: flex;
  align-items: center;

  .headeRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 40px;

    .btns {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .update {
    cursor: pointer;
  }
}

@mixin gapForGrid {
  gap: 50px;
}

/* Стуктура для глобального типа */
@mixin globalStruct {
  grid-template-columns:
  18px minmax(100px, 0.5fr) minmax(100px, 0.8fr) minmax(100px, 0.8fr)
  minmax(150px, 1fr);
}

/* Стуктура для глобального типа (удаленный) */
@mixin globalStructDeleted {
  grid-template-columns:
  18px minmax(100px, 0.5fr) minmax(100px, 0.8fr) minmax(100px, 0.8fr)
  minmax(150px, 1fr) minmax(250px, 1.5fr);
}

/* Стуктура для клиентского типа */
@mixin clientStruct {
  grid-template-columns:
  18px minmax(100px, 0.5fr) minmax(100px, 0.8fr) minmax(100px, 0.8fr)
  minmax(150px, 1fr) minmax(100px, 0.8fr);
}

/* Стуктура для клиентского типа (удаленный) */
@mixin clientStructDeleted {
  grid-template-columns:
  18px minmax(100px, 0.5fr) minmax(100px, 0.8fr) minmax(100px, 0.8fr)
  minmax(150px, 1fr) minmax(100px, 0.8fr) minmax(250px, 1.5fr);
}

.content {
  padding-top: 28px;
  padding-bottom: 40px;
  .list {
    & .item {
      @include clientStruct;
      @include gapForGrid;
    }
    &_headerWrapper {
      @include clientStruct;
      @include gapForGrid;
    }
  }

  &:global(._isGlobal) {
    .list {
      & .item {
        @include globalStruct;

        &.loading {
          & > div {
            &:last-child {
              display: none;
            }
          }
        }
      }
      &_headerWrapper {
        @include globalStruct;
      }
    }
  }

  &:global(._isDeleted) {
    .list {
      & .item {
        @include clientStructDeleted;
      }
      &_headerWrapper {
        @include clientStructDeleted;
      }
    }

    &:global(._isGlobal) {
      .list {
        & .item {
          @include globalStructDeleted;
        }
        &_headerWrapper {
          @include globalStructDeleted;
        }
      }
    }
  }
}

.list {
  &_body {
    height: 65vh !important;
    @include laptop {
      height: 58vh !important;
    }
    table {
      width: 100%;
    }
  }
  & .item {
    display: grid;
    align-items: center;
    padding: 6px 22px;
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid #e2e2e2;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background: $grey-bg;
    }

    &:last-child {
      border: none;
    }

    .source {
      font-weight: 500;
    }

    .date {
      span {
        margin-left: 4px;
        color: #828282;
      }
    }

    div {
      word-break: break-word;
    }
  }
  &_header {
    padding: 0px 22px 25px 22px;
  }
  &_headerWrapper {
    display: grid;
    align-items: center;
  }
}

.alert {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%) translateY(1000%);
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.15);
  background: #e3ecff;
  padding: 10px 20px;
  transition: 0.3s;
  &_text {
    strong {
      font-weight: 500;
    }
  }
}
