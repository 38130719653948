@import 'src/styles/variables';

$height_item: 38px;
$whidth_options: 100px;
$left_padding_width: 400px;
$height_header: 90px;
$width: 570px;

/* Для мобильной версии*/
$whidth_options_MB: 50px;
$left_padding_width_MB: 270px;
$width_MB: 270px;

.table {
  padding-top: 30px;
  padding-bottom: 30px;
  display: block;
  width: 100%;

  &:global(._isThemeFull) {
    padding-top: 16px;
    padding-right: 10px;
    padding-left: 10px;

    :global(.full) {
      padding: 0;
    }

    &:global(._isFixed) {
      .header {
        top: 135px;
        padding-right: 10px;
        padding-left: 10px;

        & .fixed {
          & .text {
            padding-left: 14px;
          }

          .img {
            margin-right: 12px;
          }
        }

        & .scroll {
          & .sum {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }

    & .header {
      line-height: 14px;
      padding-bottom: 6px;

      & .scroll {
        width: 100%;
        padding-right: 10px;

        & .item {
          padding: 0 6px 0 6px;

          @for $i from 1 to 100 {
            &:global(.size-#{$i}) {
              width: calc(28px + #{$i * 5}px);
              font-size: 12px;
              line-height: 14px;
            }
          }

          &:global(._isItalic) {
            font-style: italic;
          }
        }
      }

      & .options {
        width: $whidth_options;
        display: flex;
        align-items: center;
        padding-left: 28px;

        @include tablet {
          width: $whidth_options_MB;
        }

        @include tablet {
          justify-content: flex-end;
        }

        &:global(.isScroll) {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100%;
            width: 22px;
            background: linear-gradient(to right, $tr 0%, #f4f4f4 100%);
          }
        }

        &:global(.fixed) {
          &:before {
            background: linear-gradient(to right, #fff0 0%, #ffffff 100%);
          }
        }
      }
    }

    & .body {
      padding-right: $whidth_options;

      @include tablet {
        padding-right: $whidth_options_MB;
      }

      & .content {
        :global(.row) {
          height: $height_item;
          padding-right: 10px;

          :global(.fixed) {
            height: $height_item;
          }

          :global(.options) {
            height: $height_item;
            width: $whidth_options;

            @include tablet {
              width: $whidth_options_MB;
            }
          }
        }
      }
    }
  }

  &:global(._isLong) {
    & .scrollbar {
      display: block;
    }

    & .body {
      & .content {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  &:global(._isFixed) {
    & .header {
      position: fixed;
      top: 146px;
      height: 70px;
      left: 0;
      right: 0;
      background: $white;
      box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.15);
      z-index: 997;
      align-items: center;
      padding-bottom: 0;
      padding: 10px 0;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #e2e2e2;
        z-index: 10;
      }

      & .fixed {
        align-items: center;

        &:after {
          background: linear-gradient(to left, $tr 0%, $white 100%);
        }

        & .text {
          padding-left: 29px;

          & .sum {
            display: block;
            padding-left: 0;
          }

          & .title {
            padding-left: 0;
          }
        }

        .img {
          display: block;
          margin-left: 12px;
          margin-right: 22px;
        }
        .filter {
          display: block;
          text-transform: none;
        }
      }

      & .scroll {
        & .sum {
          display: block;
        }
      }

      & .options {
        &:global(.fixed) {
          :global(.tooltipComponentHint) {
            display: none;
          }
        }
        &:before {
          background: linear-gradient(to right, $tr 0%, $white 100%);
        }
        
      }
    }
  }

  &:global(._isFullTitle) {
    & .header {
      & .fixed {
        width: $width;

        @include tablet {
          width: $width_MB;
        }
      }
    }
    & .body {
      padding-left: $width;

      @include tablet {
        padding-left: $width_MB;
      }
    }
    & .scrollbar {
      & .bar {
        margin-left: $width;
        width: calc(100% - #{$width});

        @include tablet {
          margin-left: $width_MB;
          width: calc(100% - #{$width_MB});
        }
      }
    }
  }

  & .select__box {
    display: block;
    margin-left: 12px;
  }

  & .listFilter {
    margin-left: 20px;
  }

  & .header {
    display: flex;
    transform: translate(0);
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $black;
    padding-bottom: 16px;
    position: relative;
    z-index: 1;

    :global(.container) {
      display: flex;
    }

    & .fixed {
      width: $left_padding_width;
      position: relative;
      z-index: 3;
      display: flex;
      flex: none;
      align-items: center;

      @include tablet {
        width: $left_padding_width_MB;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        background: linear-gradient(to left, $tr 0%, #f4f4f4 100%);
        width: 10px;
      }

      & .img,
      & .filter {
        display: none;
      }

      & .num {
        margin-left: 20px;
      }

      & .title {
        display: flex;
        padding-left: 24px;

        & > div {
          display: flex;
          align-items: center;

          &:last-child {
            margin-left: 20px;
          }
        }
      }

      & .sum {
        width: 100%;
        margin-top: 4px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        text-transform: none;
        padding-left: 120px;
        display: none;
      }
    }

    & .scroll {
      width: calc(100% - (#{$left_padding_width} + #{$whidth_options}));
      display: flex;
      overflow: hidden;
      position: relative;
      justify-content: space-between;
      align-items: center;

      @include tablet {
        width: calc(100% - (#{$left_padding_width_MB} + #{$whidth_options_MB}));
      }

      &::-webkit-scrollbar {
        display: none;
      }

      & .item {
        flex: none;
        box-sizing: content-box;
        width: 115px;
        padding: 0 10px;

        @for $i from 1 to 100 {
          &:global(.size-#{$i}) {
            width: calc(40px + #{$i * 7}px);
          }
        }

        &:global(._isGrey) {
          .sum {
            font-size: 12px !important;
            line-height: 12px !important;
            font-weight: 500;
            color: #828282;
          }
        }

        .last-child {
          padding-right: 22px;
        }

        .title {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      & .sum {
        display: none;
        margin-top: 4px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        text-transform: none;
        text-align: right;
      }
    }

    & .options {
      width: $whidth_options;
      align-self: center;
      flex: none;
      position: relative;
      padding-left: 10px;

      @include tablet {
        width: $whidth_options_MB;
      }

      :global(.TooltipPoratal) {
        display: inline-block;
      }
    }
  }

  & .body {
    position: relative;
    z-index: 1;
    padding-left: $left_padding_width;
    padding-right: $whidth_options;
    transform: translate(0);
    
    @include tablet {
      padding-left: $left_padding_width_MB;
      padding-right: $whidth_options_MB;
    }

    & .content {
      overflow-y: hidden;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        bottom: 0;
      }

      &::-webkit-scrollbar-track {
        background: $tr;
      }

      &::-webkit-scrollbar-thumb {
        background: #cbcbcb;
        border-radius: 26px;
      }

      :global(.row) {
        display: flex;
        flex-wrap: nowrap;
        flex: none;
        width: 100%;
        height: 48px;
        background: $white;
        border-radius: 1px;
        transition: box-shadow 0.1s;
        margin-bottom: 4px;
        position: relative;
        overflow: visible;
        justify-content: space-between;

        &:hover {
          background: $tr;

          :global(.fixed) {
            z-index: 5;

            &:after {
              display: none;
            }
          }

          :global(.options) {
            z-index: 5;
          }
        }

        :global(.fixed) {
          position: fixed;
          left: 0;
          z-index: 1;
          width: $left_padding_width;
          height: 48px;

          @include tablet {
            width: $left_padding_width_MB;
          }

          &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            background: linear-gradient(to left, $tr 0%, #fff 100%);
            width: 10px;
          }
        }

        :global(.options) {
          position: fixed;
          right: 0;
          z-index: 2;
          height: 48px;
          width: $whidth_options;

          @include tablet {
            width: $whidth_options_MB;
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100%;
            background: linear-gradient(to right, $tr 0%, #fff 100%);
            width: 22px;
          }
        }
      }
    }
  }

  & .scrollbar {
    display: none;
    position: fixed;
    bottom: 0;
    height: 20px;
    left: 0;
    right: 0;
    background: $white;
    z-index: 100;
    border-top: 1px solid #ebebeb;
    overflow: hidden;

    & .bar {
      margin-left: $left_padding_width;
      margin-right: $whidth_options;
      width: calc(100% - (#{$left_padding_width} + #{$whidth_options}));
      overflow-x: auto;
      overflow-y: hidden;
      height: 20px;
      position: relative;
      top: -7px;

      @include tablet {
        margin-left: $left_padding_width_MB;
        margin-right: $whidth_options_MB;
        width: calc(100% - (#{$left_padding_width_MB} + #{$whidth_options_MB}));
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: $tr;
      }

      &::-webkit-scrollbar-thumb {
        background: #cbcbcb;
        border-radius: 26px;
      }

      & .content {
        display: block;
        height: 30px;
        visibility: hidden;
      }
    }
  }

  & .header_placeholder {
    min-height: 34px;
  }
}

.filterList {
  cursor: default;
  padding-right: 16px;
}

.statusText {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
  margin-bottom: 4px;
}

.statusLink {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #025dff;

  &:hover {
    text-decoration: none;
  }
}
