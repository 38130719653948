@import 'src/styles/variables';

.header-account {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.isOpened {
    .header-account {
      &__chevron {
        transform: rotate(180deg);
      }
      &__dropdown {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }

  &__icon {
    display: block;
    height: 38px;
    width: 38px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;

    &.isDefault {
      border-radius: 0;
    }

    @include phone {
      display: none;
    }
  }

  &__text {
    display: block;
    margin-left: 8px;

    @include phone {
      margin-left: 0;
    }
  }

  &__login {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: $white;
    display: flex;
    align-items: center;

    @include phone {
      justify-content: flex-end;
      font-size: 11px;
      line-height: 11px;
    }
  }

  &__balance {
    font-size: 13px;
    line-height: 20px;
    color: $white;
    span {
      color: #f4f4f4;
    }

    @include phone {
      text-align: right;
      font-size: 11px;
      line-height: 11px;
      padding-right: 10px;
    }
  }

  &__chevron {
    transition: transform 0.1s;
  }

  &__dropdown {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-5px);
    background: $white;
    border-radius: 1px;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    top: calc(100% + 12px);
    left: 0;
    right: 0;
    z-index: 1000;
    transition: all 0.2s ease 0s;

    @include phone {
      left: initial;
      min-width: 150px;
    }

    &:before {
      content: '';
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0L0 8H16L8 0Z' fill='white'/%3E%3C/svg%3E%0A");
      width: 16px;
      height: 8px;
      top: -8px;
      left: 13px;

      @include phone {
        right: 13px;
        left: initial;
      }
    }

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 15px;
      min-height: 48px;
      background: $tr;
      transition: background 0.1s;

      &:hover {
        background: #f6f6f6;
      }

      svg {
        margin-right: 20px;
        color: $text-main;
      }

      span {
        font-size: 14px;
        line-height: 22px;
        color: $text-main;
      }
    }
  }
}
