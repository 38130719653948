@import 'src/styles/variables';

.alert {
  position: absolute;
  width: 100%;
  height: 75px;
  background: #ffffff;
  border: 1px solid #ff3636;
  box-sizing: border-box;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  z-index: 1;
  left: 0;
  top: -1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px;
  opacity: 0;
  animation-name: show;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  &:global(.isExtend) {
    animation-name: show2;
  }

  &__data {
    display: flex;
    align-items: center;
    gap: 16px;

    &_text {
      font-size: 14px;
      line-height: 22px;

      a {
        display: inline-block;
        margin-left: 2px;
        color: #025dff;
        text-decoration: underline;
      }
    }

    &_icon {
      svg {
        vertical-align: middle;
        fill: #ff3636;
        stroke: white;
      }
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      flex: none;
      vertical-align: middle;
    }
    width: 26px;
    height: 26px;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
      background: #f4f5f6;
    }
  }
}

@keyframes show {
  0% {
    top: 0px;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 86px;
    opacity: 1;
  }
  100% {
    top: 76px;
    opacity: 1;
  }
}

@keyframes show2 {
  0% {
    top: 0px;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 66px;
    opacity: 1;
  }
  100% {
    top: 56px;
    opacity: 1;
  }
}
