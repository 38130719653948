@import 'src/styles/variables';

$structureColumns: 50px 1.5fr 1fr 1.1fr 0.8fr 0.7fr;

.contractors {
  & .header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:global(.isToggleSearch) {
      .title {
        display: none;
      }
      .actions {
        flex: 1 0 auto;
      }
    }

    & .status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 22px;

        & .search {
          height: 40px;
          border: 1px solid $tr;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          transition: all 0.1s;

          svg {
            vertical-align: middle;
          }

          &:global(.isActive) {
            border: 1px solid #025dff;
            flex: 1 0 auto;
            padding: 0 10px;

            .close,
            .input {
              display: block;
            }
          }

          & .icon {
            cursor: pointer;
          }

          & .close {
            display: none;
            cursor: pointer;

            &:hover {
              svg {
                color: #222;
              }
            }

            svg {
              color: #828282;
            }
          }

          & .input {
            flex: 1 0 auto;
            display: none;

            input {
              box-sizing: border-box;
              border-radius: 1px;
              padding: 0 12px;
              border: none;
              font-size: 14px;
              line-height: 22px;
              color: $black;
              width: 100%;

              &:placeholder {
                color: #828282;
              }
            }
          }
        }

        & .period {
          margin-right: 20px;
        }

        & .toggle-title {
          margin-right: 32px;

          &:global(.isDisabled) {
            pointer-events: none;

            svg {
              color: #828282;
            }
          }

          & .tooltip {
            margin-left: 0;

            & > div {
              &:last-child {
                top: calc(50% - 19px);
              }
            }
          }
        }

        & .range {
          margin-right: 16px;
        }
      }
    }
  }

  & .content {
    padding-top: 28px;
    padding-bottom: 40px;
  }

  & .table {
    &_header {
      display: grid;
      padding: 0 20px;
      grid-template-columns: $structureColumns;
      grid-gap: 0 10px;
      align-items: center;
      margin-bottom: 16px;

      & > div {
        &:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &_body {
      & > div {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.item_wrapper {
  position: relative;
  background: $white;

  &:global(._isLastEdited) {
    @include last-edited-row-animation;

    & .indicator {
      @include last-edited-indicator-animation;
    }
  }

  & .indicator {
    @include indicator;
    left: 0;
  }
}

.item {
  background: $tr;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  padding: 17px 22px;
  grid-template-columns: $structureColumns;
  display: grid;
  align-items: center;
  grid-gap: 20px;
  min-height: 66px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;

  :global(.tooltipComponent) {
    z-index: 2;
  }

  & > div {
    &:nth-child(2) {
      justify-self: center;
    }
  }
  a {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    @include tablet {
      display: none;
    }
  }

  &:hover {
    background: $backlightItems;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  }

  &_header {
    display: flex;
    align-items: center;
    gap: 16px;

    & .img {
      border-radius: 50%;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #3137bd;
      font-weight: 500;
      font-size: 14px;
      z-index: 1;
      position: relative;
      flex: none;
    }
  }
  &_info {
  }
  &_title {
    font-weight: 500;
  }
  &_id {
    color: var(--neutral-grey-50, #828282);
    font-size: 12px;
    line-height: 18px;
  }



  &_id {
    color: var(--neutral-grey-50, #828282);
    font-size: 12px;
    line-height: 18px;
  }
  
  & .status {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: rgba(226, 226, 226, 1);
    &:global(._isActive) {
      background: #149c08;
    }
    &:global(._isDeleted) {
      background: #ff3636;
    }
    &:global(._isModeration) {
      background: #ff9c08;
    }
  }
}

