@import 'src/styles/variables';

.offers {
  & .header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:global(.isToggleSearch) {
      .title {
        display: none;
      }
      .actions {
        flex: 1 0 auto;
      }
    }

    & .status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 22px;

        & .search {
          height: 40px;
          border: 1px solid $tr;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          transition: all 0.1s;

          svg {
            vertical-align: middle;
          }

          &:global(.isActive) {
            border: 1px solid #025dff;
            flex: 1 0 auto;
            padding: 0 10px;

            .close,
            .input {
              display: block;
            }
          }

          & .icon {
            cursor: pointer;
          }

          & .close {
            display: none;
            cursor: pointer;

            &:hover {
              svg {
                color: #222;
              }
            }

            svg {
              color: #828282;
            }
          }

          & .input {
            flex: 1 0 auto;
            display: none;

            input {
              box-sizing: border-box;
              border-radius: 1px;
              padding: 0 12px;
              border: none;
              font-size: 14px;
              line-height: 22px;
              color: $black;
              width: 100%;

              &:placeholder {
                color: #828282;
              }
            }
          }
        }

        & .period {
          margin-right: 20px;
        }

        & .toggle-title {
          margin-right: 32px;

          &:global(.isDisabled) {
            pointer-events: none;

            svg {
              color: #828282;
            }
          }

          & .tooltip {
            margin-left: 0;

            & > div {
              &:last-child {
                top: calc(50% - 19px);
              }
            }
          }
        }

        & .range {
          margin-right: 16px;
        }
      }
    }
  }

  & .content {
    padding-top: 28px;
    padding-bottom: 40px;

    & .table {
      &_header {
        display: grid;
        padding: 0 20px;
        grid-template-columns: minmax(130px, 1fr) minmax(350px, 3fr) 40px;
        grid-gap: 0 10px;
        align-items: center;
        margin-bottom: 16px;
      }
      &_body {
        & > div {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.item_wrapper {
  position: relative;
  background: $white;

  @keyframes last-edited-row {
    0% {
      background: #f4f5f6;
    }
    50% {
      background: $white;
    }
    100% {
      background: $white;
    }
  }

  @keyframes last-edited-indicator {
    0% {
      opacity: 1;
      top: 3px;
      bottom: 3px;
    }
    10% {
      opacity: 1;
      top: 2px;
      bottom: 2px;
    }
    100% {
      opacity: 0;
      top: 50%;
      bottom: 50%;
    }
  }

  &:global(.isLastEdited) {
    animation: last-edited-row 3s ease-out forwards;

    & .indicator {
      animation: last-edited-indicator 3s ease-out forwards;
    }
  }

  & .indicator {
    position: absolute;
    top: 50%;
    left: 2px;
    width: 4px;
    bottom: 50%;
    border-radius: 2px;
    background: $green;
    display: block;
    opacity: 0;
  }
}

.item {
  background: $tr;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  padding: 17px 22px;
  grid-template-columns: minmax(130px, 1fr) minmax(350px, 3fr) 40px;
  display: grid;
  align-items: center;
  grid-gap: 0 10px;
  min-height: 66px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;

  & > a {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  @keyframes new-item {
    0% {
      box-shadow: 0 0 0 rgba(2, 85, 255, 0);
    }
    30% {
      box-shadow: 0 6px 60px rgba(2, 85, 255, 0.22);
    }
    100% {
      box-shadow: 0 0 0 rgba(2, 85, 255, 0);
    }
  }

  &:global(._isNew) {
    animation: new-item 1s ease-out forwards;
  }

  &:global(._isOpen) {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;

    & > div {
      display: none;

      &:nth-child(1) {
        display: flex;
      }

      &:last-child {
        display: flex;
        justify-self: stretch;
      }
    }

    & > a {
      display: none;
    }

    & .item__header {
      max-width: 800px;

      & > div {
        &:last-child {
          overflow: inherit;
        }
      }
    }

    & .dropdown {
      &__menu {
        @keyframes show-item {
          0% {
            transform: translateY(40px);
            opacity: 0;
          }
          100% {
            transform: translateY(0px);
            opacity: 1;
          }
        }

        & > div {
          animation: show-item 0.2s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;

          @for $i from 2 to 10 {
            &:nth-last-child(#{$i}) {
              animation-delay: #{($i * 0.1) - 0.1}s;
            }
          }
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
    z-index: 10;
    background: $backlightItems;
  }

  & > div {
    transition: all 0.2s ease;

    &:last-child {
      justify-self: end;
    }
  }

  & .value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    flex-wrap: wrap;
    z-index: 2;
    line-height: 18px;

    & .group {
      display: block;
      padding: 6px 8px;
      background: #e3ecff;
      border-radius: 8px;
      margin: 4px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #222222;
      overflow: hidden;
      text-overflow: ellipsis;

      max-width: 300px;
      white-space: pre-wrap;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-wrap: nowrap;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 280px;

    & .img {
      border-radius: 50%;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #3137bd;
      font-weight: 500;
      font-size: 14px;
      margin-right: 16px;
      z-index: 1;
      position: relative;

      & .status {
        width: 12px;
        height: 12px;
        border: 2px solid $white;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2;
        &:global(.bg-LAUNCHED) {
          background: $green;
        }
        &:global(.bg-STOPPED) {
          background: $orange;
        }
        &:global(.bg-DELETED) {
          background: $red;
        }
        &:global(.bg-ARCHIVE) {
          background: $yellow;
        }
      }
    }

    & .text {
      width: calc(100% - 58px);

      & .tooltip {
        margin-left: 0;
        z-index: 10;
        width: 100%;
      }

      strong {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }

    & .hint {
      color: #828282;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > * {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }
      }

      & .type {
        display: block;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.dropdown {
  z-index: 2;
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &:global(._isOpen) {
    opacity: 1;
    visibility: visible;

    & .dropdown__show {
      opacity: 1;
      z-index: 0;
      background: #f4f5f6;
    }

    & .dropdown__menu {
      opacity: 1;
      visibility: visible;
    }
  }

  &__show {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #828282;
    cursor: pointer;
    transition: all 0.1s ease;

    &:disabled {
      cursor: default;
    }
  }

  &__menu {
    position: absolute;
    right: 64px;
    top: 0;
    bottom: 0;
    display: grid;
    height: 100%;
    grid-auto-flow: column;
    grid-gap: 0 20px;
    align-items: center;
    justify-content: end;
    opacity: 0;
    visibility: hidden;
    z-index: 2;

    &:before {
      position: absolute;
      content: '';
      top: -20px;
      bottom: -20px;
      left: 0;
      right: -64px;
      z-index: 0;
    }

    & > div {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      opacity: 0;

      & > span {
        display: block;
        padding: 4px 10px;
        background: #222222;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        opacity: 0;
        visibility: hidden;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 8px 0 8px;
          border-color: #222222 transparent transparent transparent;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &:hover {
        span {
          opacity: 1;
          visibility: visible;
        }
        svg {
          color: #025dff;
        }
      }
    }
  }
}
