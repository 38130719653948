@import 'src/styles/variables';

.filter {
  position: relative;
  display: flex;
  align-items: center;

  .found {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #025dff;
    margin-right: 11px;
  }

  .filterTooltip {
    .filterBtn {
      svg {
        transition: 0.3s;
      }
      &:hover {
        cursor: pointer;

        svg {
          color: #025dff;
        }
      }

      &:global(._isFound) {
        svg {
          color: #025dff;
        }
      }
    }
  }

  .panel {
    // Убрала позиционирование тут, потому что right 100% не работал. А надо чтобы иконку было видно
    // position: absolute;
    // top: -19px;
    // right: 100%;
    background: #ffffff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
    border-radius: 1px;
    height: auto;
    width: 500px;
    z-index: 20;
    padding: 16px;

    &:global(._isEmpty) {
      padding: 16px;

      .settingsBox {
        margin-bottom: 0;
        max-height: 0;
      }

      .actions {
        padding-right: 0;
      }
    }

    .settingsBox {
      max-height: 250px;
      margin-bottom: 16px;
      // padding-right: 8px;

      &:global(._isScroll) {
        overflow-x: scroll;
      }

      &:global(._isHistory) {
        max-height: fit-content;
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
    }

    .settings {
      display: flex;
      flex-direction: column;
      height: auto;
      max-height: 400px;
      gap: 20px;

      .settingsItem {
        display: flex;
        align-items: center;
        gap: 12px;

        :global(.horizontal) {
          flex: 1;
        }

        :global(.AgreementsMode__control) {
          // min-height: 32px;
          // height: 32px;
        }

        :global(.input-form-group) {
          width: 100%;
        }

        &.offers {
          :global(.selectWrapper) {
            width: 100%;
          }
        }
      }

      .select {
        flex: none !important;
       
        &.date {
          width: 200px !important;
        }
        &.params {
          width: 80px !important;
        }
      }

      .calendar {
        width: 132px;
        height: 40px;

        &:global(._isWrond) {
          input {
            border: 1px solid #ff3636;
          }
        }

        input {
          padding: 0 35px 0 10px;
          height: 100%;
          width: 100%;
          background: #ffffff;
          border: 1px solid #e2e2e2;
          border-radius: 1px;
          font-size: 14px;
          line-height: 22px;
          color: #222;

          &:checked {
            color: rgb(255, 0, 0);
          }

          &:focus {
            color: #222;
            border: 1px solid #025dff;
          }
        }

        .icon {
          color: #e2e2e2;
          position: relative;
          top: -65%;
          right: -78%;
          height: 18px;
          width: 18px;
          transform: translateY(-30%);
        }

        :global(.DayPickerInput) {
          height: 100%;
          width: 100%;
          background: #ffffff;
          font-size: 14px;
          line-height: 22px;
          color: #222;
        }

        :global(.DayPickerInput-OverlayWrapper) {
          position: absolute;
          width: 60%;
          z-index: 30;
        }
      }

      .close {
        cursor: pointer;
        &_wrap {
          margin-left: auto;

          svg {
            vertical-align: middle;
          }
        }
      }
    }

    .actions {
      display: flex;
      padding-right: 8px;

      .add {
        margin-right: 16px;
      }

      .apply {
        margin-left: auto;
      }
    }
  }

  .filterInput {
    height: 40px;
  }
}
