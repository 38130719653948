@import 'src/styles/variables';

.search {
  margin-right: 22px;
  height: 40px;
  padding: 0 10px;
  border: 1px solid $tr;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s;
  transition-delay: 0.1s;
  padding: 0;

  &:global(.isActive) {
    padding: 0 10px;
    border: 1px solid #025dff;
    flex: 1 0 auto;

    .close,
    .input,
    .match {
      display: block;
    }

    .match {
      text-transform: lowercase;
    }

    & .icon {
      display: none;
    }
  }

  &:global(.isDisabled) {
    pointer-events: none;
    cursor: not-allowed;
    svg {
      color: #828282;
    }
  }

  & .icon {
    cursor: pointer;
  }

  & .match {
    display: none;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
    padding-right: 12px;
    border-right: 1px solid #b9b9b9;
    margin-right: 12px;
  }

  & .close {
    display: none;
    cursor: pointer;

    &:hover {
      svg {
        color: #222;
      }
    }

    svg {
      color: #828282;
    }
  }

  & .input {
    flex: 1 0 auto;
    display: none;

    input {
      box-sizing: border-box;
      border-radius: 1px;
      padding: 0 12px;
      border: none;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      width: 100%;

      &:placeholder {
        color: #828282;
      }
    }
  }

  svg {
    vertical-align: middle;
  }
}
