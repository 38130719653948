@import 'src/styles/variables';

.card {
  padding: 0;

  .body {
    display: flex;
    flex-direction: column;
    gap: 0 20px;
    margin-top: 10px;

    & > * {
      display: flex;
      align-items: center;
      gap: 0 10px;
      max-width: 50%;
      :global(.PriceInput) {
        &:global(.horizontal) {
          flex: 1;
          display: grid;
          grid-template-areas:
            'PriceLabel PriceBody'
            'PriceError PriceError';
          grid-template-columns: 200px auto;
          :global(.label) {
            grid-area: PriceLabel;
          }
          :global(.PriceInput_body) {
            grid-area: PriceBody;
            input {
              width: 100%;
            }
          }
          :global(.ErrorText) {
            grid-area: PriceError;
          }
        }
      }
    }
  }
}
