@import 'src/styles/variables';

.selectItem {
  background: #e3ecff;
  border-radius: 1px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  gap: 12px;

  .close {
    cursor: pointer;
    color: #828282;

    &:hover {
        color: $blue;
    }
  }
}
