@import 'src/styles/variables';

$widthPopup: 300px;

@mixin showMorePopup_itemStyle {
  padding: 8px 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 157%;
  color: #222;
  overflow-wrap: anywhere;
  transition: 0.3s;

  &:hover {
    background: #f6f6f6;
  }

  strong {
    font-weight: 500;
  }
}

.showMorePopup {
  position: fixed;
  opacity: 1;
  z-index: 1000;
  left: 1px;
  top: 1px;
  text-transform: none;
  max-width: $widthPopup;
  border-radius: 1px;

  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 4px;
  padding: 10px 0;
  transform: translateX(-50%);

  &:after {
    content: '';
    width: 0;
    height: 0;

    border: 10px solid transparent;
    border-bottom: 10px solid rgb(255, 255, 255);

    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    filter: drop-shadow(0px 15px 10px rgba(255, 255, 255, 0.12));
    background: $tr;
    z-index: -1;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    width: 100%;
    height: 12px;
    left: 0;
    z-index: 0;
  }

  &:global(.fixTopPadding) {
    &:after {
      border: 10px solid transparent;
      border-top: 10px solid rgb(255, 255, 255);
      top: 100%;
      bottom: auto;
    }
    &:before {
      top: 100%;
      bottom: auto;
    }
  }

  &_list {
    max-height: 212px;
    overflow: auto;
    overflow-x: hidden;
    width: $widthPopup;
  }
}

:global(.showMorePopup_item) {
  @include showMorePopup_itemStyle;
}
