@import 'src/styles/variables';

.dropdown {
  position: relative;

  &:global(.isDisabled) {
    pointer-events: none;

    button {
      background: #e2e2e2;
      border: 1px solid #828282;
      color: #828282;
    }
  }

  & .header {
    position: relative;
    white-space: nowrap;

    &:global(._isOpened) {
      button {
        background: #002b97;
      }
    }
  }

  & .list {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 205px;
    padding-bottom: 2px;
    background: $white;
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    border-radius: 1px;
    transition: opacity 0.1s, transform 0.1s;
    z-index: 10;

    &:global(._isOpened) {
      opacity: 1;
      transform: translateY(0px);
      pointer-events: all;
    }

    & .item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      min-height: 40px;
      padding: 0 14px;

      &:hover {
        background: #f6f6f6;
      }

      & > * {
        display: grid;
        grid-template-columns: 40px auto;
        align-items: center;
        justify-items: flex-start;
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        color: #222222;
        height: 100%;
        min-height: 40px;

        &:hover {
          opacity: 1;
        }

        img {
          max-width: 24px;
          color: $black;
        }
      }
    }
  }
}
