@import 'src/styles/variables';

.statistics {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  & .chart {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    height: 16px;
    position: relative;
    z-index: 1;
    margin-right: 8px;

    @include tablet {
      display: none;
    }

    &:hover {
      .item {
        &:last-of-type {
          .bar {
            background: #a5ceff;
          }
        }
      }
    }

    & .item {
      margin-left: 1px;
      position: relative;

      &:first-child {
        margin-left: 0;
      }

      & .bar {
        background: #a5ceff;
        display: block;
        width: 4px;
        height: 1px;
        pointer-events: all;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 16px;
        }

        &:hover {
          background: $blue !important;
        }

        &:global(._isInactive) {
          background: #dddddd;

          &:hover {
            background: #828282;
          }
        }
      }
    }
  }

  & .count {
  }
}
