@import 'src/styles/variables';

.revertBlock {
  text-align: right;

  &_body {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    color: $blue;
  }
}
