@import 'src/styles/variables';

.icons {
  position: fixed;
  opacity: 1;
  z-index: 1000;

  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background: #ffffff;
  text-transform: none;
  width: 320px;
  border: 1px solid var(--neutral-grey-30, #e2e2e2);
  transform: translateY(-50%);

  &_header {
    padding: 10px 16px;
    border-bottom: 1px solid var(--neutral-grey-30, #e2e2e2);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_title {
    font-weight: 500;
    line-height: 22px;
  }

  &_btn {
    color: var(--primary-blue-20, #025dff);
    cursor: pointer;
  }

  &_body {
    padding: 16px;
    max-height: 188px;
    overflow-y: auto;
  }

  button {
    width: 30px;
    height: 30px;
    transition: 0.3s;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #E3ECFF;
    }

    &:active {
      background-color:
        #C2D6FF;
    }

    img {
      flex: none;
      width: 20px;
      height: 20px;
      cursor: pointer;
      vertical-align: middle;
    }
  }

  &_last {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }

  &_label {
    color: var(--neutral-grey-50, #828282);
    margin-bottom: 10px;
  }

  &_icons {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
  }
}