@import 'src/styles/variables';

.card {
  margin: 0 auto;
  max-width: 774px;
  width: 100%;
  background: $white;
  display: block;
  pointer-events: all;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 28px;

  & .title {
    flex: 1 0 auto;
    max-width: calc(100% - 80px);
    padding-right: 20px;

    h1 {
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  & .close {
    margin-left: 20px;
    flex: none;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      background: #f4f5f6;
    }

    svg {
      color: $black;
    }
  }

  & .more {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    position: relative;
    border-radius: 50%;

    svg {
      color: #828282;
    }

    &:hover {
      cursor: pointer;
      background: #f4f5f6;

      svg {
        color: $black;
      }
    }

    &:global(.isOpened) {
      .list {
        opacity: 1;
        transform: translateY(0px);
        pointer-events: all;
      }
    }

    & .list {
      position: absolute;
      top: calc(100% - 4px);
      right: -8px;
      padding: 1px 0px;
      background: $white;
      box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
      border-radius: 1px;
      min-width: 160px;
      opacity: 0;
      transform: translateY(-20px);
      pointer-events: none;
      transition: opacity 0.1s, transform 0.1s;

      & .item {
        display: flex;
        min-height: 32px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        transition: background 0.1s;
        cursor: pointer;
        padding: 0 14px;
        white-space: nowrap;

        &:hover {
          background: #f6f6f6;
        }
      }
    }
  }
}

.content {
  padding: 0 28px 28px;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  overflow-wrap: anywhere;
}
