.partner_option {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  min-height: 46px !important;
  flex: 1;

  &:global(._isNotColor) {
    .icon {
      color: #ffffff00;
    }
  }

  &.selected {
    max-width: calc(100% - 8px);
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 8px;
    padding-right: 12px;
  }

  &_left {
    display: flex;
    align-items: center;
    .icon {
      background: #3137bd;
      flex: none;
      width: 24px;
      height: 24px;
      margin-right: 18px;
      border-radius: 100%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 10px;
      margin-right: 12px;
    }

    .title {
      max-width: 245px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &_right {
    display: flex;
    align-items: center;
    color: #828282;
    .internal_id {
      margin-right: 4px;
    }
  }
}
