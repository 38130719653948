@import 'src/styles/variables';

.container {
  @include campaignGrid;
}

.card {
  padding: 28px 24px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.form {
  &:global(.single) {
    & > * {
      margin-bottom: 0;
    }
  }
  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  :global(.react-tabs__tab-list) {
    margin: 0;
    padding: 0;
  }

  .warning {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: -20px;
    svg {
      vertical-align: middle;
      margin-right: 0;
    }
    span {
      color: #ff9c08;
    }
  }
}

.hint {
  margin-top: -10px;
}

.flex_row {
  display: flex;
  justify-content: space-between;
  &_col {
    flex: 1;
  }
}

.rough {
  @extend .flex_row;
  gap: 0 24px;
  &_col {
    flex: 1;
  }
}

.sizes {
  margin-bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  &_col {
    flex: 1;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 20px;
}

.widget {
  padding: 0;
  .actions {
    button {
      width: 100%;
    }
  }
  .hint {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;

    svg {
      flex: none;
      margin-right: 8px;
    }
    p {
      font-size: 13px;
      line-height: 20px;
    }
  }
}

.customOptions {
  height: 40px;
  padding: 5px 11px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;

  &:hover {
    background-color: #f6f6f6;
  }

  label {
    text-transform: capitalize;
    margin-bottom: 0;
  }

  &__show {
    background: #f6f6f6;
    border-radius: 12px;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 18px;
    margin-right: 8px;
  }

  &:global(._isSub) {
    padding-left: 20px;
    font-weight: 400;
  }

  &:global(.isShow) {
    padding-left: 15px;
    height: 49px;
    cursor: auto;
  }

  &:global(.isEmpty) {
    padding: 0 0;
  }
}
