@import 'src/styles/variables';

.listings {
  position: relative;
  background: #f5f5f5;
  min-height: calc(100vh - 106px);
  padding-bottom: 40px;

  &__loader {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }

  &__header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    &.isToggleSearch {
      .title {
        display: none;
      }
      .actions {
        flex: 1 0 auto;
      }
    }

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .search {
      margin-right: 20px;
      height: 40px;
      padding: 0 10px;
      border: 1px solid $tr;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      transition: all 0.1s;

      &.isActive {
        border: 1px solid #025dff;
        flex: 1 0 auto;

        .search {
          &__close,
          &__input {
            pointer-events: all;
            position: relative;
            opacity: 1;
          }
        }
      }

      &__icon {
        cursor: pointer;
      }

      &__close {
        pointer-events: none;
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:hover {
          svg {
            color: #222;
          }
        }

        svg {
          color: #828282;
        }
      }

      &__input {
        flex: 1 0 auto;
        pointer-events: none;
        position: absolute;
        opacity: 0;

        input {
          box-sizing: border-box;
          border-radius: 1px;
          padding: 0 12px;
          border: none;
          font-size: 14px;
          line-height: 22px;
          color: $black;
          width: 100%;

          &:placeholder {
            color: #828282;
          }
        }
      }
    }
  }

  &__table {
    padding-top: 28px;

    .table {
      display: block;

      &__header {
        display: grid;
        padding: 0 20px;
        padding-left: 60px;
        grid-template-columns: 280px 300px 300px auto;
        grid-gap: 0 30px;
        align-items: center;

        @include laptop {
          grid-template-columns: 280px auto auto auto;
        }

        div {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          letter-spacing: 0.03em;
          padding-bottom: 16px;
        }
      }

      &__body {
        & > div {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
