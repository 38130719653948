@import 'src/styles/variables';

.filter {
  display: inline-block;
  position: relative;
  white-space: nowrap;

  &:global(.isOpened) {
    .active {
      background: #e3ecff;
      border-color: $blue;
      color: $blue;

      &:hover {
        background: #e3ecff;
      }

      svg {
        transform: rotate(-180deg);
        color: $blue;
      }
    }
  }

  &:global(.isDisabled) {
    .active {
      pointer-events: none;
      background: #e2e2e2;
      border: 1px solid #828282;
      color: #828282;

      svg {
        color: #828282;
      }
    }
  }

  & .active {
    display: flex;
    align-items: center;
    height: 32px;
    border: 1px solid $grey;
    padding: 0 11px 0 16px;
    font-size: 14px;
    line-height: 22px;
    color: #0a0a0a;
    background: $tr;
    z-index: 1;
    cursor: pointer;
    transition: background 0.2s ease;

    &:hover {
      background: #f6f6f6;
    }

    svg {
      margin-left: 4px;
      transition: transform 0.2s ease;
      transform: rotate(0);
      color: #0a0a0a;
    }
  }

  & .list {
    position: absolute;
    opacity: 0;
    transform: translateY(-40px);
    pointer-events: none;
    top: calc(100% + 4px);
    left: 0;
    min-width: 100%;
    z-index: 14;
    background: $white;
    padding-top: 2px;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    transition: opacity 0.1s, transform 0.1s;

    &:global(.isOpened) {
      opacity: 1;
      transform: translateY(0px);
      pointer-events: all;
    }

    & .item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      font-size: 14px;
      line-height: 22px;
      color: #0a0a0a;
      padding: 0 16px;
      background: $tr;
      transition: background 0.1s;
      cursor: pointer;

      &:hover {
        background: #f6f6f6;
      }

      &:global(.isActive) {
        color: #828282;
        pointer-events: none;

        &:hover {
          background: transparent;
          cursor: default;
        }
      }
    }
  }
}
