@import 'src/styles/variables';

.widget {
    p {
      font-size: 14px;
      line-height: 22px;
      color: $black;
    }
  
    a {
      color: $blue;
      transition: color 0.1s;
  
      &:hover {
        opacity: 1;
        color: $dark-blue;
      }
    }
  }