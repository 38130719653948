@import 'src/styles/variables';

.settings {
    & .header {
      background: #fff;
      height: 102px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  
    & .body {
      .container {
        @include campaignGrid;
  
        @include tablet {
          grid-template-columns: 1fr;
        }
      }
  
      & .rows {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
      }
  
      & .actions {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
      }
  
      & .info {
        display: flex;
        gap: 10px;
        svg {
          flex: none;
        }
        &_text {
          p {
            word-break: break-all;
          }
          strong {
            word-break: break-all;
            font-weight: 700;
          }
        }
      }
    }
  }