@import 'src/styles/variables';

.dropdown {
  & .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    & .spacer {
      flex: 1 0 auto;
    }

    & .toggle {
      font-size: 14px;
      line-height: 22px;
      color: $blue;
      transition: color 0.1s;

      &:hover {
        color: $dark-blue;
      }
    }
  }
  & .body {
    display: none;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    padding-left: 34px;
    padding-top: 7px;
    padding-bottom: 7px;

    &:global(.isToggle) {
      display: block;
    }
  }
}
