@import 'src/styles/variables';

.wrapper {
  background: $white;

  @keyframes last-edited-row {
    0% {
      background: #f4f5f6;
    }
    50% {
      background: $white;
    }
    100% {
      background: $white;
    }
  }

  @keyframes last-edited-indicator {
    0% {
      opacity: 1;
      top: 3px;
      bottom: 3px;
    }
    10% {
      opacity: 1;
      top: 2px;
      bottom: 2px;
    }
    100% {
      opacity: 0;
      top: 50%;
      bottom: 50%;
    }
  }

  &:global(.isLastEdited) {
    animation: last-edited-row 3s ease-out forwards;

    & .indicator {
      animation: last-edited-indicator 3s ease-out forwards;
    }
  }

  & .indicator {
    position: absolute;
    top: 50%;
    left: 2px;
    width: 4px;
    bottom: 50%;
    border-radius: 2px;
    background: $green;
    display: block;
    opacity: 0;
  }
}

.row {
  display: grid;
  grid-template-columns: 25px 240px 1fr 1fr 1fr 1fr 1fr 1fr 36px;
  grid-gap: 20px;

  &:hover {
    .moderation {
      z-index: 20;
    }
  }

  &:global(.isActive) {
    grid-template-columns: 25px auto 1fr;

    & > div {
      display: none;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-last-child(1),
      &:nth-last-child(2) {
        display: flex;
      }
    }

    & > a {
      display: none;
    }
  }

  & > a {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  & > div {
    font-size: 14px;
    line-height: 22px;
    color: #222222;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &:last-of-type {
      z-index: 2;
      justify-content: flex-end;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 1;

  & .img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #e3ecff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    flex-shrink: 0;
    position: relative;

    & > div {
      &:first-child {
        height: 20px;
        width: 20px;
        z-index: 1;
        position: relative;
      }
    }

    & > svg {
      color: #c6c6c6;
    }

    & .status {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid #fff;
      position: absolute;
      bottom: 0px;
      right: 0px;
      z-index: 2;
    }
  }

  & .text {
    max-width: 100%;
    font-weight: 500;

    & .tooltip {
      z-index: 10;
      margin: 0;
    }

    & .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #222222;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      text-overflow: ellipsis;

      &:global(.fullTitle) {
        display: block;
        overflow: initial;
      }
    }

    & .type {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #828282;
    }
  }
}

.icon {
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 16px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  & + .text {
    max-width: calc(100% - 58px);
  }
}

.status {
  z-index: 2;

  & > * {
    position: relative;
    left: 0;

    &:nth-child(2) {
      left: -6px;
    }
    &:nth-child(3) {
      left: -12px;
    }
  }
}

.network {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid $white;
  background: #e2e2e2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #828282;
  }
}

.condition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:global(.isStopped) {
    & > div {
      border-color: #ff9c08;
      background: #fff2c5;
    }

    & > span {
      color: #ff9c08;
    }
  }

  & > div {
    display: block;
    margin-right: 14px;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    border: 2px solid $green;
    background: #c8fcc3;
  }

  & > span {
    font-size: 14px;
    line-height: 22px;
    color: $green;
  }
}

.moderation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > div {
    margin-left: -4px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.dropdown {
  position: relative;
  z-index: 2;

  &:global(.isActive) {
    align-items: center;

    & .dropdown__show {
      border-radius: 50%;
      background: #f4f5f6;
      opacity: 1;
    }

    & .dropdown__menu {
      pointer-events: all;
      opacity: 1;

      & > div {
        @keyframes show-item {
          0% {
            transform: translateY(40px);
            opacity: 0;
          }
          100% {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        animation: show-item 0.2s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;

        &:nth-last-child(2) {
          animation-delay: 0.1s;
        }
        &:nth-last-child(3) {
          animation-delay: 0.2s;
        }
        &:nth-last-child(4) {
          animation-delay: 0.3s;
        }
        &:nth-last-child(5) {
          animation-delay: 0.4s;
        }
        &:nth-last-child(6) {
          animation-delay: 0.5s;
        }
      }
    }
  }

  & .dropdown__show {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    cursor: pointer;

    svg {
      color: #828282;
      transition: color 0.1s;

      &:nth-child(1) {
        display: block;
      }
    }
  }

  & .dropdown__menu {
    position: absolute;
    top: 5px;
    right: 64px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 32px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.1s, transform 0.1s;

    &:before {
      position: absolute;
      content: '';
      top: -20px;
      bottom: -20px;
      left: 0;
      right: -64px;
      z-index: 0;
    }

    & > div {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
    }
  }
}

.toast {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: $black;
  width: 100%;
  word-break: break-word;
  display: flex;

  b,
  strong {
    font-weight: 700;
  }

  a {
    color: $blue;
  }

  & .icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:global(._isSuccess) {
      background: $green;
    }

    &:global(._isError) {
      background: $red;
    }
  }

  & .text {
    padding-left: 16px;
    width: calc(100% - 32px);

    span {
      color: $white;
    }

    &:global(._isError) {
      padding-top: 8px;
    }
  }

  & .links {
    margin-top: 10px;

    a {
      color: $black;
      line-height: 1;

      strong {
        text-decoration: underline;
        color: $blue;
        font-weight: 400;
        line-height: 1;
        padding-bottom: 0;
      }

      span {
        margin-right: 4px;
        color: $black !important;
      }
    }
  }
}
