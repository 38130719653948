@import 'src/styles/variables';

.itemWrap {
  &:global(.isHidden) {
    display: none;
  }
}

.item {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  &:hover {
    .actions {
      opacity: 1;
      :global(.options_active) {
        svg {
          opacity: 1;
        }
      }
      &:global(.isDisabled) {
        opacity: 0;
      }
    }
    .circle {
      opacity: 0;
      z-index: -10;
      visibility: hidden;
      &:global(.isDisabled) {
        visibility: visible;
        z-index: 50;
        opacity: 1;
      }
    }
  }

  .arrow {
    display: flex;
    align-items: center;

    .img {
      margin-right: 15px;
      cursor: pointer;
      transition: 0.2s;
      &:global(.active) {
        transform: rotate(90deg);
      }
    }
  }
  .checkboxWrap {
    width: 100%;

    &:global(.end) {
      padding-left: 20px;
    }
    &:global(.toggleNone) {
      margin-left: 20px;
    }
  }

  & .actions {
    flex: 1 1;
    margin-left: 8px;

    :global(.options_active) {
      svg {
        opacity: 0;
      }
    }

    .circle {
      opacity: 1;
      transition: opacity 0.3s;
      z-index: 2;
      visibility: visible;
    }

    &:global(.isOpened) {
      opacity: 1 !important;
    }
  }

  .circle {
    width: 6px;
    height: 6px;
    background: #04b900;
    border-radius: 100%;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    visibility: visible;
    z-index: 50;
    opacity: 1;
  }
}
