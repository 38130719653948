@import 'src/styles/variables';

.filter {
  cursor: pointer;

  &:global(._isShowPanel) {
    & ~ .filter_panel {
      opacity: 1;
      transform: translateX(0%);
      visibility: visible;
      z-index: 20;
      pointer-events: all;
    }
  }

  &:global(._isHighlightIcon) {
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      color: $blue;
    }
    span {
      font-weight: 500;
      color: $blue;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.filter_panel {
  position: fixed;
  top: calc(58px + 102px + 48px);
  right: 10px;
  background: #ffffff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
  height: auto;
  width: 330px;
  z-index: -1;
  padding: 16px;
  transition: 0.3s;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;

  .parametrs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 20px;

    .item {
      :global(.inputField) {
        display: block;
      }
      :global(.labelWrapper) {
        margin-bottom: 8px;
        width: auto;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
