.filterResults {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .item__wrap {
    display: flex;
    align-items: center;

    &:first-of-type {
      .item {
        margin-left: 0;
      }
    }

    &:nth-of-type(5n) {
      .item {
        margin-left: 0;
      }
    }
  }

  .item {
    background: #ffffff;
    height: 40px;
    width: auto;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
    margin-left: 22px;
    margin-right: 22px;

    &__wrap {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 14px;
      line-height: 20px;
      margin-right: 6px;
    }

    .icon {
      cursor: pointer;
      color: #828282;
      transition: 0.2s;
      &:hover {
        color: #025dff;
      }
    }
  }

  .filterMerger {
    font-size: 14px;
    line-height: 16px;
    color: #828282;
    text-transform: uppercase;
    margin-bottom: 23px;
  }

  .collapse {
    margin-bottom: 23px;
  }

  .clear {
    margin-bottom: 23px;
    margin-left: 12px;
  }
}
