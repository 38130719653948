.balance_option {
  min-height: 0;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  height: fit-content !important;

  &_label {
    font-size: 14px;
    line-height: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;

    strong {
      font-weight: 500;
    }
  }
  &_price {
    font-size: 12px;
    line-height: 18px;
  }
}
