@import 'src/styles/variables';

.card {
  max-width: 774px;
  width: 100%;

  &_header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 41px;
  }

  &_title {
    flex: 1 0 auto;
    max-width: calc(100% - 60px);
    color: var(--text-main, #222);
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
  }

  &_body {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 30px;
    strong {
      font-weight: 500;
    }
  }

  &_close {
    margin-left: 24px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    &:hover {
      background: darken(#f4f5f6, 5%);
    }

    svg {
      cursor: pointer;
      color: black;
    }
  }

  &_actions {
    display: flex;
    justify-content: flex-end;

    button {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
