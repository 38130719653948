@import 'src/styles/variables';

.users {
  position: relative;
  background: #f5f5f5;
  min-height: calc(100vh - 58px);

  & > .header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    top: 48px;
    z-index: 5;
    transition: 0.5s;

    &:global(.isToggleSearch) {
      .title {
        display: none;
      }
      .actions {
        flex: 1 0 auto;
      }
    }

    &:global(.isFixed) {
      box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.15);
    }

    & .status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @include tablet {
        width: 100%;
      }

      & .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        @include tablet {
          width: 100%;
        }

        & .toggle-title {
          margin-right: 32px;

          &:global(.isDisabled) {
            pointer-events: none;

            svg {
              color: #828282;
            }
          }

          & .tooltip {
            margin-left: 0;

            & > div {
              &:last-child {
                top: calc(50% - 19px);
              }
            }
          }
        }

        & .range {
          margin-right: 16px;
        }
      }
    }
  }

  & .content {
    padding-top: 28px;
    padding-bottom: 40px;

    & .table {
      display: block;

      & .header {
        display: grid;
        padding: 0 20px;
        padding-left: calc(20px + 55px);
        grid-template-columns: 600px auto;
        grid-gap: 0 15px;
        align-items: center;

        @include tablet {
          display: none;
          grid-template-columns: 500px auto;
        }

        div {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          letter-spacing: 0.03em;
          padding-bottom: 16px;
        }
      }

      & .body {
        & > div {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
