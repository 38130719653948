@import 'src/styles/variables';

.breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  color: #828282;

  @include phone {
    flex-wrap: wrap;
  }

  & .item {
    transition: color 0.1s, text-decoration 0.1s;
    color: #828282;
    display: flex;
    cursor: default;
    align-items: center;
    position: relative;
    padding-right: 16px;
    padding-left: 8px;
    line-height: 12px;

    .link {
      cursor: pointer;
      max-width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      height: 14px;

      a {
        color: #828282;
        height: 14px;
      }

      &:global(._noLink) {
        cursor: default;
      }
    }

    &:first-child {
      padding-left: 0;
    }

    &:after {
      content: '/';
      display: block;
      position: absolute;
      pointer-events: none;
      right: 0;
      top: 0;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:global(.isNotLink) {
      cursor: default;

      &:hover {
        color: #828282;
        .link {
          text-decoration: none;
        }
        .hint {
          display: block;
        }
      }
    }

    &:hover {
      color: $black;
      opacity: 1;

      .link {
        text-decoration: underline;
      }

      .hint {
        display: block;
      }
    }

    & .hint {
      position: absolute;
      display: none;
      left: 0;
      top: calc(100% + 4px);
      z-index: 10;
      background: $white;
      padding: 7px 14px;
      white-space: nowrap;
      box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
      border-radius: 1px;
    }
  }

  & .loading {
    height: 14px;
    width: 600px;
  }
}
