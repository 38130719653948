.bid {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 14px;
  line-height: 22px;
  color: #222;
  text-align: left;
  background: transparent;
  white-space: nowrap;
  position: relative;

  input {
    width: 63px;
    padding: 0 8px;
    background: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 1px;
    height: 28px;
    font-size: 14px;
    color: #0a0a0a;
    transition: border 0.1s;

    &:global(.isEdit) {
      &:disabled {
        background: #f4f5f6;
      }
    }
  }
  &:global(._isDisable) {
    input {
      pointer-events: none;
      background: #e2e2e2;
      border: 1px solid #828282;
      color: #828282;
    }
  }

  &:global(.isGroup) {
    background-color: #f4f5f6;
    input {
      background-color: #f4f5f6;
    }
  }
}
