@import 'src/styles/variables';

.uc {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  background: $white;

  &__form {
    text-align: center;
    padding: 160px 0 208px;
  }

  &__code {
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      height: calc(100vh - 482px);
      min-height: 270px;
      max-width: min-content;
    }
  }

  &__title {
    margin-top: 48px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
  }

  &__text {
    margin-top: 16px;
    font-size: 14px;
    line-height: 22px;
    color: #222222;
  }
}
