@import 'src/styles/variables';

.users {
  position: relative;
  background: #f5f5f5;
  min-height: calc(100vh - 58px);

  :global(.full),
  :global(.extend) {
    & .table {
      & .header {
        &:global(.api) {
          grid-template-columns:
            minmax(100px, 0.5fr) minmax(150px, 0.5fr) minmax(100px, 0.6fr)
            minmax(100px, 0.8fr) 65px 40px !important;
        }
      }
    }
  }

  & > .header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:global(.isToggleSearch) {
      .title {
        display: none;
      }
      .actions {
        flex: 1 0 auto;
      }
    }

    & .status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        & .search {
          margin-right: 22px;
          height: 40px;
          padding: 0 10px;
          border: 1px solid $tr;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          transition: all 0.1s;

          &:global(.isActive) {
            border: 1px solid #025dff;
            flex: 1 0 auto;

            .close,
            .input {
              display: block;
            }
          }

          & .icon {
            cursor: pointer;
          }

          & .close {
            display: none;
            cursor: pointer;

            &:hover {
              svg {
                color: #222;
              }
            }

            svg {
              color: #828282;
            }
          }

          & .input {
            flex: 1 0 auto;
            display: none;

            input {
              box-sizing: border-box;
              border-radius: 1px;
              padding: 0 12px;
              border: none;
              font-size: 14px;
              line-height: 22px;
              color: $black;
              width: 100%;

              &:placeholder {
                color: #828282;
              }
            }
          }
        }

        & .period {
          margin-right: 20px;
        }

        & .toggle-title {
          margin-right: 32px;

          &:global(.isDisabled) {
            pointer-events: none;

            svg {
              color: #828282;
            }
          }

          & .tooltip {
            margin-left: 0;

            & > div {
              &:last-child {
                top: calc(50% - 19px);
              }
            }
          }
        }

        & .range {
          margin-right: 16px;
        }
      }
    }
  }

  & .content {
    padding-top: 28px;
    padding-bottom: 40px;

    & .table {
      display: block;

      & .header {
        display: grid;
        padding: 0 20px;
        grid-template-columns:
          minmax(230px, 1fr) minmax(350px, 1.2fr) minmax(120px, 1fr)
          minmax(80px, 0.5fr) 65px 40px;
        grid-gap: 0 10px;
        align-items: center;

        &:global(.api) {
          grid-template-columns:
            minmax(200px, 0.9fr) minmax(250px, 0.8fr) minmax(100px, 0.8fr)
            minmax(100px, 0.8fr) 65px 40px;
        }

        &:global(.isOnline) {
          grid-template-columns: 1fr 1fr 0.5fr 65px 40px;
        }

        &:global(.isStatistics) {
          grid-template-columns: 2.5fr 1.5fr 1fr 1fr 1fr 40px;
        }

        & .header__item {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          letter-spacing: 0.03em;
          padding-bottom: 16px;
          margin-left: 0;

          & .header__item_hint {
            font-weight: normal;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      & .body {
        & > div {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
