@import 'src/styles/variables';

.blackLists {
  &:global(.isFixedHeader) {
    .header {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 500;
      box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.1490196078);
    }
  }
  & .header {
    background: #fff;
    height: 102px;
    display: flex;
    align-items: center;

    .headeRow {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:global(._isActiveSearch) {
        justify-content: flex-start;
        .title {
          display: none;
        }
        .search {
          border: 1px solid #025dff;
          flex: 1 0 auto;
          padding: 0 10px;

          .input {
            display: block;
          }

          .close {
            display: flex;
            align-items: center;
          }
        }
        .actions {
          flex: 1 0 auto;

          & > div {
            display: none;

            &:first-child {
              display: flex;
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .search {
      height: 40px;
      border: 1px solid #fff0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      transition: all 0.1s;

      .icon {
        height: 24px;
        cursor: pointer;

        &:hover {
          svg {
            stroke: #025dff;
          }
        }
      }

      .input {
        flex: 1 0 auto;
        display: none;

        input {
          border-radius: 1px;
          padding: 0 12px;
          border: none;
          font-size: 14px;
          line-height: 22px;
          color: #222;
          width: 100%;
        }
      }

      .close {
        display: none;
        cursor: pointer;

        svg {
          color: #828282;
          transition: 0.3s;
        }

        &:hover {
          svg {
            color: #222;
          }
        }
      }
    }

    .update {
      cursor: pointer;
    }
  }
  & .content {
    padding-top: 28px;
    padding-bottom: 40px;
  }

  & .list {
    &_item {
      padding: 6px 22px;
      background: rgba(255, 255, 255, 1);
      display: grid;
      grid-template-columns: 20px auto 150px;
      align-items: center;
      gap: 20px;
      border-bottom: 1px solid #e2e2e2;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background: $grey-bg;
      }

      &:last-child {
        border: none;
      }

      & .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;

        & .date {
          span {
            margin-left: 4px;
            color: #828282;
          }
        }
      }
    }
    &_itemName {
      font-weight: 500;
      font-size: 14px;
      line-height: 157%;
      color: #222;
    }
  }
}
