@import 'src/styles/variables';

$structureColumns: 50px 1.2fr 0.4fr minmax(140px, 1.1fr) 1fr
  minmax(100px, 0.4fr) 0.5fr 60px;

.contractors {
  & > * {
    line-height: 18px; /* 150% */
  }
  & .header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:global(.isToggleSearch) {
      .title {
        display: none;
      }
      .actions {
        flex: 1 0 auto;
      }
    }

    & .status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 22px;

        & .search {
          height: 40px;
          border: 1px solid $tr;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          transition: all 0.1s;

          svg {
            vertical-align: middle;
          }

          &:global(.isActive) {
            border: 1px solid #025dff;
            flex: 1 0 auto;
            padding: 0 10px;

            .close,
            .input {
              display: block;
            }
          }

          & .icon {
            cursor: pointer;
          }

          & .close {
            display: none;
            cursor: pointer;

            &:hover {
              svg {
                color: #222;
              }
            }

            svg {
              color: #828282;
            }
          }

          & .input {
            flex: 1 0 auto;
            display: none;

            input {
              box-sizing: border-box;
              border-radius: 1px;
              padding: 0 12px;
              border: none;
              font-size: 14px;
              line-height: 22px;
              color: $black;
              width: 100%;

              &:placeholder {
                color: #828282;
              }
            }
          }
        }

        & .period {
          margin-right: 20px;
        }

        & .toggle-title {
          margin-right: 32px;

          &:global(.isDisabled) {
            pointer-events: none;

            svg {
              color: #828282;
            }
          }

          & .tooltip {
            margin-left: 0;

            & > div {
              &:last-child {
                top: calc(50% - 19px);
              }
            }
          }
        }

        & .range {
          margin-right: 16px;
        }
      }
    }
  }

  & .content {
    padding-top: 28px;
    padding-bottom: 40px;
  }

  & .table {
    &_header {
      display: grid;
      padding: 0 20px;
      grid-template-columns: $structureColumns;
      grid-gap: 0 10px;
      align-items: center;
      margin-bottom: 16px;

      & > div {
        &:first-child {
          justify-self: center;
        }
        &:nth-child(7) {
          justify-self: center;
        }
      }
    }
    &_body {
      & > div {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.item_wrapper {
  position: relative;
  background: $white;

  &:global(._isLastEdited) {
    @include last-edited-row-animation;

    & .indicator {
      @include last-edited-indicator-animation;
    }
  }

  & .indicator {
    @include indicator;
    left: 0;
  }

  .item {
    background: $tr;
    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
    border-radius: 1px;
    padding: 17px 22px;
    grid-template-columns: $structureColumns;
    display: grid;
    align-items: center;
    grid-gap: 0 10px;
    min-height: 66px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;

    & > div {
      &:first-child {
        justify-self: center;
      }
    }

    & > a {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    @keyframes new-item {
      0% {
        box-shadow: 0 0 0 rgba(2, 85, 255, 0);
      }
      30% {
        box-shadow: 0 6px 60px rgba(2, 85, 255, 0.22);
      }
      100% {
        box-shadow: 0 0 0 rgba(2, 85, 255, 0);
      }
    }

    &:global(._isNew) {
      animation: new-item 1s ease-out forwards;
    }

    &:global(._isOpen) {
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;

      & > div {
        display: none;

        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          display: block;
          padding-left: 60px;
        }

        &:last-child {
          display: flex;
          justify-self: stretch;
        }
      }

      & > a {
        display: none;
      }

      & .item__header {
        max-width: 800px;

        & > div {
          &:last-child {
            overflow: inherit;
          }
        }
      }

      & .dropdown {
        &__menu {
          @keyframes show-item {
            0% {
              transform: translateY(40px);
              opacity: 0;
            }
            100% {
              transform: translateY(0px);
              opacity: 1;
            }
          }

          & > div {
            animation: show-item 0.2s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;

            @for $i from 2 to 10 {
              &:nth-last-child(#{$i}) {
                animation-delay: #{($i * 0.1) - 0.1}s;
              }
            }
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
      z-index: 10;
      background: $backlightItems;
    }

    & > div {
      transition: all 0.2s ease;

      &:last-child {
        justify-self: end;
      }
    }

    & .status {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: rgba(226, 226, 226, 1);
      &:global(._isNotSend) {
        background: rgba(226, 226, 226, 1);
      }
      &:global(._isWaiting) {
        background: rgba(255, 242, 197, 1);
      }
      &:global(._isWaitUnload) {
        background: rgba(255, 156, 8, 1);
      }
      &:global(._isSuccess) {
        background: rgba(20, 156, 8, 1);
      }
      &:global(._isError) {
        background: rgba(255, 54, 54, 1);
      }
    }

    & .value {
      &_top {
      }
      &_fat {
        font-weight: 500;
      }
      &_bottom {
        color: var(--neutral-grey-50, #828282);
        font-size: 12px;
      }
    }
  }

  .dropdown {
    z-index: 2;
    position: relative;
    cursor: default;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &:global(._isOpen) {
      opacity: 1;
      visibility: visible;

      & .dropdown__show {
        opacity: 1;
        z-index: 0;
        background: #f4f5f6;
      }

      & .dropdown__menu {
        opacity: 1;
        visibility: visible;
      }
    }

    &__show {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #828282;
      cursor: pointer;
      transition: all 0.1s ease;

      &:disabled {
        cursor: default;
      }
    }

    &__menu {
      position: absolute;
      right: 64px;
      top: 0;
      bottom: 0;
      display: grid;
      height: 100%;
      grid-auto-flow: column;
      grid-gap: 0 20px;
      align-items: center;
      justify-content: end;
      opacity: 0;
      visibility: hidden;
      z-index: 2;

      &:before {
        position: absolute;
        content: '';
        top: -20px;
        bottom: -20px;
        left: 0;
        right: -64px;
        z-index: 0;
      }

      & > div {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        opacity: 0;

        & > span {
          display: block;
          padding: 4px 10px;
          background: #222222;
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          color: #fff;
          border-radius: 2px;
          font-size: 14px;
          line-height: 22px;
          opacity: 0;
          visibility: hidden;

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 0 8px;
            border-color: #222222 transparent transparent transparent;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        &:hover {
          span {
            opacity: 1;
            visibility: visible;
          }
          svg {
            color: #025dff;
          }
        }
      }
    }
  }
}
