@import 'src/styles/variables';

.type {
  position: relative;
  flex: 1;
  text-align: end;
  
  &_btn {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;

    &:hover {
      cursor: pointer;

      span,
      svg {
        color: #5b5a5a;
      }
    }
    span,
    svg {
      color: #828282;
      vertical-align: middle;
      transition: all 0.3s;
    }
    span {
      font-size: 10px;
      line-height: 18px;
      margin-right: 4px;
    }
  }
}

.list {
  position: fixed;
  transition: opacity 0.1s, transform 0.1s;
  background: $white;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);
  z-index: 999;

  & .item {
    min-height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 14px;
    font-size: 12px;
    line-height: 20px;
    color: $black;
    transition: background 0.1s;

    &:hover {
      background: #f6f6f6;
      cursor: pointer;
    }
  }
}
