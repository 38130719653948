@import 'src/styles/variables';

.card {
  padding: 24px 22px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  & .title {
    font-size: 16px;
    font-weight: 700;
  }
}

.info {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  &_block {
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 24px;

    &:last-of-type {
      border: none;
      padding-bottom: 0;
    }
  }
  &_title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  &_subtitle {
    color: var(--neutral-grey-50, #828282);
    font-size: 12px;
    line-height: 18px; /* 150% */
    margin-bottom: 24px;
  }
  &_target {
  }
  &_income {
  }
  &_parent {
    margin-bottom: 24px;
  }
  &_check {
    .checkbox {
      font-size: 14px;
      line-height: 22px;
      padding-left: 34px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        background-color: #e3ecff;
        border-radius: 4px;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='%23025DFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-size: 60%;
        background-repeat: no-repeat;
      }

      &::after {
      }
    }
  }

  &_erir {
  }

  .string {
    display: flex;
    align-items: center;
    gap: 10px;
    &_wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      svg {
      }

      strong {
        font-size: 12px;
        font-weight: 500;
      }
      span {
        font-size: 12px;
        word-break: break-word;
      }
    }
  }
}

.data {
  & .nds_wrapper {
    &:global(._isError) {
      align-items: start;

      :global(.labelWrapper) {
        margin-top: 5px;
      }

      .nds {
        align-items: flex-start;
      }
    }
  }
  & .nds {
    display: flex;
    align-items: center;
    gap: 26px;

    & > div {
      &:first-child {
        flex: 1;
      }
    }
    :global(.PriceInput_body) {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }

  & .calendar {
    :global(.DateInput) {
      width: 100%;

      input {
        width: 100%;
      }
    }

    :global(.DateInput_body) {
      width: 100%;
    }
  }

  & .list {
    list-style: inherit;
    padding-left: 20px;
    li {
      list-style: inherit;
    }
  }
}

.moderation {
  .actions {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
  }

  .update {
    display: flex;
    cursor: pointer;

    &_status {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 100%;
      width: 8px;
      height: 8px;
      background: #149c08;
      border: 1px solid #ffffff;
    }
  }

  .content {
    border-top: 1px solid #f3f3f3;
    padding: 12px 0 0 0;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
