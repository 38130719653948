@import 'src/styles/variables';

$fixedSideWidth: 250px;

.itemWrap {
  &:global(.isHidden) {
    display: none;
  }
}

.item {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 10px;
  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 4px;
    width: 18px;
    flex: none;

    .img {
      transition: 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:global(.active) {
        transform: rotate(90deg);
      }
    }
  }
  .checkboxWrap {
    width: 100%;

    &:global(.end) {
      padding-left: 20px;
    }
    &:global(.toggleNone) {
      margin-left: 20px;
    }
  }
}

:global(.full) {
  .grid {
    & .header {
      & .scroll {
        & > div {
          & .cell {
            &:global(._isGrey) {
              font-size: 12px;
              color: #828282;
              line-height: 20px;
            }

            @for $i from 1 to 30 {
              &:global(.size-#{$i}) {
                width: calc(46px + #{$i * 8}px);
                min-width: calc(46px + #{$i * 8}px);
                padding: 0 4px;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }

    & .body {
      & .content {
        & .column {
          & .cell {
            &:global(._isGrey) {
              font-size: 12px !important;
              line-height: 20px !important;
              color: #828282;
            }

            @for $i from 1 to 30 {
              &:global(.size-#{$i}) {
                width: calc(46px + #{$i * 8}px);
                min-width: calc(46px + #{$i * 8}px);
                padding: 0 4px;
                justify-content: flex-end;
                & > div {
                  justify-content: flex-end;
                }

                &:global(._isCenter) {
                  & > div {
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.grid {
  display: block;
  width: 100%;

  &:global(.isExtended) {
    .header {
      .fixed {
        width: 320px;

        .cell {
          min-width: 320px;
        }
      }
    }
    .body {
      .fixed {
        width: 320px;
      }
    }
  }

  & .header {
    display: flex;
    position: relative;
    transform: translate(0);
    z-index: 2;

    & .fixed {
      width: $fixedSideWidth;
      position: relative;
      z-index: 3;

      & .cell {
        padding-left: 22px;
        min-width: $fixedSideWidth;

        &:first-child {
          border-right: 1px solid #ebebeb;
        }
      }
    }

    & .scroll {
      width: calc(100% - #{$fixedSideWidth});
      overflow: hidden;
      position: relative;

      & > div {
        display: flex;
        position: relative;
        justify-content: space-between;

        &:after {
          content: '';
          border-bottom: 1px solid #ebebeb;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }

        & .cell {
          width: 115px;

          &:first-of-type {
            margin-left: 10px;
          }

          @for $i from 1 to 30 {
            &:global(.size-#{$i}) {
              width: calc(40px + #{$i * 9}px);
              min-width: calc(40px + #{$i * 9}px);

              &:global(._isCenter) {
                justify-content: center;
              }
            }
          }

          .last-child {
            padding-right: 22px;
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &:global(._isNoRaw) {
        overflow: auto;

        &::-webkit-scrollbar {
          display: block;
        }

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }
      }
    }

    & .cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 8px;
      height: 40px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: $black;
      text-align: left;
      border-bottom: 1px solid #ebebeb;
      background: $white;
      white-space: nowrap;
      position: relative;
      flex: none;
      z-index: 1;
      max-width: 100%;

      &:global(._isGrey) {
        font-size: 12px !important;
        line-height: 20px !important;
        color: #828282;
      }

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  & .body {
    display: flex;
    position: relative;
    z-index: 1;

    &:global(._isEmpty) {
      .fixed {
        overflow: auto;

        &::-webkit-scrollbar {
          display: initial;
          width: 0;
          height: 0;
        }
      }
    }

    & .fixed {
      width: $fixedSideWidth;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      border-right: 1px solid #ebebeb;

      &:global(._noBorder) {
        border-right: 0;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }

      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      &::-webkit-scrollbar-track {
        background: $tr;
      }

      & .cell {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 10px 0 22px;
        height: 40px;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        text-align: left;
        background: $white;
        white-space: nowrap;
        position: relative;

        .action {
          display: flex;
          align-items: center;
          cursor: pointer;

          &:global(._noList) {
            padding-left: 26px;
          }

          .arrow {
            display: flex;
            align-items: center;
            width: 18px;
            justify-content: center;
            height: 18px;
            margin-right: 8px;
          }

          .img {
            transition: 0.2s;

            & > div {
              &:first-child {
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            &:global(.active) {
              transform: rotate(90deg);
            }
          }
        }

        .flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .title {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .searchTitle {
          background: #e6fce4;
        }

        &:global(.isVideoType) {
          padding-left: 30px;
        }

        &:global(.isGroup) {
          background-color: #f4f5f6;
          font-weight: 500;
        }

        &:global(.isChilds) {
          padding-left: 30px;
          font-weight: normal;
        }

        &:global(.isTotal) {
          font-weight: bold;
        }

        &:hover {
          .actions {
            opacity: 1;
            :global(.options_active) {
              svg {
                opacity: 1;
              }
            }
            &:global(.isDisabled) {
              opacity: 0;
            }
          }
          .circle {
            opacity: 0;
            z-index: -10;
            visibility: hidden;
            &:global(.isDisabled) {
              visibility: visible;
              z-index: 50;
              opacity: 1;
            }
          }
        }

        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          flex: none;

          & svg {
            margin-left: 8px;
            cursor: pointer;
            transition: all 0.2s;
            vertical-align: middle;
            width: 18px;

            &:hover {
              path {
                stroke: #025dff;
              }
            }
          }
        }

        &:global(.noEditing) {
          &:hover {
            .circle {
              opacity: 1;
              z-index: 10;
              visibility: visible;
            }
          }
          span {
            max-width: calc(100% - 30px);
          }
        }

        & .actions {
          flex: 1 1;
          margin-left: 8px;

          :global(.options_active) {
            svg {
              opacity: 0;
            }
          }

          .circle {
            opacity: 1;
            transition: opacity 0.3s;
            z-index: 2;
            visibility: visible;
          }

          &:global(.isOpened) {
            opacity: 1 !important;
          }
        }

        .circle {
          width: 6px;
          height: 6px;
          background: #04b900;
          border-radius: 100%;
          position: absolute;
          right: 17px;
          top: 50%;
          transform: translateY(-50%);
          visibility: visible;
          z-index: 50;
          opacity: 1;
        }
      }
    }
    & .content {
      display: flex;
      
      &:global(._isFull) {
        border-left: 1px solid #ebebeb;
      }

      & .column {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:global(.isGroup) {
          font-weight: 500;
          background-color: #f4f5f6;

          .cell {
            background-color: #f4f5f6;
          }

          &:global(.isChilds) {
            font-weight: normal;
          }
        }

        &:global(.isTotal) {
          font-weight: 500;
        }

        &:global(.isGrey) {
          background: #F9F9F9;
        }

        & .cell {
          width: 115px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 8px;
          height: 100%;
          font-size: 14px;
          line-height: 22px;
          min-width: 115px;
          color: $black;
          text-align: left;
          white-space: nowrap;
          position: relative;

          &:first-of-type {
            margin-left: 10px;
          }

          &:global(._isGrey) {
            font-size: 12px;
            line-height: 20px;
            color: #828282;
          }

          @for $i from 1 to 30 {
            &:global(.size-#{$i}) {
              width: calc(40px + #{$i * 9}px);
              min-width: calc(40px + #{$i * 9}px);
            }
          }
        }
      }
    }

    & .displayScroll {
      &::-webkit-scrollbar-thumb {
        display: block;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }
    }
  }

  & .cell {
    :global(.checkbox) {
      flex: none;
      width: 18px;
      height: 18px;
      border-radius: 1px;
      border: 1px solid #cbcbcb;
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
      position: relative;
      z-index: 1;
      transition: border 0.1s, background 0.1s;
      cursor: pointer;

      &:after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        position: absolute;
        z-index: 2;
        top: 0;
        left: 1px;
        bottom: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        transition: opacity 0.1s;
      }

      &:global(.isChildChecked) {
        background-color: $blue;
        border-color: $blue;

        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
          opacity: 1;
        }
      }

      &:global(.isChecked) {
        background-color: $blue;
        border-color: $blue;

        &:after {
          opacity: 1;
          background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
        }
      }

      &:global(.isDisabled) {
        background: #f6f6f6;
        border: 1px solid #828282;
        cursor: not-allowed;

        &:global(.isChildChecked) {
          background: #828282;

          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H9' stroke='%23DDDDDD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
          }
        }

        &:global(.isChecked) {
          background: #828282;
          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='%23DDDDDD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
          }
        }
      }
    }
  }

  & .input {
    width: 100%;
    display: flex;
    align-items: center;
  }

  & .bid {
    & > input {
      width: 100%;
      padding: 0 8px;
      background: #fff;
      border: 1px solid #ddd;
      box-sizing: border-box;
      border-radius: 1px;
      height: 28px;
      font-size: 14px;
      color: #0a0a0a;
      transition: border 0.1s;

      &:focus {
        outline: none;
        border: 1px solid #025dff;
      }
    }
  }
}

.container-grid {
  & .loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.7);
  }
}

.empty {
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  pointer-events: none;

  .body {
    display: flex;
    flex-direction: column;
    &:global(._isSmall) {
      svg {
        width: 130px;
        height: auto;
      }
    }
  }

  :global(.icon) {
    margin-bottom: 10px;
  }
}

.text {
  margin: 0 auto;
  text-align: center;
}

.icon {
  margin-right: 10px;
  margin-left: 10px;
}

.statistics_error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 29px 0 48px 0;
  font-size: 14px;
  line-height: 22px;

  &_icon {
    margin-top: 10px;
    display: inline-block;
    margin-bottom: 10px;
  }

  &_btn {
    color: #025dff;
    cursor: pointer;
    margin-top: 15px;
  }
}
