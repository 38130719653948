@import 'src/styles/variables';

.settings {
  &:global(.isOpen) {
    .settings {
      &__btn {
        box-shadow: none;
        background-color: $tr;
      }
      &__body {
        left: 0;
      }
    }
  }

  &__btn {
    position: fixed;
    top: 250px;
    left: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0 6px 60px rgba(0, 0, 0, 0.22);
    border-radius: 0 8px 8px 0;
    z-index: 1000;
    cursor: pointer;
    transition: 0.2s all ease;
  }

  &__body {
    position: fixed;
    left: -289px;
    top: 250px;
    transition: 0.2s all ease;
    background: #ffffff;
    box-shadow: 0 6px 60px rgba(0, 0, 0, 0.22);
    border-radius: 0 8px 8px 0;
    z-index: 999;
    width: 289px;
  }

  &__header {
    height: 44px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
  }

  &__close {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s all ease;

    &:hover {
      background: #f6f6f6;
    }
  }

  &__points > div {
    padding: 14px;
    display: flex;
    align-items: center;
    color: #828282;

    span {
      color: #828282;
    }

    .icon {
      div {
        display: flex;
        align-items: center;
      }
    }

    svg {
      width: 18px;
      margin-right: 16px;
    }

    &:hover {
      background: #f6f6f6;
      border-radius: 0 8px 8px 0;
      color: #222222;
      cursor: pointer;

      span {
        color: #222222;
      }
    }
  }
}
