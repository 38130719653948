@import 'src/styles/variables';

.item {
  display: block;
  position: relative;
  min-height: 76px;
  padding: 28px 22px;

  & .content {
    display: block;

    & .string {
      margin-top: 28px;

      &:first-child {
        margin-top: 0;
      }

      & > * {
        margin-bottom: 6px;
      }
    }
  }
}

.width100 {
  width: 100%;
}
.width45 {
  width: 45%;
}
.width20 {
  width: 20%;
}

.background {
  background-color: #f4f4f4;
  background-size: cover;
  background-position: center;
  display: block;
  width: 100%;
  height: 70%;
  opacity: 1;
  animation: load-item 1s infinite;
}

@include loadItemKeyframe;