@import 'src/styles/variables';

.card {
  margin: 0 auto;
  max-width: 774px;
  width: 100%;
  background: $white;
  display: block;
  pointer-events: all;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 28px;

  & .title {
    flex: 1 0 auto;
    max-width: calc(100% - 60px);
  }

  & .close {
    margin-left: 24px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    justify-content: center;
    align-items: center;

    svg {
      cursor: pointer;
      color: $black;
    }
  }
}

.content {
  & .search {
    border: 1px solid #ebebeb;
    flex: 1;

    &_wrapper {
      display: flex;
      align-items: center;
      gap: 0 24px;
      margin-bottom: 20px;
    }

    label {
      input {
        border: none;
        height: 40px;
        display: block;
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        padding: 0 15px;

        &::placeholder {
          color: #828282;
        }
      }
    }
  }
}

.update_btn {
  transition: 0.3s;
  svg {
    vertical-align: middle;
  }
  &:hover {
    cursor: pointer;
  }
  &:global(._isDisabled) {
    animation: rotate 2s ease-in-out 0.1s infinite;
    cursor: not-allowed;
    svg {
      pointer-events: none;
      color: #828282;
    }

    :global(.tooltipComponentHint) {
      display: none;
    }
  }
}

.list {
  border: 1px solid #ebebeb;
  height: 363px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $tr;
  }

  &::-webkit-scrollbar-thumb {
    background: #dddddd;
    border-radius: 15px;
  }

  & .item {
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background: $tr;
    transition: background 0.1s;
    padding: 0 14px;
    cursor: pointer;

    &:hover {
      background: #f4f5f6;
    }

    & > div {
      width: 100%;

      label {
        display: block;
        padding: 9px 0;
        width: 100%;
      }
    }

    &:global(.context) {
      display: block;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 28px 28px;

  & > * {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.tab_container {
  margin: 0 26px;
  padding: 0 26px;
}

.add {
  &_elem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &_elemLabel {
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      color: #828282;
      font-size: 12px;
      line-height: 12px; /* 100% */
      letter-spacing: 0.36px;
      text-transform: uppercase;
    }
  }
}

.textarea {
  position: relative;
  .field {
    background: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 1px;
    min-height: 100px;
    width: 100%;
    font-size: 14px;
    line-height: 26px;
    color: #0a0a0a;
    transition: border 0.1s;
    padding: 13px 14px;
    resize: none;
    height: 184px;
  }
}

.errorBox {
  :global(.ErrorText) {
    color: #222;
    line-height: 20px; /* 153.846% */
  }
  .errorText {
    span {
      color: #ff3636;
    }
  }
}
