@import 'src/styles/variables';

.history {
  &:global(.isFixedHeader) {
    .header {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 500;
    }
    .content {
      .fixedHeader {
        position: fixed;
        left: 0;
        top: 102px;
        width: 100%;
        z-index: 499;
        box-shadow: 0px 2px 28px #00000026;
        background-color: #fff;

        .table__header,
        .selectedFilters {
          max-width: $containerWidth;
          margin: 0 auto;
          padding-right: calc(50px + #{$containerPaddingRight});
          padding-left: calc(16px + #{$containerPaddingRight});

          @include desktopXL {
            max-width: $containerWidth-mob;
            padding-right: calc(50px + #{$containerPaddingRight-mob});
            padding-left: calc(16px + #{$containerPaddingRight-mob});
          }
        }
      }

      :global(.extend) {
        .fixedHeader {
          .table__header,
          .selectedFilters {
            max-width: $extendContainerWidth;
            padding-right: calc(50px + #{$extendContainerPaddingRight});
            padding-left: calc(16px + #{$extendContainerPaddingRight});

            @include desktopXL {
              max-width: $containerWidth-mob;
              padding-right: calc(50px + #{$containerPaddingRight-mob});
              padding-left: calc(16px + #{$containerPaddingRight-mob});
            }
          }
        }
      }
      :global(.full) {
        .fixedHeader {
          .table__header,
          .selectedFilters {
            max-width: $fullContainerWidth;
            padding-right: calc(50px + #{$fullContainerPaddingRight});
            padding-left: calc(16px + #{$fullContainerPaddingRight});
          }
        }
      }
    }
  }

  .header {
    background: #fff;
    height: 102px;
    display: flex;
    align-items: center;

    .headeRow {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:global(._isActiveSearch) {
        .title {
          display: none;
        }
        .search {
          border: 1px solid #025dff;
          flex: 1 0 auto;
          padding: 0 10px;

          .input {
            display: block;
          }

          .close {
            display: flex;
            align-items: center;
          }
        }
        .actions {
          flex: 1 0 auto;

          & > div {
            display: none;

            &:first-child {
              display: flex;
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 30px;

      .expandBtn {
        svg {
          width: 20px;
          height: auto;
        }
        &:hover {
          cursor: pointer;
        }
      }

      .limits {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        &:global(._isInput) {
          svg {
            color: #025dff;
          }
          .limits_number {
            display: none;
          }
          .limits_input {
            display: block;
          }
        }

        &_number {
          font-size: 16px;
        }
        &_input {
          display: none;

          input {
            width: 60px;
            height: 30px;
            padding: 4px;
          }
        }
      }
    }

    .search {
      height: 40px;
      border: 1px solid #fff0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      transition: all 0.1s;

      .icon {
        height: 24px;
        cursor: pointer;

        &:hover {
          svg {
            stroke: #025dff;
          }
        }
      }

      .input {
        flex: 1 0 auto;
        display: none;

        input {
          border-radius: 1px;
          padding: 0 12px;
          border: none;
          font-size: 14px;
          line-height: 22px;
          color: #222;
          width: 100%;
        }
      }

      .close {
        display: none;
        cursor: pointer;

        svg {
          color: #828282;
          transition: 0.3s;
        }

        &:hover {
          svg {
            color: #222;
          }
        }
      }
    }
  }

  .content {
    padding-top: 28px;
    padding-bottom: 40px;

    :global(.extend) {
      .table__header {
        grid-gap: 0 12px;
        grid-template-columns: 50px 130px 250px 300px 300px 200px auto;

        @include desktopXL {
          grid-template-columns: 0.3fr 0.6fr 1fr 0.7fr 0.5fr 0.5fr 1fr;
        }
      }
      .table__body {
        .table__row {
          white-space: pre-line;
          grid-gap: 0 12px;
          grid-template-columns: 50px 130px 250px 300px 300px 200px auto;

          @include desktopXL {
            grid-template-columns: 0.3fr 0.6fr 1fr 0.7fr 0.5fr 0.5fr 1fr;
          }
        }
      }
    }

    :global(.full) {
      .table__header {
        grid-gap: 0 12px;
        grid-template-columns: 50px 130px 250px 300px 300px 200px auto;

        @include desktopXL {
          grid-template-columns: 0.3fr 0.6fr 1fr 0.7fr 0.5fr 0.5fr 1fr;
        }
      }
      .table__body {
        .table__row {
          grid-gap: 0 12px;
          white-space: pre-line;
          grid-template-columns: 50px 130px 250px 300px 300px 200px auto;

          @include desktopXL {
            grid-template-columns: 0.3fr 0.6fr 1fr 0.7fr 0.5fr 0.5fr 1fr;
          }
        }
      }
    }

    .table__header {
      display: grid;
      padding-top: 16px;
      padding-right: 50px;
      padding-bottom: 16px;
      padding-left: 16px;
      grid-template-columns: 40px 120px 170px 150px 150px 150px auto;
      grid-gap: 0 10px;

      @include desktopXL {
        grid-template-columns: 0.3fr 0.6fr 1fr 0.7fr 0.5fr 0.5fr 1fr;
      }

      .table__headerItem {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    .table__body {
      .table__row {
        white-space: pre-line;
        display: grid;
        grid-template-columns: 40px 120px 170px 150px 150px 150px auto;
        grid-gap: 0 10px;
        background: #fff;
        box-shadow: 0px 2px 25px #0000000d;
        padding: 8px 50px 8px 16px;
        align-items: baseline;
        margin-bottom: 4px;
        position: relative;
        line-break: anywhere;
        overflow: hidden;

        @include desktopXL {
          grid-template-columns: 0.3fr 0.6fr 1fr 0.7fr 0.5fr 0.5fr 1fr;
        }

        &:global(._isNoCollapse) {
          max-height: fit-content !important;
        }

        &:global(._isWideItem) {
          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              #ffffff 64.58%,
              #ffffff 100%
            );
            width: 100%;
            height: 50px;
          }
        }

        .collapseBtn {
          position: absolute;
          right: 16px;
          top: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.2s;
          transform-origin: center;

          &:global(._isOpen) {
            transform: rotate(180deg);
          }

          &:hover {
            cursor: pointer;
          }
        }

        div {
          line-height: 1.5;
        }

        .campaign,
        .creo {
          div {
            &:nth-child(2),
            &:nth-child(3) {
              color: #b9b9b9;
            }
          }
        }
      }
    }

    .selectedFilters {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}
