@import 'src/styles/variables';

.warning {
  background: var(--status-alerts-and-notification-orange-10, #fff2c5);
  padding: 16px 22px;
  gap: 0 10px;
  position: relative;
  display: grid;
  grid-template-columns: 20px auto;
  align-items: center;

  &:global(._isWarningHtml) {
    display: grid;
    grid-template-columns: 20px auto 20px;
    align-items: baseline;
  }

  &:global(._isWarningErid) {
    display: grid;
    grid-template-columns: 20px auto auto;
    align-items: center;

    :global(.button_transparent) {
      color: #ff9c08;
      border: 1px solid #ff9c08;

      &:hover {
        background: hsla(36, 100%, 52%, 0.1);
      }
    }
  }

  &_text {
    font-size: 13px;
    line-height: 18px;
    strong {
      font-weight: 500;
    }
  }
  &_close {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff8e1;
    position: relative;

    &:hover {
      cursor: pointer;
    }
    svg {
      border-radius: 18px;
    }
  }
}
