@import 'src/styles/variables';

.container {
  @include campaignGrid;
}

.form_user {
  .loader {
    min-height: calc(100vh - 102px - 58px);
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}