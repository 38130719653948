@import 'src/styles/variables';

.settings {
  & .card {
    padding: 0;
  }

  & .title {
    padding-bottom: 29px;
    border-bottom: 1px solid #f3f3f3;
    padding: 29px 20px;
  }

  & .form {
    :global(.switch_body) {
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      :global(.text) {
        margin-left: 0;
        font-size: 12px;
        line-height: 18px;
        color: #828282;
      }
      :global(.toggle) {
        margin-left: 20px;
      }
    }

    .main {
      & > div {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      :global(.input-form-group) {
        label {
          margin-bottom: 20px;
        }
      }

      .lang {
        :global(.label) {
          margin-bottom: 20px;
        }
      }
    }

    .safety {
      .label {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
      }
      .items {
        max-width: 288px;
        & > div {
          margin-bottom: 48px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .push {
      :global(.switch) {
        padding: 20px 20px 20px 30px;
        border-bottom: 1px solid #f3f3f3;

        @include tablet {
          padding: 10px 10px 10px 20px;
        }
      }
      :global(.switch_body) {
        :global(.text) {
          font-size: 14px;
          line-height: 22px;
          color: #222222;
        }
      }
    }

    .appearance {
      .tables {
        padding: 30px;
        border-bottom: 1px solid #f3f3f3;

        @include tablet {
          padding: 10px;
          display: grid;
          grid-template-areas:
            'label'
            'radio_group';
        }

        .label {
          font-size: 12px;
          line-height: 12px;
          letter-spacing: 0.03em;
          text-transform: uppercase;
          color: #828282;
          margin-bottom: 30px;

          @include tablet {
            grid-area: label;
          }
        }

        .miniatures {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          margin-bottom: 20px;
          justify-items: center;

          @include tablet {
            display: none;
          }
        }

        :global(.radio_group) {
          @include laptop {
            grid-area: radio_group;
          }
        }

        :global(.radio_group-list) {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;

          @include tablet {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr;
          }

          :global(.item) {
            margin-left: 0;
          }
        }
      }

      .item {
        padding: 20px 20px 20px 30px;
        border-bottom: 1px solid #f3f3f3;

        .label {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #222222;
        }

        a {
          color: #025dff;
        }
      }
    }

    .status_translation {
      .item {
        padding: 20px 20px 20px 30px;
        border-bottom: 1px solid #f3f3f3;

        .label {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #222222;
        }
      }
    }
  }
}
