@import 'src/styles/variables';

.loading {
  :global(.load-item) {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
