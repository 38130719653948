@import 'src/styles/variables';

.showMore {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  position: relative;
  .item {
  }
  .total {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    background: #e3ecff;
    border-radius: 8px;
    margin: 4px;
    text-align: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.03em;
    text-align: center;
    color: #222;
  }
}
