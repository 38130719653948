@import 'src/styles/variables';

.card {
  background: #ffffff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  position: relative;

  &:hover {
    .resize {
      opacity: 1;
    }
  }

  &:global(._isEmpty) {
    height: 100%;

    .children {
      height: inherit;

      .chart {
        height: inherit;
      }
    }
  }

  .children {
    position: relative;

    .chart {
      padding: 34px 10px 34px 22px;
      width: 100%;

      &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 12px;
      }

      &_close {
        display: flex;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
      }

      &_legend {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px 24px;
      }

      &_item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        &:hover {
          div {
            svg {
              opacity: 1;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &:global(.isOff) {
          .name {
            color: #828282;
            text-decoration: line-through;
          }
        }

        & .tooltip {
          &:hover {
            & > div:first-child {
              .name {
                color: $blue;
              }
            }
          }
          & > div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }
        }

        & .circle {
          width: 14px;
          height: 14px;
          background: #40baff;
          border-radius: 50%;
          position: relative;
        }

        & .name {
          margin-left: 8px;
          font-size: 14px;
          line-height: 14px;
          color: $black;
          transition: color 0.1s;
        }

        & .checkbox {
          width: 16px;
          height: 16px;
          background: $black;
          border-radius: 1px;
          position: relative;

          &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.44444 1.3335L3.94444 6.66683L1.44444 4.24259' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            position: absolute;
            z-index: 2;
            top: 0;
            left: 1px;
            bottom: 0;
            right: 0;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }

      &_empty {
        height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
          margin-bottom: 10px;
        }
      }

      &_wrapper {
        min-height: 330px;
        height: 1px;
        width: 100%;
      }
    }
  }
}

.loadingGrid {
  display: grid;
  grid-template-areas:
    'left center center'
    'left center center'
    'left bottom bottom';
  grid-template-columns: 30px auto;
  grid-template-rows: auto auto 14px;
  height: 320px;
  gap: 5px;

  :global(.load-item) {
    &:nth-child(1) {
      grid-area: left;
      height: calc(100% - 24px);
    }
    &:nth-child(2) {
      grid-area: bottom;
    }
    &:nth-child(3) {
      grid-area: center;
    }
  }
}
