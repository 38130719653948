@import 'src/styles/variables';

:global(.full),
:global(.extend) {
  .item {
    &:global(._isApi) {
      grid-template-columns:
        minmax(100px, 0.5fr) minmax(150px, 0.5fr) minmax(100px, 0.6fr)
        minmax(100px, 0.8fr) 65px 40px;
    }
  }
}

.wrapper {
  position: relative;
  background: $white;

  &:global(._isLastEdited) {
    @include last-edited-row-animation;

    & .indicator {
      @include last-edited-indicator-animation;
    }
  }

  & .indicator {
    @include indicator;
    left: 0;
  }

  .item {
    background: $tr;
    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
    border-radius: 1px;
    padding: 12px 16px;
    grid-template-columns:
      minmax(230px, 1fr) minmax(350px, 1.2fr) minmax(120px, 1fr)
      minmax(80px, 0.5fr) 65px 40px;
    display: grid;
    align-items: center;
    grid-gap: 0 10px;
    min-height: 66px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;

    &:global(._isApi) {
      grid-template-columns:
        minmax(200px, 0.9fr) minmax(250px, 0.8fr) minmax(100px, 0.8fr)
        minmax(100px, 0.8fr) 65px 40px;
    }

    &:global(._isOnline) {
      grid-template-columns: 1fr 1fr 0.5fr 65px 40px;
    }

    &:global(._isStatistics) {
      grid-template-columns: 2.5fr 1.5fr 1fr 1fr 1fr 0.2fr;
    }

    & > a {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    @keyframes new-item {
      0% {
        box-shadow: 0 0 0 rgba(2, 85, 255, 0);
      }
      30% {
        box-shadow: 0 6px 60px rgba(2, 85, 255, 0.22);
      }
      100% {
        box-shadow: 0 0 0 rgba(2, 85, 255, 0);
      }
    }

    &:global(._isNew) {
      animation: new-item 1s ease-out forwards;
    }

    &:global(._isOpen) {
      grid-template-columns: 1fr 1fr !important;
      justify-content: space-between;

      & > div {
        display: none;

        &:nth-child(1) {
          display: flex;
        }

        &:last-child {
          display: flex;
          justify-self: stretch;
        }
      }

      & > a {
        display: none;
      }

      & .item__header {
        max-width: 800px;

        & > div {
          &:last-child {
            overflow: inherit;
          }
        }
      }

      & .dropdown {
        &__menu {
          @keyframes show-item {
            0% {
              transform: translateY(40px);
              opacity: 0;
            }
            100% {
              transform: translateY(0px);
              opacity: 1;
            }
          }

          & > div {
            animation: show-item 0.2s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;

            @for $i from 2 to 10 {
              &:nth-last-child(#{$i}) {
                animation-delay: #{($i * 0.1) - 0.1}s;
              }
            }
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
      z-index: 10;
      background: $backlightItems;
    }

    & > div {
      transition: all 0.2s ease;

      &:last-child {
        justify-self: end;
      }
    }

    & .value {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      flex-wrap: wrap;
      z-index: 2;
      line-height: 18px;

      & .group {
        display: inline-block;
        padding: 6px 8px;
        background: #e3ecff;
        border-radius: 8px;
        margin: 4px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #222222;
        position: relative;
      }

      &:global(.isStatus) {
        justify-content: center;
      }

      & .login {
        margin-left: 0;
        z-index: 10;
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        flex-wrap: wrap;
        gap: 0 4px;

        strong {
          font-weight: 500;
        }

        & .tooltipHint {
          white-space: normal;
          text-overflow: none;
          overflow: auto;
          word-break: break-all;
        }
      }

      .xxhash {
        color: #828282;
        font-size: 12px;
      }
    }

    & .token {
      width: 100%;
      display: flex;
      align-items: center;
      strong {
        font-weight: 500;
        margin-right: 4px;
      }
      span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      svg {
        margin-left: 4px;
      }

      .copy_icon {
        &:hover {
          cursor: pointer;
        }

        &:global(.isDisabled) {
          color: $green;
          pointer-events: none;
          &:hover {
            cursor: default;
          }
        }
      }
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      max-width: 280px;

      & .img {
        border-radius: 50%;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #3137bd;
        font-weight: 500;
        font-size: 14px;
        margin-right: 16px;
        z-index: 1;
        position: relative;

        & .status {
          width: 12px;
          height: 12px;
          border: 2px solid $white;
          border-radius: 50%;
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 2;
          &:global(.bg-LAUNCHED) {
            background: $green;
          }
          &:global(.bg-STOPPED) {
            background: $orange;
          }
          &:global(.bg-DELETED) {
            background: $red;
          }
          &:global(.bg-ARCHIVE) {
            background: $yellow;
          }
        }
      }

      & .text {
        width: calc(100% - 58px);

        & .tooltip {
          margin-left: 0;
          z-index: 10;
          width: 100%;
        }

        strong {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }

      & .hint {
        color: #828282;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        & > * {
          margin-left: 8px;

          &:first-child {
            margin-left: 0;
          }
        }

        & .type {
          display: block;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .dropdown {
    z-index: 2;
    position: relative;
    cursor: default;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &:global(._isOpen) {
      opacity: 1;
      visibility: visible;

      & .dropdown__show {
        opacity: 1;
        z-index: 0;
        background: #f4f5f6;
      }

      & .dropdown__menu {
        opacity: 1;
        visibility: visible;
      }
    }

    &__show {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #828282;
      cursor: pointer;
      transition: all 0.1s ease;

      &:disabled {
        cursor: default;
      }
    }

    &__menu {
      position: absolute;
      right: 64px;
      top: 0;
      bottom: 0;
      display: grid;
      height: 100%;
      grid-auto-flow: column;
      grid-gap: 0 20px;
      align-items: center;
      justify-content: end;
      opacity: 0;
      visibility: hidden;
      z-index: 2;

      &:before {
        position: absolute;
        content: '';
        top: -20px;
        bottom: -20px;
        left: 0;
        right: -64px;
        z-index: 0;
      }

      & > div {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        opacity: 0;

        & > span {
          display: block;
          padding: 4px 10px;
          background: #222222;
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          color: #fff;
          border-radius: 2px;
          font-size: 14px;
          line-height: 22px;
          opacity: 0;
          visibility: hidden;

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 0 8px;
            border-color: #222222 transparent transparent transparent;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        &:hover {
          span {
            opacity: 1;
            visibility: visible;
          }
          svg {
            color: #025dff;
          }
        }
      }
    }
  }

  .loadItem {
    width: 60px;
    height: 20px;
    background: #f4f4f4;
    animation: load-item 1.1s infinite;
  }
  .disabled {
    cursor: none !important;
    opacity: 0.5 !important;
  }

  @include loadItemKeyframe;
}
