@import 'src/styles/variables';

.actions {
  position: relative;

  &:global(._isDisabled) {
    pointer-events: none;
    opacity: 0.9;
  }

  &:global(._isLoading) {
    .loader {
      display: block;
    }
    .tooltip {
      pointer-events: none;

      & .button {
        background-color: $light-blue;

        svg {
          color: $blue !important;

          path {
            stroke: $blue !important;
          }
        }
      }
    }
  }

  &:global(._isShowActions) {
    .list {
      display: block;
    }
    .tooltip {
      & .button {
        background-color: #e2e2e2;
      }
    }
  }

  & .tooltip {
    margin-left: 0;

    &:hover {
      & .button {
        background-color: #e2e2e2 !important;

        &:hover {
          cursor: pointer;
        }

        svg {
          color: $black !important;
        }
      }
    }

    & .button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: #f4f5f6;
      transition: background 0.1s;

      svg {
        color: $black;
      }
    }
  }

  & .loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;

    svg {
      display: block;
      transform: rotate(-180deg);

      circle {
        stroke-dasharray: 103;
        transform-origin: center;
        animation: loader-dash 2s ease forwards infinite,
          loader-spin 2s linear infinite;
      }
    }

    @keyframes loader-dash {
      from {
        stroke-dashoffset: 103;
      }
      to {
        stroke-dashoffset: -103;
      }
    }

    @keyframes loader-spin {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  & .list {
    display: none;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    background-color: $white;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    border-radius: 1px;
    padding-bottom: 2px;
    width: 200px;

    & .item {
      font-size: 14px;
      line-height: 22px;
      color: $black;
      cursor: pointer;
      min-height: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 13px;

      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
}
