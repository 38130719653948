@import 'src/styles/variables';

.width100 {
  width: 100%;
}
.width45 {
  width: 45%;
}

.item {
  display: block;
  position: relative;
  min-height: 76px;
  padding: 28px 24px 14px;
  & .content {
    display: block;

    & .string {
      margin-top: 28px;

      &:first-child {
        margin-top: 0;
      }

      & > * {
        margin-bottom: 6px;
      }
    }
  }
}

@keyframes load-item {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.8;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
