@import 'src/styles/variables';

.Additional {
  & .header {
    background: #fff;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .body {
    .container {
      display: grid;
      grid-gap: 25px;
      grid-template-columns: 1fr;
      padding-top: 28px;
      padding-bottom: 40px;
    }

    .card_title {
      margin-bottom: 24px;

      .help {
        display: inline-flex;
        align-items: center;
        margin-left: 5px;
        font-size: 14px;
        font-weight: normal;
        &:hover {
          opacity: 1;
        }
        svg {
          vertical-align: middle;
        }
        :global(.tooltipComponentHint) {
          padding: 4px 10px;
        }
      }
    }

    .empty {
      padding: 24px 0;
      border-top: 1px solid #ebebeb;
    }

    .buttons {
      button {
        margin-left: auto;
      }
    }
  }
}

.form {
  form > * {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  .reports {
    margin-top: 32px;

    & :global(.switch) {
      display: inline-block;
    }

    h4 {
      margin-top: 32px;
      margin-bottom: 28px;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    & .helper {
      margin-top: -20px;
      margin-bottom: 3px;
      font-size: 13px;
      line-height: 20px;
      color: #828282;
    }

    & .list {
      margin-top: 25px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 21px 0;

      & > div {
        &:last-child {
          border-color: transparent;
          padding-bottom: 0;
        }
      }

      &:global(._disabled) {
        & .group {
          pointer-events: none;
          & > span {
            color: $text-hint;
            font-weight: 400;
          }
        }

        & .select {
          & > div {
            &:first-child {
              span {
                color: $text-hint;
              }

              svg {
                display: none;
                color: $text-hint;
              }
            }
          }
        }
      }

      & .group {
        padding-bottom: 13px;
        border-bottom: 1px #ebebeb solid;
        display: grid;
        grid-template-columns: 75% auto;
        align-items: center;

        & > span {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
      }

      & .select {
        display: inline-block;
        width: 100%;

        & > div {
          &:first-child {
            height: initial;
            padding-left: 3px;
            padding-right: 36px;
            border: none;
            width: 100%;

            & > svg {
              font-size: 24px;
              width: 24px;
              height: 24px;
              top: calc(50% - 12px);
            }
          }

          &:last-child {
            width: 120%;
            left: 50%;
            right: initial;
            transform: translateX(-50%);

            & > div {
              height: 40px;
              padding: 12px;
            }
          }
        }
      }
    }
  }
}
