@import 'src/styles/variables';

$heightTagList: 200px;
$borderColor: #e2e2e2;
$speedAnimation: 0.3s;

.tagsWrapper {
  position: relative;

  &:global(.horizontal) {
    display: grid;
    grid-template-columns: 150px auto;
    gap: 0 10px;

    label {
      &:first-child {
        display: inline-block;
        margin-bottom: 0;
        flex: none;
      }
    }
  }

  label {
    margin-bottom: 20px;
  }

  .tags {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px 8px;
    width: 100%;
    overflow: hidden;
    &:global(.isTableView) {
      display: block;
      width: 100%;
      overflow: hidden;
      border: 1px solid $borderColor;

      &:global(.isEmptyBody) {
        .tags_header {
          border-bottom: none;
        }
      }
      .tags {
        &_header {
          border-bottom: none;
        }
        &_add {
          svg {
            transition: 0.3s;
            transform: rotate(-45deg);
          }
          &:global(._isRotate) {
            svg {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
    &_shell {
      position: relative;
    }
    &_header {
      border-bottom: 1px solid $borderColor;
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    &_search {
      flex: 1;
      height: 100%;
      border: none;
      outline: none;
    }
    &_body {
      border-top: 1px solid $borderColor;
      padding: 10px 8px;
      height: $heightTagList;
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
    }
    &_bodyWrap {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 8px;
    }
    &_empty {
      flex: 1;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #828282;
    }
    &_enterList {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 0;
      padding: 0;
      overflow: hidden;
      border: 1px solid #ffffff00;
      border-top: none;
      transition: $speedAnimation;

      &:global(.isOpen) {
        animation: openList $speedAnimation linear 0s 1 forwards;

        .input {
          .textarea {
            animation: noOverflowForTextarea calc(#{$speedAnimation} + 0.3s)
              linear 0s 1 forwards;
          }
        }

        &:global(.isAbsolute) {
          background: #fff;
        }
      }

      &:global(.isAbsolute) {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
      }

      &:global(.isClosingAmin) {
        animation: hideList $speedAnimation linear 0s 1 forwards;

        .input {
          .textarea {
            animation: noOverflowForTextarea calc(#{$speedAnimation} + 0.3s)
              linear 0s 1 forwards;
          }
        }
      }

      .input {
        height: 120px;

        ::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        .textarea {
          height: 100%;
          width: 100%;
          border: none;
          resize: none;
          font-size: 14px;
          line-height: 143%;

          &::placeholder {
            color: #cfcfcf;
            font-size: 14px;
            line-height: 143%;
          }
        }
      }

      .action {
        display: flex;
        justify-content: flex-end;
      }

      .hint {
        position: absolute;
        width: $heightTagList;
        right: calc(100% + 20px);
        top: 0;
        background: #fff;
        padding: 16px;
        box-shadow: 0 2px 25px #0000002e;
        transition: $speedAnimation;
        opacity: 1;
        z-index: 10;
        font-size: 14px;
        line-height: 157%;

        &::before {
          content: '';
          border: 10px solid transparent;
          border-left: 10px solid rgb(255, 255, 255);
          position: absolute;
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .errorBlock {
    position: absolute;
    top: -16px;
    right: 0;

    span {
      margin-top: 0;
    }
  }

  :global(.revert_block) {
    position: absolute;
    top: -30px;
    right: 0;
  }
}

@keyframes openList {
  0% {
    height: 0;
    padding: 0;
    border-left: 1px solid #ffffff00;
    border-right: 1px solid #ffffff00;
    border-bottom: 1px solid #ffffff00;
    box-shadow: 0px -3px 2px 0px #ffffff00;
  }
  25% {
    padding: 10px 8px;
  }
  75% {
    overflow: initial;
  }
  100% {
    padding: 10px 8px;
    overflow: initial;
    height: $heightTagList;
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    box-shadow: 0px -3px 2px 0px #cccccc00;
  }
}

@keyframes hideList {
  0% {
    padding: 10px 8px;
    overflow: initial;
    height: $heightTagList;
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    box-shadow: 0px -3px 2px 0px #cccccc00;
  }
  25% {
    overflow: initial;
  }
  75% {
    padding: 10px 8px;
  }
  100% {
    height: 0;
    padding: 0;
    border-left: 1px solid #ffffff00;
    border-right: 1px solid #ffffff00;
    border-bottom: 1px solid #ffffff00;
    box-shadow: 0px -3px 2px 0px #ffffff00;
  }
}

@keyframes noOverflowForTextarea {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: initial;
  }
}
