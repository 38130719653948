@import 'src/styles/variables';

.targeting {
  & .empty_cap {
    padding-top: 30px;

    & .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      svg {
        display: inline-block;
        margin-right: 8px;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $black;
      }
    }

    & .actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 16px;
    }
  }

  & .card {
    & .title {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #222222;
      padding: 15px 24px;
      margin-left: -24px;
      margin-right: -24px;
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
    }

    & .table {
      & .item {
        height: 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }

    & .actions {
      border-top: none;
    }
  }

  & .form {
    padding: 20px 0;

    & > div {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
