.saveModal {
  &:global(.infoBlockText) {
    margin-bottom: 20px;
  }
}
:global(.InfoBlock) {
  :global(.InfoBlock_text) {
    p,
    strong {
      font-size: 12px;
    }
  }
}

.card {
  max-width: 774px;
  width: 100%;
  padding: 50px 30px;

  .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    &.saving {
      .saving_line {
        width: 414px;
        background: #e1e0e0;
        height: 6px;
        margin: 0 auto;
        display: block;
        position: relative;
      }
      .saving_lineColor {
        position: absolute;
        left: 0;
        top: 0;
        width: 0%;
        height: 100%;
        border-radius: 1px;
        background: var(--primary-blue-20, #025dff);
        transition: 1s;
      }
    }

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 125% */
    }

    .text {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */

      strong {
        font-weight: 500;
      }
    }
  }

  &_close {
    position: absolute;
    right: 30px;
    top: 24px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    &:hover {
      background: darken(#f4f5f6, 5%);
    }

    svg {
      cursor: pointer;
      color: black;
    }
  }
}
