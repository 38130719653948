@import 'src/styles/variables';

.customCard {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  padding: 28px 24px;
  position: relative;
  display: block;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .form {
    & > * {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .sizesField {
      .label {
        margin-bottom: 8px;
      }
      span {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .flex_row {
    display: flex;
    justify-content: space-between;
    &_col {
      flex: 1;
    }
  }

  .rough {
    @extend .flex_row;
    gap: 0 24px;
    &_col {
      flex: 1;
    }
  }

  .sizes {
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    &_col {
      flex: 1;
    }
  }
}

.card {
  position: static;
  padding: 0;
}

:global(.isLocked) {
  .form {
    .sizesField {
      .label {
        color: $text-hint;
      }
      span {
        color: $text-hint;
        cursor: not-allowed;
      }
    }
  }
}
