@import 'src/styles/variables';

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 24px;
}

.main {
  .rows {
    display: grid;
    grid-template-rows: 1fr;
    gap: 24px 0;

    :global(.ErrorText) {
      justify-self: end;
    }
  }
  .multiselect {
    display: grid;
    grid-template-columns: 150px auto;
    gap: 0 10px;
    align-items: center;
  }
  .label {
    color: #828282;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }
  .status {
    display: grid;
    grid-template-columns: 150px auto;
    gap: 0 10px;
    align-items: center;
  }
}