@import 'src/styles/variables';

.client {
  min-height: 58px;
  display: flex;
  padding: 8px 14px;
  cursor: pointer;
  transition: background 0.1s;

  &:hover {
    background-color: $gray-bg;
  }

  & .icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3137bd;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  & .data {
    padding-left: 16px;
    width: calc(100% - 42px);
    padding-top: 2px;

    & .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      word-break: break-word;
    }

    & .info {
      font-size: 12px;
      line-height: 18px;
      color: $text-hint;

      span {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
