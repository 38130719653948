@import 'src/styles/variables';

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 24px;
}

.methods {
  .checkboxes {
    display: grid;
    grid-template-rows: 1fr;
    gap: 16px 0;

    max-height: 272px;
    overflow-y: scroll;

    &:global(._isScroll) {
      padding: 10px 20px;
      border: 1px solid #e2e2e2;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    .item {
      display: grid;
      grid-template-columns: 18px auto;
      align-items: center;
      gap: 0 16px;

      &:hover {
        cursor: pointer;
      }

      .label {
        font-size: 14px;
        line-height: 22px;
        strong {
        }
        span {
          color: var(--neutral-grey-50, #828282);
        }
      }
    }
  }
}
