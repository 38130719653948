@import 'src/styles/variables';

.targeting {
  & .header {
    height: 102px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: $white;

    &__wrapper {
      min-height: 102px;
      position: sticky;
      top: 48px;
      z-index: 3;

      &:global(._isSmall) {
        min-height: 90px;

        .header {
          height: 90px;

          @include phone {
            height: auto;
            padding: 10px 0;
          }
        }
      }
      &:global(._isFixed) {
        z-index: 998;

        .header {
          box-shadow: 0 2px 28px rgba(0, 0, 0, 0.15);

          & .status {
            & .title {
              font-size: 16px;
              line-height: 18px;
              font-weight: 700;
            }
          }
        }
      }
    }

    & .data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      @include phone {
        flex-direction: column;
        align-items: baseline;
      }
    }

    & .status {
      min-height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @include phone {
        margin-bottom: 10px;
      }

      & .title {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #222222;
        word-break: break-all;
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;
        gap: 4px;

        span {
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:global(.copied) {
            &:hover {
              cursor: default;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }

        :global(.tooltipComponentHint) {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    & .actions {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      margin-left: 6px;
      align-self: end;

      & > * {
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }

        @include phone {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }

      @include phone {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 10px;
      }
    }
  }

  & .card {
    & .title {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #222222;
      padding: 15px 24px;
      margin-left: -24px;
      margin-right: -24px;
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
    }

    & .table {
      & .item {
        height: 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  & .content {
    padding: 28px 0;

    &:global(._isSmall) {
      padding: 14px 0;
    }
  }

  & .form {
    margin-bottom: 8px;

    & > div {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  & .center {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}

:global(.full) {
  .form {
    margin-bottom: 4px;
  }
}
