@import 'src/styles/variables';

.detail {
  :global(.loader) {
    margin: 32px auto 0;
  }

  & :global(.title) {
    max-width: calc(100% / 12 * 5);
    word-wrap: break-word;
  }
}
