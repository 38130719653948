@import 'src/styles/variables';

.notifications {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & .title {
    flex: 1 0 auto;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: $black;
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }

    & > div {
      & > div {
        &:first-of-type {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  & .switch {
    flex: none;
  }
}
