@import 'src/styles/variables';

.container {
  @include campaignGrid;
}

.card {
  padding: 0;
  .form {
    display: flex;
    flex-direction: column;
    gap: 24px 0;
    margin-top: 28px;

    &:global(._isNoAccess) {
      cursor: not-allowed !important;
      & > * {
        pointer-events: none !important;
      }
    }
  }

  .label {
    color: #828282;
    font-size: 12px;
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
  }

  .info {
    font-size: 13px;
    line-height: 20px;
    color: $black;

    &:global(.isUppercase) {
      text-transform: uppercase;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.field_item {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  :global(.prompt) {
    display: flex;
    align-items: center;
    height: 32px
  }

  :global(.PriceInput) {
    &:global(.horizontal) {
      flex: 1;
      display: grid;
      grid-template-areas:
        'PriceLabel PriceBody'
        'PriceError PriceError';
      grid-template-columns: 200px auto;
      :global(.label) {
        grid-area: PriceLabel;
      }
      :global(.PriceInput_body) {
        grid-area: PriceBody;
        input {
          width: 100%;
        }
      }
      :global(.ErrorText) {
        grid-area: PriceError;
      }
    }
  }
}

.loader {
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  height: calc(100% - 48px);
  z-index: 999;
  background: rgba(255, 255, 255, 0.3);
  pointer-events: all;
  transition: all 0.1s ease;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &:global(.isShow) {
    opacity: 1;
    visibility: visible;
  }
}

/* Блок отчетов */
.reports {
  margin-top: 28px;
  & .list {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 21px 0;

    & > div {
      &:last-child {
        border-color: transparent;
        padding-bottom: 0;
      }
    }

    &:global(._disabled) {
      & .group {
        pointer-events: none;
        & > span {
          color: $text-hint;
          font-weight: 400;
        }
      }

      & .select {
        & > div {
          &:first-child {
            span {
              color: $text-hint;
            }

            svg {
              display: none;
              color: $text-hint;
            }
          }
        }
      }
    }

    & .group {
      padding-bottom: 13px;
      border-bottom: 1px #ebebeb solid;
      display: grid;
      grid-template-columns: 75% auto;
      align-items: center;

      & > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }

    & .select {
      display: inline-block;
      width: 100%;

      & > div {
        &:first-child {
          height: initial;
          padding-left: 3px;
          padding-right: 36px;
          border: none;
          width: 100%;

          & > svg {
            font-size: 24px;
            width: 24px;
            height: 24px;
            top: calc(50% - 12px);
          }
        }

        &:last-child {
          width: 120%;
          left: 50%;
          right: initial;
          transform: translateX(-50%);

          & > div {
            height: 40px;
            padding: 12px;
          }
        }
      }
    }
  }

  :global(.inputField) {
    grid-template-columns: 180px auto;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 20px;
}