@import 'src/styles/variables';

.empty {
  margin: 0 auto;
  text-align: center;
  padding: 40px 0;

  &:hover {
    cursor: pointer;

    .text {
      color: $blue;
    }

    .icon {
      svg > * {
        stroke: $blue;
      }
    }
  }

  & .text {
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $black;
  }
}
