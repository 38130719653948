@import 'src/styles/variables';

.wrapper {
  min-height: 102px;
}

.header {
  background: $white;
  height: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:global(.isFixed) {
    position: fixed;
    top: 48px;
    height: 102px;
    left: 0;
    right: 0;
    z-index: 1001;
    box-shadow: 0 2px 28px rgba(0, 0, 0, 0.15);
    & .container {
      & .info {
        & .status {
          & .title {
            font-size: 16px;
            line-height: 18px;
            font-weight: 700;
          }
        }
      }
    }
  }

  & .container {
    display: grid;
    grid-template:
      'breadcrumbs breadcrumbs'
      'info actions';

    & .breadcrumbs {
      grid-area: breadcrumbs;
    }

    & .info {
      grid-area: info;

      & .status {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-height: 40px;

        & .title {
          font-size: 16px;
          line-height: 18px;
          padding-right: 20px;
          word-break: break-all;
          display: grid;
          grid-template-columns: auto auto auto;
          align-items: center;
          grid-gap: 4px;
          gap: 4px;

          span {
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            
            &:global(.copied) {
              &:hover {
                cursor: default;
              }
            }
            &:hover {
              cursor: pointer;
            }
          }

          :global(.tooltipComponentHint) {
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }

    & .actions {
      grid-area: actions;
      width: 100%;
      align-items: center;
      justify-items: end;
      justify-content: end;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 16px;
    }
  }
}

.resize {
  cursor: pointer;

  svg {
    color: $black;
  }

  &:hover {
    svg {
      color: $blue;
    }
  }
}
