@import 'src/styles/variables';

.container {
  @include campaignGrid;
}

.item {
  border-bottom: 1px #ebebeb dashed;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  :global(.prompt) {
    margin-top: 5px;
    margin-left: 20px;
  }
  &:last-child {
    border: none;
    padding-bottom: 0px;
  }
  :global(.chackboxField) {
    margin-top: 10px;
  }
  &:global(._isPriceHorisontal) {
    :global(.PriceInput) {
      &:global(.horizontal) {
        flex: 1;
        display: grid;
        grid-template-areas:
          'PriceLabel PriceBody'
          'PriceError PriceError';
        grid-template-columns: 200px auto;
        :global(.label) {
          grid-area: PriceLabel;
        }
        :global(.PriceInput_body) {
          grid-area: PriceBody;
          input {
            width: 100%;
          }
        }
        :global(.ErrorText) {
          grid-area: PriceError;
        }
      }
    }
  }

  :global(.inputField) {
    :global(.labelWrapper) {
      margin-right: 10px;
    }
  }
}

.text {
  font-size: 14px;
  line-height: 22px;
}
