@import 'src/styles/variables';

.inputFieldWrapper {
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  cursor: pointer;

  .inputField {
    border: none;
    max-width: 240px;

    @include laptop {
      max-width: 150px;
    }
  }

  &:global(.isError) {
    border: 1px solid $red;
  }

  .counter {
    color: #b9b9b9;
    margin-right: 8px;
    margin-bottom: 0;
  }

  .close {
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      color: $blue;
    }
  }
}