@import 'src/styles/variables';

.inputField {
  display: grid;
  grid-template-columns: 150px auto;
  align-items: center;
  gap: 0 10px;

  @include phone {
    grid-template-columns: auto;
    width: 100%;
  }

  .labelWrapper {
    flex: none;
    width: 100%;

    @include phone {
      width: 100%;
    }

    label {
      margin-bottom: 0;
    }
  }
}
