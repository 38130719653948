@import 'src/styles/variables';

.card {
  max-width: 774px;
  width: 100%;

  &_header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 41px;
  }

  &_title {
    flex: 1 0 auto;
    max-width: calc(100% - 60px);
  }

  &_close {
    margin-left: 24px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    &:hover {
      background: darken(#f4f5f6, 5%);
    }

    svg {
      cursor: pointer;
      color: black;
    }
  }

  &_form {
    margin-bottom: 40px;
  }

  &_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    :global(.inputField) {
      flex: 1;

      :global(.labelWrapper) {
        margin-right: 0;
        width: 100px;
      }
    }

    :global(.label) {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #222222;
      text-transform: capitalize;
    }

    :global(.isDisable) {
      color: #b9b9b9;
    }

    :global(.isError) {
      :global(.InputAutoSaveLabel) {
        border-bottom: 1px solid #FF3636 !important;
      }
    }

    :global(.InputAutoSaveLabel) {
      border-bottom: 1px solid #b9b9b9 !important;
    }

    &:nth-child(2) {
      margin-top: 45px;
    }
    &:last-child {
      margin-bottom: 0;
    }

    &_label {
      width: 150px;
      flex: none;
      font-size: 16px;
      line-height: 22px;
    }

    &_field {
      flex: 1;
    }
  }
  &_actions {
    display: flex;
    justify-content: flex-end;

    button {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

.reset_passwords {
  flex: 1;
  text-align: left;
  padding-left: 8px;
  color: #025dff;
  cursor: pointer;
}
