@import 'src/styles/variables';

.options {
  position: relative;

  &:global(.isDisabled) {
    pointer-events: none;
  }

  & .active {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      color: $black !important;
    }
  }
}

.list {
  position: fixed;
  transition: opacity 0.1s, transform 0.1s;
  background: $white;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);
  z-index: 999;

  & .item {
    min-height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 14px;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    transition: background 0.1s;

    &:hover {
      background: #f6f6f6;
      cursor: pointer;
    }
  }
}
