@import 'src/styles/variables';

.tooltip {
  display: inline-block;
  vertical-align: middle;
  position: relative;

  svg {
    vertical-align: middle;
  }

  &:hover {
    .tooltip {
      &__title {
        & > svg {
          color: #025dff;
        }
      }

      &__hint {
        display: block;
      }
    }
  }

  &:global(._isWhite) {
    .tooltip {
      &__hint {
        padding: 16px;
        border-radius: 1px;
        background: #fff;
        color: #222;

        &:after {
          border-color: #fff $tr #fff $tr;
        }

        p,
        h6 {
          color: #222;
        }

        a {
          color: #025dff;

          &:active {
            color: #013fdd;
          }
        }
      }
    }
    &:global(._isRight) {
      .tooltip {
        &__hint {
          &:after {
            border-color: $tr #fff $tr #fff;
          }
        }
      }
    }
    &:global(._isBottom) {
      .tooltip {
        &__hint {
          &::after {
            border-color: #fff $tr #fff $tr;
          }
        }
      }
    }

    &:global(._isLeft),
    &:global(._isFixTopPadding) {
      .tooltip {
        &__hint {
          &:after {
            border-color: $tr #fff $tr #fff;
          }
        }
      }
    }

    &:global(._isFixBottomPadding) {
      .tooltip {
        &__hint {
          &:after {
            border-color: #fff $tr $tr $tr;
          }
        }
      }
    }

    &:global(._isFixLeftPadding) {
      .tooltip {
        &__hint {
          &:after {
            border-color: $tr #ffffff $tr #ffffff;
          }
        }
      }
    }
  }

  &:global(._isRight) {
    .tooltip {
      &__hint {
        left: calc(100% + 12px);
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);

        &::before {
          width: 12px;
          height: 100%;
          left: -12px;
          top: 0;
        }

        &:after {
          top: 50%;
          left: auto;
          right: 100%;
          transform: translate(0, -50%);
          border-color: $tr #222 $tr #222;
          border-width: 10px 10px 10px 0px;
        }
      }
    }
  }

  &:global(._isBottom) {
    .tooltip {
      &__hint {
        top: calc(100% + 12px);
        bottom: auto;

        &::before {
          left: 0;
          top: -12px;
        }
        &::after {
          top: auto;
          bottom: 100%;
          border-color: #222 $tr #222 $tr;
          border-width: 0px 10px 10px 10px;
        }
      }
    }
  }

  &:global(._isLeft) {
    .tooltip {
      &__hint {
        left: auto;
        right: calc(100% + 12px);
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);

        &::before {
          width: 12px;
          height: 100%;
          right: -12px;
          top: 0;
          left: auto;
        }

        &:after {
          top: 50%;
          left: 100%;
          right: auto;
          transform: translate(0, -50%);
          border-color: $tr #222 $tr #222;
          border-width: 10px 0px 10px 10px;
        }
      }
    }
  }

  &:global(._isFixRightPadding) {
    .tooltip {
      &__hint {
        left: auto;
        bottom: calc(100% + 12px);
        right: 0;
        transform: translate(0, 0);

        &:after {
          left: auto;
          right: 12px;
          transform: translate(0, 0);
        }
      }
    }
  }

  &:global(._isFixTopPadding) {
    .tooltip {
      &__hint {
        left: auto;
        right: calc(100% + 12px);
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);

        &::before {
          width: 12px;
          height: 100%;
          right: -12px;
          top: 0;
          left: auto;
        }

        &:after {
          top: 50%;
          left: 100%;
          right: auto;
          transform: translate(0, -50%);
          border-color: $tr #222 $tr #222;
          border-width: 10px 0px 10px 10px;
        }
      }
    }
  }

  &:global(._isFixBottomPadding) {
    .tooltip {
      &__hint {
        bottom: calc(100% + 12px);
        top: auto;
        left: 50%;
        transform: translateX(-50%);

        &::before {
          left: 0;
          top: 100%;
          right: auto;
          bottom: auto;
        }

        &:after {
          top: 100%;
          bottom: auto;
          left: 50%;
          transform: translateX(-50%);
          border-color: #222 $tr $tr $tr;
          border-width: 10px 10px 0 10px;
        }
      }
    }
  }

  &:global(._isFixLeftPadding) {
    .tooltip {
      &__hint {
        left: calc(100% + 12px);
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);

        &::before {
          width: 12px;
          height: 100%;
          left: -12px;
          top: 0;
        }

        &:after {
          top: 50%;
          left: auto;
          right: 100%;
          transform: translate(0, -50%);
          border-color: $tr #222 $tr #222;
          border-width: 10px 10px 10px 0px;
        }
      }
    }
  }

  &:global(._isPositionLeft) {
    .tooltip {
      &__hint {
        left: 0px;
        transform: translateX(0);

        &::after {
          left: 12px;
          transform: translate(0px, 0px);
        }
      }
    }
  }

  &:global(.isDisabled) {
    cursor: not-allowed !important;

    svg {
      color: #828282 !important;
    }

    &:hover {
      &__title {
        svg {
          color: #828282 !important;
        }
      }
    }
  }

  &__title {
    position: relative;
    svg {
      vertical-align: middle;
    }
  }

  &__hint {
    display: none;
    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 14px;
    box-shadow: 0px 7px 50px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    background: #222;
    color: #fff;
    z-index: 20;
    text-transform: none;
    width: max-content;
    height: fit-content;
    line-height: 22px;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #222 $tr $tr $tr;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, 0.12));
      background: $tr;
      z-index: -1;
    }

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      width: 100%;
      height: 12px;
      left: 0;
      z-index: 0;
    }

    h6 {
      margin: 0 0 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #fff;
      word-break: break-all;
    }

    p {
      margin: 0 0 16px;
      font-size: 14px;
      line-height: 22px;
      color: #fff;
      word-wrap: break-word;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      font-size: 14px;
      line-height: 22px;
      color: #fff;
      vertical-align: middle;

      &:hover {
        opacity: 1;
        text-decoration: underline;
      }

      &:active {
        color: #eee;
      }

      span {
        vertical-align: middle;
      }

      svg {
        vertical-align: -2px;
      }
    }
  }
}
