@import 'src/styles/variables';

.widget {
  font-size: 13px;
  line-height: 20px;
  color: $black;
  border-top: 4px solid $orange;

  &:global(.noTopLine) {
    border-top: none;
  }

  img {
    max-width: 100%;
  }

  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: $black;
    margin-bottom: 12px;
  }

  p {
    font-size: 13px;
    line-height: 20px;
    color: $black;
    margin-bottom: 8px;
    white-space: pre-wrap;
  }

  ul {
    font-size: 13px;
    line-height: 20px;
    color: $black;

    li {
      margin-top: 8px;

      &:before {
        content: '·';
        margin-right: 8px;
      }
    }
  }

  :global(.image) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
  }
}
