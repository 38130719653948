.google-visualization-tooltip {
  padding: 16px !important;
  border: none !important;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.24) !important;
  border-radius: 1px !important;
  background: #ffffff;
  pointer-events: none !important;
}

.google-visualization-tooltip strong {
  font-family: Rubik;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.google-visualization-tooltip span {
  display: block;
  font-family: Rubik;
  font-size: 14px;
  line-height: 22px;
  color: #025dff;
  margin-top: 4px;
}

.google-visualization-tooltip span:nth-of-type(2) {
  color: #40baff;
}

.google-visualization-tooltip span:nth-of-type(3) {
  color: #0dd3c7;
}
.google-visualization-tooltip span:nth-of-type(4) {
  color: #e82c59;
}
.google-visualization-tooltip span:nth-of-type(5) {
  color: #3b29a8;
}
.google-visualization-tooltip span:nth-of-type(6) {
  color: #52c94f;
}
.google-visualization-tooltip span:nth-of-type(7) {
  color: #df51a6;
}
.google-visualization-tooltip span:nth-of-type(8) {
  color: #f6ac55;
}
.google-visualization-tooltip span:nth-of-type(9) {
  color: #a043e9;
}
.google-visualization-tooltip span:nth-of-type(10) {
  color: #6443e9;
}
.google-visualization-tooltip span:nth-of-type(11) {
  color: #251a50;
}
