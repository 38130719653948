@import 'src/styles/variables';
@import '~styles/main';

.filtersWrapper {
  position: fixed;
  top: 150px;
  left: 0;
  right: 0;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.15);

  opacity: 0;
  z-index: -100;

  &:global(._isShow) {
    opacity: 1;
    z-index: 998;
    transition: 0.2s;
  }

  .filters {
    display: flex;
    align-items: center;
    grid-gap: 0 20px;
    padding: 16px;

    &_left {
      @include leftPartForCampaignItem;
    }

    &_center {
      @include centerPartForCampaignItem;
      @include setGridTamplateForCampaignLine;
    }

    &_item {
      @include calcSizeForCampaignItem;

      :global(.TableFilterItem) {
        justify-content: flex-end;
      }

      &:global(._isHide) {
        @include tablet {
          display: none;
        }
      }
    }

    &_right {
      @include rightPartForCampaignItem;
      &::before {
        background: linear-gradient(to right, #ffffff75 10%, #ffffff 100%);
      }
    }

    &:global(._isFullScreen) {
      .filters_left {
        width: $widthLeftPartCampaignItemFull;
        @include mobileLeftSize;
      }
    }

    &:global(._isWeekView) {
      .filters_item {
        :global(.TableFilterItem) {
          justify-content: flex-start;
        }
      }
    }

    .filter {
      width: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
