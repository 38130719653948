.card__title {
  margin-bottom: 16px;
}

.card {
  :global(.UploadImage) {
    margin-bottom: 24px;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}