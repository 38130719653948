@import 'src/styles/variables';

.wrapper {
  position: relative;

  & .children {
    &:hover {
      & + .message {
        display: flex;
      }
    }
  }

  & .message {
    display: none;
    position: absolute;
    top: 50%;
    right: calc(100% + 16px);
    background: $white;
    box-shadow: 0px 7px 50px rgba(0, 0, 0, 0.12);
    border-radius: 1px;
    transform: translateY(-50%);
    padding: 16px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $black;
    width: 240px;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0px;
      transform: rotate(45deg) translateY(-50%);
      box-shadow: 0px 7px 50px rgba(0, 0, 0, 0.12);
      z-index: 0;
      background: $white;
      width: 14px;
      height: 14px;
    }

    &:hover {
      display: flex;
    }
  }
}
