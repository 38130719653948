@import 'src/styles/variables';

.partner_select {
  :global(.new_select__multi-value__label) {
    background: #e3ecff !important;
  }
}

.partner_option {
  cursor: pointer;
  .label {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    pointer-events: none !important;
    user-select: none !important;

    .name {
      font-size: 14px;
      line-height: 157%;
      color: #222;
    }

    .hash {
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #828282;
    }
  }
}

.partner_container {
  &_empty {
    opacity: 1;
  }
  .incriment {
    width: fit-content !important;
    font-size: 14px !important;
    line-height: 20px;
    color: #222222;
    padding: 4px 8px !important;
    white-space: break-spaces !important;
    margin: 4px !important;
    background: #e3ecff !important;
    border-radius: 8px !important;
  }
}
