@import 'src/styles/variables';

.create-campaign {
  & .tab {
    display: none;
    &:global(.isShow) {
      display: block;
    }
  }
}

.header {
  background: $white;
  height: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 3;

  & .status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;

    & .title {
      max-width: calc(100% / 12 * 5);
      word-wrap: break-word;
    }
  }
}
