@import 'src/styles/variables';

.fixedLoader {
  position: fixed;
  top: 106px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, 0.4);
}

.creative {
  .loader {
    margin: 32px auto 0;
  }

  h3 {
    display: flex;
    align-items: center;

    svg {
      margin-left: 4px;
    }
  }

  & :global(.react-tabs__tab-list) {
    pointer-events: all;
  }
}

.content {
  display: block;
}

.container {
  @include campaignGrid;
}

.cards {
  display: grid;
  grid-gap: 20px;
  align-self: self-start;
}

.card {
  &__form {
    & > * {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-size: 13px;
      line-height: 20px;
      color: $black;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 24px;

    &:first-of-type {
      margin-top: 0;
    }

    &.card__row_nm {
      .card__col {
        margin-right: 0;
      }
    }

    &.card__row_input-with-select {
      .card__col {
        &:nth-child(1) {
          position: relative;
          right: -1px;
          z-index: 1;
          max-width: calc(100% - 115px);
        }

        &:nth-child(2) {
          max-width: 114px;
        }
      }
    }
  }

  &__col {
    margin-right: 20px;
    flex: 1 0 auto;
    max-width: calc(50% - 10px);

    &:global(.extend) {
      max-width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  h3 {
    margin-bottom: 28px;
  }
}

.actions {
  padding: 0;
  background: $tr;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;

  button {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.status {
  & .status__tab {
    display: flex;
    align-items: center;
  }

  & .status__lamp {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #828282;
    background: #e2e2e2;
    margin-left: 4px;
  }

  & .status__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  }

  & .status__text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 14px;
      line-height: 22px;
      color: #828282;
    }

    svg {
      margin-right: 8px;
    }
  }

  & .status__date {
    font-size: 14px;
    line-height: 22px;
    color: #828282;
    margin-bottom: 4px;
  }

  & .status__description {
    p {
      font-size: 14px;
      line-height: 22px;
      color: #828282;
    }
  }
}

.translation {
  :global(.childrenWrapper) {
    max-height: fit-content;
  }
}
