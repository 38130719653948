@import 'src/styles/variables';
@import '~styles/main';

:global(.extend),
:global(.full) {
  .item {
    & .content {
      grid-template-columns: 40px 400px 1.4fr 1.1fr 1.1fr 1.1fr 1fr 1fr 1.2fr 1.1fr auto;
    }

    .campaign_left {
      width: $widthLeftPartCampaignItemFull;
      @include mobileLeftSize;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 76px;

  &:global(._isHeader) {
    background: $tr;
    box-shadow: none;
    min-height: 48px;
    margin-bottom: 0;
    padding: 20px 22px 0;

    & .content {
      & .text {
        & .title {
          display: none;
        }
      }
    }
  }

  &:global(._isCampaign) {
    display: flex;
    align-items: center;
    grid-gap: 0 20px;
    padding: 16px;
  }

  &:global(._isHistory) {
    padding-top: 16px;
    padding-right: 50px;
    padding-bottom: 16px;
    padding-left: 16px;
  }

  &:global(._isCreative){
    padding: 8px 12px;
    min-height: 48px;
  }

  & .content {
    display: grid;
    grid-gap: 0 10px;
    align-items: center;
    padding-left: 40px;
    flex: 1;

    &:global(.agency) {
      grid-template-columns: 0.2fr 5fr 5fr 0.4fr;
    }

    &:global(.user) {
      grid-template-columns:
        minmax(230px, 1fr) minmax(350px, 1.2fr) minmax(120px, 1fr)
        minmax(80px, 0.5fr) 65px 40px;
      padding-left: 0;

      & .text {
        width: 100%;
        & .title {
          margin-bottom: 0px;
          @include laptop {
            :global(.load-item) {
              width: 100% !important;
            }
          }
        }
      }
    }

    &:global(.usersApi) {
      grid-template-columns:
      minmax(200px, 0.9fr) minmax(250px, 0.8fr) minmax(100px, 0.8fr)
      minmax(100px, 0.8fr) 65px 40px;
      padding-left: 0;

      & > :global(.load-item) {
        width: 100%;
      }
    }

    &:global(.partner) {
      grid-template-columns: 2fr 1fr 2fr 1fr 0.28fr;
      padding-left: 0;

      @include laptop {
        grid-template-columns: 4fr 1fr 2fr 1fr 40px;
      }
    }

    &:global(.creative) {
      grid-template-columns: 0.3fr 0.3fr 0.3fr 2.5fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.4fr;
      padding-left: 0;
      align-items: center;
    }

    &:global(.client) {
      grid-template-columns: 0.2fr 5fr 5fr 0.4fr;
    }

    &:global(.offer) {
      grid-template-columns: minmax(130px, 1fr) minmax(350px, 3fr) 40px;
      gap: 20px;
      padding-left: 0;

      .text {
        & > div {
          &:last-child {
            display: none;
          }
        }
      }

      :global(.load-item) {
        &:nth-child(2) {
          width: 100%;
        }
        &:nth-child(3) {
          width: 36px;
        }
      }
    }

    &:global(.counteragents) {
      grid-template-columns: 1.5fr 1fr 1.1fr 0.8fr 0.7fr;
      gap: 20px;
      padding-left: 0;

      & .header {
        display: none;
      }

      :global(.load-item) {
        width: 100%;
      }
    }

    &:global(.history) {
      grid-template-columns: 40px 120px 170px 150px 150px 150px auto;
      align-items: start;
      grid-gap: 0 10px;
      padding: 0;

      @include desktopXL {
        grid-template-columns: 0.3fr 0.6fr 1fr 0.7fr 0.5fr 0.5fr 1fr;
      }

      .double {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;
      }
    }

    &:global(.contracts) {
      grid-template-columns: 50px 1.2fr 0.4fr minmax(140px, 1.1fr) 1fr
      minmax(100px, 0.4fr) 0.5fr 60px;
      grid-gap: 0 10px;
      padding-left: 0;

      & .header {
        display: none;
      }

      :global(.load-item) {
        width: 100%;
      }
    }

    & .name {
      display: flex;
      align-items: center;
    }

    & .header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    & .logo {
      width: 42px;
      height: 42px;
      display: block;
      border-radius: 50%;
      background: #e7e7e7;
      animation: load-item 1.1s infinite;
      margin-right: 20px;
      flex: none;
    }

    & .text {
      & .title {
        margin-bottom: 4px;
      }
    }
  }
}

.campaign {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  grid-gap: 0 20px;
  padding: 16px;
  padding-bottom: 13px;
  background: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  height: 51px;

  @include tablet {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }

  &_center {
    @include calcWidthForCenterPart;
    @include centerPartForCampaignItem;
    @include setGridTamplateForCampaignLine;

    :global(.Statistics_item) {
      display: flex;
      justify-content: flex-end;
      @include calcSizeForCampaignItem;

      &:global(._isGrey) {
        font-size: 12px !important;
        line-height: 12px !important;
        font-weight: 500;
        color: #828282;

        @include tablet {
          display: none;
        }
      }
    }

    .loadingItem {
      display: flex;
      justify-content: flex-end;
      @include calcSizeForCampaignItem;

      @include tablet {
        &:last-child {
          display: none;
        }
      }
    }
  }

  &_left {
    @include leftPartForCampaignItem;
    width: $widthLeftPartCampaignItem;
    flex: none;

    @include tablet {
      width: $widthLeftPartCampaignItemMobile;
    }
  }

  &_right {
    width: $widthRightPartCampaignItem;

    @include tablet {
      width: $widthRightPartCampaignItemMobile;
    }
  }
}

@include loadItemKeyframe;
