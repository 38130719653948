@import 'src/styles/variables';
@import '~styles/main';

.audiences {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  padding: 0;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 22px 0px 22px;

    &:global(._isOpenSearch) {
      justify-content: flex-end;
      h3 {
        display: none;
      }
    }
  }

  & .body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 220px;
    overflow: auto;
    padding: 0px 22px 0px 22px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    & .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      svg {
        cursor: pointer;

        &:hover {
          color: $blue;
        }
      }
    }

    & .empty {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      gap: 10px;
      p {
        font-size: 14px;
        line-height: 157%;
        text-align: center;
        color: #222;
      }
    }
  }

  & .add_btn {
    @include dashedBtn;

    margin: 0px 22px 17px 22px;
  }

  :global(.MainSearch) {
    margin-right: 0;
  }
}
