@import 'src/styles/variables';

.card {
  &__form {
    & > * {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-size: 13px;
      line-height: 20px;
      color: $black;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 24px;

    &:first-of-type {
      margin-top: 0;
    }

    &.card__row_nm {
      .card__col {
        margin-right: 0;
      }
    }

    &.card__row_input-with-select {
      .card__col {
        &:nth-child(1) {
          position: relative;
          right: -1px;
          z-index: 1;
          max-width: calc(100% - 115px);
        }

        &:nth-child(2) {
          max-width: 114px;
        }
      }
    }
  }

  &__col {
    margin-right: 20px;
    flex: 1 0 auto;
    max-width: calc(50% - 10px);

    &:global(.extend) {
      max-width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  h3 {
    margin-bottom: 28px;
  }
}
