@import 'src/styles/variables';

.report {
  position: relative;
  background: #f5f5f5;

  & .header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:global(.isFixed) {
      position: fixed;
      top: 48px;
      width: 100%;
      z-index: 1000;
    }

    .status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-transform: none;

      & .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        & .resize {
          margin-right: 32px;
          cursor: pointer;
        }

        & .toggle-title {
          margin-right: 32px;

          &:global(.isDisabled) {
            pointer-events: none;

            svg {
              color: #828282;
            }
          }

          & .tooltip {
            margin-left: 0;

            & > div {
              &:last-child {
                top: calc(50% - 19px);
              }
            }
          }
        }

        & .range {
          margin-right: 16px;
        }

        & .update,
        & .showTree {
          margin-right: 16px;

          &:hover {
            cursor: pointer;
          }

          svg {
            vertical-align: middle;
          }
        }

        .showTree {
          svg {
            width: 24px;
            height: auto;
          }
          &:global(._isShow) {
            svg {
              color: $blue;
            }
          }
        }
      }
    }
  }

  & .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    &_tabs {
      display: flex;
      width: 100%;
      &_item {
        padding: 6px 8px;
        line-height: 20px;
        background-color: #e3ecff;
        border-radius: 8px;
        margin-right: 10px;
        cursor: pointer;
        &:global(._isNoActive) {
          color: #828282;
          background-color: #f6f6f6;
        }
      }
    }
    .settings {
      &_btn {
        background: #fff;
        border-radius: 1px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          svg {
            color: $blue;
          }
        }
        svg {
          transition: color 0.4s;
        }
      }
      &_panel {
        position: fixed;
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.15);
        background: #fff;
        max-width: 271px;
        transition: all 0.3s;

        opacity: 0;
        visibility: hidden;
        transform: translateX(50%);
        z-index: -10;
        top: 0;
        left: 0;

        &:global(._isOpen) {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
          z-index: 1000;
        }

        &_search {
          padding: 10px;
          & > div {
            border: 1px solid #e2e2e2;
            border-radius: 1px;
            background: #fff;
            padding: 13px 14px;
            position: relative;

            input {
              border: none;
              width: 100%;
              outline: none;
              padding-right: 24px;
            }

            svg {
              position: absolute;
              right: 14px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        &_body {
          max-height: 170px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 6px;
          }

          & > div {
            padding: 10px;
            transition: background 0.3s;
            cursor: pointer;
            text-transform: uppercase;

            &.empty {
              cursor: default;
              background: #fff;
              text-transform: initial;
            }

            &:hover {
              background: #f6f6f6;

              &.empty {
                background: #fff;
              }
            }
          }
        }

        &_actions {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;

          padding: 10px;

          :global(.button) {
            min-width: fit-content;
          }
        }
      }
    }
  }

  & .content {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
