@import 'src/styles/variables';

.card {
  padding: 0;

  & .content {
    padding-top: 28px;

    & .selected {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }

      & .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      & .checkbox {
        margin-right: 16px;
        width: 18px;
        height: 18px;
        border: 1px solid #cbcbcb;
        border-radius: 1px;
        cursor: pointer;
        position: relative;

        &:global(._isDisabled) {
          cursor: not-allowed;

          &:global(._isFullyChecked) {
            background: #828282;
            border: 1px solid #828282;
          }
          &:global(._isPartiallyChecked) {
            background: #828282;
            border: 1px solid #828282;
          }
        }

        &:global(._isFullyChecked) {
          background: $blue;
          border: 1px solid $blue;
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }

        &:global(._isPartiallyChecked) {
          background: $blue;
          border: 1px solid $blue;
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 8H12' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }

        &:before {
          position: absolute;
          z-index: 2;
          content: '';
          top: 0;
          bottom: 0;
          right: 0;
          left: 1px;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    & .table {
      width: 100%;
      margin-top: 16px;
      border-top: 1px solid #ebebeb;
      padding-top: 16px;
      display: flex;
      flex-wrap: wrap;

      & .item {
        width: 25%;
        margin-bottom: 16px;
      }
    }
  }
}
