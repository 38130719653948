@import 'src/styles/variables';

.inputWrapper {
  position: relative;
  height: 32px;

  &:global(.modeView) {
    height: auto;
  }

  &:global(.isDisable) {
    & > * {
      pointer-events: none;
    }

    &:hover {
      .input {
        border-bottom: 1px solid #ebebeb;
      }
      .copy {
        display: none;
      }
    }

    .label {
      span {
        color: hsl(0, 0%, 60%);
      }
    }
  }

  &:hover {
    .input {
      border-bottom: 1px solid #025dff;
    }
    .copy {
      display: inline-block;
    }
  }

  .input {
    width: 100%;
    height: 100%;
    border: 1px solid $tr;
    border-bottom: 1px solid #ebebeb;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 105px;
    cursor: pointer;

    &::placeholder {
      color: #b9b9b9;
      font-size: 14px;
      line-height: 20px;
    }

    &:global(.modeEdit) {
      border: 1px solid $blue;
      cursor: text;
      &:hover {
        border-bottom: 1px solid $blue;
      }
    }
    &:global(.modeView) {
      display: none;
    }

    &:global(.isError) {
      border: 1px solid $red;
      &:hover {
        border-bottom: 1px solid $red;
      }
    }
  }

  .label {
    display: none;
    max-width: 100%;
    word-break: break-all;
    border: 1px solid #0000;
    border-bottom: 1px solid #ebebeb;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.2px;
    height: 100%;
    cursor: pointer;
    padding-right: 40px;
    transition: background 0.3s;

    &:hover {
      border-bottom: 1px solid $blue;
    }

    &:global(.modeView) {
      display: flex;
      align-items: center;
      padding-left: 10px;
      min-height: 32px;
    }
    &:global(.isSaved) {
      border: 1px solid $savedInputEffect;
      background: $savedInputEffect;
      &:hover {
        border-bottom: 1px solid $savedInputEffect;
      }
    }
  }

  .placeholder {
    color: #b9b9b9;
    font-size: 14px;
    line-height: 20px;
  }

  .copy {
    display: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    svg {
      color: $blue;
      width: 17px;
      height: 17px;
    }
  }
  .length {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    color: #b9b9b9;
    font-size: 14px;
    line-height: 15px;
  }
  .close {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    svg {
      vertical-align: middle;
      color: $blue;
    }
  }
  .errorText {
    position: absolute;
    right: 0;
    top: 100%;
  }
}
