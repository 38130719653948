@import 'src/styles/variables';

.togglePanel {
  margin-bottom: 48px;

  &:global(.loaded) {
    .buttonIcon {
      background-color: $tr;
    }

    .buttonArrow {
      right: 20px;
      cursor: pointer;
    }
  }

  & .titlePanel {
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: #828282;
    margin-bottom: 10px;
  }

  & .button {
    display: flex;
    align-items: center;
    padding: 8px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 1px;
    position: relative;

    &:hover {
      cursor: pointer;

      .buttonIcon {
        svg {
          color: $blue;
        }
      }
    }
  }

  & .buttonIcon {
    width: 60px;
    height: 60px;
    background: #f4f5f6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    overflow: hidden;

    svg {
      flex: none;
      color: #b9b9b9;
      transition: all 0.3s;
    }
  }

  & .buttonText {
    max-width: 160px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .buttonArrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
}
