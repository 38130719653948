@import 'src/styles/variables';

.header {
  background: $white;
  height: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 3;
}
