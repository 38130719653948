@import 'src/styles/variables';

.card {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img {
    width: 163px;
    margin-bottom: 10px;
  }

  .processing {
    display: flex;
    align-items: center;
    gap: 10px;

    :global(.Loader) {
      width: 20px;
      height: 20px;
    }
  }

  .cancel {
    margin-top: 30px;
    cursor: pointer;
    color: var(--Primary-Blue-20, #025dff);
  }
}
