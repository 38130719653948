@import 'src/styles/variables';

.header {
  cursor: pointer;
  transition: background 0.1s;

  &:nth-last-child(1),
  &:nth-last-child(2) {
    .button {
      .more {
        .list {
          left: auto;
          right: 0;
        }
      }
    }
  }

  &:nth-child(1) {
    & .button {
      & .text {
        flex: 1 0 auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: calc(100% - 88px);
      }
    }
  }

  &:hover {
    background-color: #f6f6f6;
    .button {
      .more {
        svg {
          color: $black;
        }
      }
    }
  }

  &:global(._isOpened) {
    background-color: #f6f6f6;
    .button {
      .more {
        svg {
          color: $black;
        }

        .list {
          pointer-events: all;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  & .button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    :global(.checkbox) {
      z-index: 2;
    }

    & .text {
    }

    & .more {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      position: relative;

      svg {
        color: #828282;
      }

      & .list {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        display: block;
        background: #ffffff;
        box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
        border-radius: 1px;
        padding: 1px 0;
        transform: translateY(-20px);
        opacity: 0;
        pointer-events: none;
        transition: transform 0.1s, opacity 0.1s;
        z-index: 111;

        & .item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0 16px;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: $black;
          text-transform: none;
          height: 32px;
          transition: background 0.1s;

          &:global(._isActive) {
            color: #828282;

            svg {
              color: #828282;
            }
          }

          &:hover {
            background: #f6f6f6;
          }

          svg {
            display: block;
            color: $black;
            margin-right: 8px;
            padding-bottom: 2px;
          }
        }
      }
    }

    & .spacer {
      flex-grow: 1;
    }

    & .sortIcon {
      margin-left: 8px;

      svg {
        display: block;
        color: $black;
      }
    }
  }

  & .expand {
    position: absolute;
    right: -12px;
    top: calc(50% - 12px);
    z-index: 10;

    &:global(._isExpandStart) {
      &:after {
        position: absolute;
        content: '';
        background: $tr;
        top: -75px;
        left: -75px;
        right: -75px;
        bottom: -75px;
      }
    }

    & .button {
      background: #ffffff;
      border: 1px solid #ebebeb;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      transition: background 0.1s, border 0.1s;

      &:hover {
        background: #e3ecff;
        border: 1px solid $blue;

        svg {
          color: $blue;
        }
      }

      svg {
        color: $black;
      }
    }
  }
}
