@import 'src/styles/variables';

.add {
  display: none;
  background: #E3ECFF;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #E3ECFF80;
  }

  &:global(.isToggle) {
    display: flex;
  }
  svg {
    color: #025DFF;
  }
}
.addInputWrapper {
  display: flex;

  &:global(.isToggle) {
    display: none;
  }
}
