@import 'src/styles/variables';

.erir {
  background: #f6f6f6;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  &:global(._isSuccess) {
    background: #e6fce4;
    .erir_status {
      background: #149c08;
    }
  }
  &:global(._isNotSend) {
    .erir_btn {
      display: block;
    }
  }
  &:global(._isWillExpoert) {
    background: var(--status-alerts-and-notification-orange-10, #fff2c5);

    .erir_status {
      background: #ff9c08;
    }
  }
  &:global(._isError) {
    background: var(--status-alerts-and-notification-red-10, #ffe3e3);

    .erir_status {
      background: #ff3636;
    }
  }
  &_status {
    flex: none;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #e2e2e2;
  }
  &_text {
    font-size: 12px;
  }
  &_btn {
    display: none;
    color: var(--primary-blue-20, #025dff);
    cursor: pointer;
    font-size: 12px;
    flex: 1;
    text-align: right;
  }
}
