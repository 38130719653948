@import 'src/styles/variables';

.gridItem {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid $tr;
  transition: all 0.1s;

  &:hover {
    border: 2px solid lighten($blue, 25%);
  }

  &:global(.isActive) {
    border: 2px solid $blue;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
