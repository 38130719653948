@import 'src/styles/variables';

.card {
  margin: 0 auto;
  max-width: 774px;
  width: 100%;
  background: $white;
  display: block;
  pointer-events: all;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 28px 28px 20px;

  & .title {
    flex: 1 0 auto;
  }

  & .close {
    margin-left: 24px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    justify-content: center;
    align-items: center;

    svg {
      cursor: pointer;
      color: $black;
    }
  }
}

.content {
  padding: 0 0 16px;

  & .list {
    & .item {
      display: grid;
      align-items: center;
      grid-template-columns: 40px auto;
      color: $black;
      font-size: 14px;
      line-height: 22px;
      min-height: 48px;
      background: $tr;
      transition: background 0.1s;
      cursor: pointer;
      padding: 0 28px;

      &:hover {
        background: #f6f6f6;
        opacity: 1;
      }

      img {
        max-width: 24px;
      }
    }
  }
}

.plag_img {
  width: 24px;
  height: 24px;
  background-color: $blue;
  opacity: 0.2;
  border-radius: 100%;
}