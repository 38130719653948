@import 'src/styles/variables';

.form-group {
  position: relative;

  &:global(.horizontal) {
    label {
      display: inline-block;
      margin-right: 24px;
      margin-bottom: 0;

      @include phone {
        min-width: 70px;
      }
    }

    & .title {
      display: inline-block;
      padding-left: 8px;
      padding-right: 21px;
      border-bottom: 1px solid #ebebeb;
      height: 32px;
      line-height: 32px;
    }

    & .body {
      display: inline-block;
      border-bottom: 1px solid $tr;

      &:hover {
        input {
          border-bottom: 1px solid $blue;
        }
      }

      input {
        height: 32px;
        border: 1px solid $tr;
        border-bottom: 1px solid #ebebeb;
        width: 105px;
        padding: 0;
        padding-left: 7px;

        &:focus {
          border: 1px solid #025dff;
        }
      }

      &:global(.isSaved) {
        input {
          background: $savedInputEffect;

          &:focus {
            border: 1px solid $tr;
          }
        }
      }

      :global(.DayPickerInput-Overlay) {
        width: 260px;
      }
    }
  }

  &:global(.error) {
    label {
      color: $red;
    }
    & .body {
      input {
        border: 1px solid $red;

        &:focus {
          border-color: $red;
        }
      }
    }
  }

  :global {
    .DayPicker-wrapper {
      position: relative;
    }
    .calendar-navbar {
      top: 24px;
      left: 0;
      right: 0;

      button {
        cursor: pointer;
        &:nth-child(1) {
          left: 12px;
          svg {
            right: auto;
            pointer-events: all;
          }
        }
        &:nth-child(2) {
          svg {
            left: auto;
            pointer-events: all;
          }
        }
      }
    }
  }

  & .title {
    font-size: 14px;
    line-height: 22px;
    color: #222222;
    &:global(.isSaved) {
      background: $savedInputEffect;
    }
  }

  label {
    color: $text-hint;
    font-size: 12px;
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
  }

  & .body {
    input {
      background: $white;
      border: 1px solid $grey;
      box-sizing: border-box;
      border-radius: 1px;
      height: 48px;
      width: 100%;
      padding-left: 14px;
      padding-right: 48px;
      font-size: 14px;
      color: $black;
      transition: border 0.1s;

      &:focus {
        outline: none;
        border: 1px solid $blue;
      }
    }

    textarea {
      background: $white;
      border: 1px solid $grey;
      box-sizing: border-box;
      border-radius: 1px;
      min-height: 100px;
      width: 100%;
      font-size: 14px;
      line-height: 26px;
      color: $black;
      transition: border 0.1s;
      padding: 13px 14px;
      resize: none;

      &:focus {
        outline: none;
        border: 1px solid $blue;
      }
    }

    & > div {
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 18px;
        pointer-events: none;
      }
    }
  }

  &__link {
    position: absolute !important;
    top: 0;
    right: 0;
    font-size: 12px;
    line-height: 12px;
    text-align: right;

    a {
      color: $blue;
    }
  }
}

:global(.isLocked) {
  :global(.DateInput) {
    .title {
      cursor: not-allowed;
      color: $text-hint;
    }
  }
}