@import 'src/styles/variables';

.search {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 40px;
  transition: width 0.1s;

  &:global(.isOpen) {
    width: 100%;

    .open {
      left: 8px;
    }

    .input {
      opacity: 1;
      pointer-events: all;
    }

    .close {
      opacity: 1;
      pointer-events: all;
      transform: translateX(0);
    }
  }

  & .open {
    position: absolute;
    left: 15px;
    top: 10px;
    cursor: pointer;
    z-index: 2;
  }

  & .input {
    position: relative;
    display: block;
    width: 100%;
    z-index: 1;
    padding-left: 40px;
    padding-right: 40px;
    height: 40px;
    border: none;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    border: 1px solid $grey;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s, border 0.1s;

    &::placeholder {
      color: #828282;
    }

    &:focus {
      border: 1px solid $blue;
    }
  }

  & .close {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    z-index: 2;
    opacity: 0;
    transform: translateX(20px);
    pointer-events: none;
    transition: opacity 0.1s, transform 0.1s;
  }
}
