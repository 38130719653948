@import 'src/styles/variables';

$widthStaticPart: 240px;

.table {
  margin-top: 20px;
  background-color: #fff;

  &_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 21px 22px;
  }

  &_content {
    max-height: 408px;
    overflow-x: hidden;
    overflow-y: scroll;

    .table_row {
      .table_row_scroll {
        overflow-x: hidden !important;
      }
    }
  }

  &_content_long {
    max-height: 60vh;
  }

  &_body {
    display: block;
    width: 100%;
    position: relative;

    .table_header {
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      overflow-y: scroll;

      .cell {
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        cursor: pointer;
        padding-right: 10px;

        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 90px;
        }

        .sort_icon {
          flex: none;
          width: 8px;
          margin-left: 6px;
        }
      }
    }
    .table_row_static {
      width: $widthStaticPart;
      border-right: 1px solid #ebebeb;
      flex: none;

      .cell {
        width: 100%;
      }
    }
    .table_row_scroll {
      display: flex;
      overflow: hidden;
      justify-content: space-between;
      // width: calc(100% - #{$widthStaticPart});
      width: 100%;
    }
    .table_row {
      display: flex;
      align-items: center;


      &:hover {
        background: #f6f6f6;
      }

      &:global(._isTotal) {
        overflow-y: scroll;
        .cell {
          text-transform: uppercase;
          font-weight: 500;
        }
      }

      &_bar {
        position: absolute;
        left: 0;
        bottom: -8px;
        width: 100%;
        height: 8px;

        &:hover {
          background: initial;
        }

        .table_row_static {
          height: 8px;
        }
        .table_row_scroll {
          height: 8px;
        }
      }

      &:last-of-type {
        .table_row_scroll {
          overflow-x: scroll;
        }
      }

      &:global(._isGroup) {
        background: #F9F9F9;

        &:hover {
          background: #f6f5f5;
        }
      }

      &:global(._isTree) {
        .table_row_static {
          padding-left: 34px;
        }
      }
    }
    .cell {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: #222;
      text-align: left;
      white-space: nowrap;
      flex: none;
      width: 150px;
      transition: background 0.1s;
      padding-left: 18px;
      padding-right: 18px;
      position: relative;

      & .expand {
        position: absolute;
        right: -12px;
        top: calc(50% - 12px);
        z-index: 10;

        &:global(.isMove) {
          &:after {
            content: '';
            position: absolute;
            background: $tr;
            top: -50px;
            left: -50px;
            right: -50px;
            bottom: -50px;
            z-index: -1;
          }
        }

        & .button {
          background: #ffffff;
          border: 1px solid #ebebeb;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;
          transition: background 0.1s, border 0.1s;

          &:hover {
            background: #e3ecff;
            border: 1px solid $blue;

            svg {
              color: $blue;
            }
          }

          svg {
            color: $black;
          }
        }
      }

      &:global(._isLongForAgency) {
        width: 150px;
      }

      span {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:global(._isAlignRight) {
        justify-content: flex-end;
      }

      :global(.icon) {
        transition: 0.3s;
        &:hover {
          path {
            fill: #9a9a9a;
          }
        }
      }
    }
  }
}
