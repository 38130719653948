@import 'src/styles/variables';

.filter {
  display: inline-block;
  position: relative;
  white-space: nowrap;

  &:global(.isOpened) {
    .active {
      background: #e3ecff;
      border-color: $blue;

      &:hover {
        background: #e3ecff;
      }

      svg {
        transform: rotate(-180deg);
        color: $blue;
      }
    }
  }

  &:global(.isDisabled) {
    cursor: not-allowed !important;
    .active {
      pointer-events: none;

      svg {
        color: #828282;
      }

      &:hover {
        background: #e3ecff;
      }
    }
  }

  & .active {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 16px 0 16px;
    font-size: 14px;
    line-height: 22px;
    background: #e3ecff;
    z-index: 100;
    cursor: pointer;
    transition: background 0.2s ease;

    &:hover {
      background: #c4d5fa;
    }

    svg {
      margin-left: 4px;
      transition: transform 0.2s ease;
      transform: rotate(0);
      color: #0a0a0a;
    }
  }

  & .list {
    position: absolute;
    opacity: 0;
    transform: translateY(-40px);
    pointer-events: none;
    top: calc(100% + 4px);
    left: 0;
    min-width: 100%;
    z-index: 140;
    background: $white;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    transition: opacity 0.1s, transform 0.1s;

    &:global(.isOpened) {
      opacity: 1;
      transform: translateY(0px);
      pointer-events: all;
    }

    &:global(._available) {
      display: none;
      & > div {
        &:nth-child(2) {
          display: none;
        }
      }
    }

    & .item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 40px;
      font-size: 14px;
      line-height: 22px;
      color: #0a0a0a;
      padding: 10px 16px 8px;
      background: $tr;
      transition: background 0.1s;
      cursor: pointer;

      &:hover {
        background: #e3ecff;
      }

      & .point {
        flex: none;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #ffffff;
        border: 1px solid #cbcbcb;
        position: relative;

        &:before {
          content: '';
          opacity: 0;
          position: absolute;
          top: 4px;
          left: 4px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $blue;
        }
      }

      & .description {
        margin-left: 16px;
        max-width: calc(100% - 34px);

        & .title {
          font-size: 14px;
          line-height: 22px;
          color: $black;
          white-space: nowrap;
        }

        & .hint {
          margin-top: 4px;
          font-size: 13px;
          line-height: 20px;
          color: $black;
          white-space: pre-line;
        }
      }

      &:global(.isActive) {
        .point {
          border: 1px solid $blue;

          &:before {
            opacity: 1;
          }
        }

        .title {
          color: #025dff;
        }
      }
    }
  }
}
