@import 'src/styles/variables';

.reports {
  position: relative;
  background: #f5f5f5;
  min-height: calc(100vh - 106px);
  padding-bottom: 40px;

  &__header {
    background: $white;
    height: 102px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      word-break: break-all;
    }
  }

  &__table {
    padding-top: 28px;

    .report {
      height: 76px;
      width: 100%;
      background: $white;
      box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);
      border-radius: 1px;
      transition: box-shadow 0.1s;
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 22px;
      z-index: 1;
      position: relative;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        background-color: #e7e7e7;
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
        z-index: 2;
      }

      &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        flex: 1 0 auto;
        max-width: calc(100% - 160px);
      }
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > button {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
