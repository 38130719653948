@import 'src/styles/variables';

.show {
  position: relative;

  &:global(._isShow) {
    .show_btn {
      display: none;
    }

    .show_block {
      display: block;
    }
  }

  &_btn {
    z-index: 999999;
    cursor: pointer;
    color: #37404d;
    background: #f0f0f066;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: auto;
    padding: 0;
    font-size: 18px;
    position: absolute;
    bottom: 4px;
    right: 4px;
    overflow: hidden;
  }

  &_block {
    display: none;
    z-index: 999999;
    background-color: #000000b3;
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    inset: 0;
  }

  &_close {
    z-index: 999999;
    cursor: pointer;
    color: #37404d;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: auto;
    padding: 0;
    font-size: 18px;
    bottom: 4px;
    right: 4px;
    overflow: hidden;
    background-color: #0000;
    position: absolute;
    inset: 4px 4px auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_info {
    color: #222;
    z-index: 999;
    background: #fff;
    border-radius: 12px 12px 0 0;
    padding: 14px 16px 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }

  &_ads {
    color: var(--Neutral-Grey-50, #828282);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-bottom: 6px;
  }
}
