@import 'src/styles/variables';

.container {
  @include campaignGrid;
}

.card {
  &__form {
    & > * {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-size: 13px;
      line-height: 20px;
      color: $black;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 24px;

    &:first-of-type {
      margin-top: 0;
    }

    .card__row {
      .card__col {
        margin-right: 0;
      }
    }

    &.card__row_input-with-select {
      align-items: flex-start;
      margin-top: 6px;

      .card__col {
        &:nth-child(1) {
          position: relative;
          right: -1px;
          z-index: 2;
          max-width: calc(100% - 115px);
        }

        &:nth-child(2) {
          margin-top: 20px;
          max-width: 114px;
          z-index: 1;
          text-transform: uppercase;
        }
      }
    }
  }

  &__rowGrid {
    grid-gap: 18px 24px;
    margin-top: 24px;
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    .card__col {
      max-width: 100%;
      margin-right: 0;
    }
  }

  &__col {
    margin-right: 20px;
    flex: 1 0 auto;
    max-width: calc(50% - 10px);

    &:last-child {
      margin-right: 0;
    }
  }

  &__full {
    max-width: 100%;
  }

  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }
  & .label {
    color: #828282;
    font-size: 12px;
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
  }
  & .tags {
    position: relative;
    &:global(.disabled) {
      .add,
      .input,
      .remove {
        display: none;
      }
    }
    & .add {
      position: absolute;
      top: 0;
      right: 8px;
      height: 48px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 32px;
      z-index: 2;
      cursor: pointer;

      svg {
        color: #828282;
        &:hover {
          color: $blue;
        }
      }
    }
    & .plugin {
      position: relative;
      z-index: 1;

      & .input {
        position: relative;
      }
      & .field {
        background: $tr;
        border: 1px solid #cbcbcb;
        box-sizing: border-box;
        border-radius: 1px;
        width: 100%;
        display: block;
        height: 48px;
        text-align: left;
        padding: 0 14px;
        font-size: 14px;
        line-height: 22px;
        color: $black;

        &::placeholder {
          color: #aaaaaa;
        }
      }
      & .selected {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0 -4px;
      }
      & .tag {
        margin: 4px;
        background: #ebebeb;
        border-radius: 1px;
        padding: 0 16px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.03em;
        color: $black;
        max-width: 700px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      & .remove {
        margin-left: 8px;
        cursor: pointer;

        &:hover {
          color: $blue;
        }
      }

      & .suggestions {
        z-index: 1;
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        right: 0;
        background: $white;
        box-shadow: 0 6px 60px rgba(0, 0, 0, 0.22);
        border-radius: 1px;
        transition: all 0.1s ease;

        ul {
          li {
            height: 48px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding-left: 14px;
            padding-right: 48px;
            font-size: 14px;
            color: $black;
            background: $tr;
            transition: background 0.1s;
            cursor: pointer;

            &:hover {
              background: rgba(0, 0, 0, 0.05);
            }

            mark {
              background: $tr;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.additionl {
  display: flex;
  align-items: center;
  margin-top: 20px;
  &__label {
    margin-right: 24px;
    display: inline-block;
    color: #828282;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
  }
}

.actions {
  padding: 0;
  background: $tr;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;

  button {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }

  @include phone {
    margin-top: 0;
    margin-bottom: 8px;
    justify-content: space-between;
  }
}

.widget {
  p {
    font-size: 14px;
    line-height: 22px;
    color: $black;
  }

  a {
    color: $blue;
    transition: color 0.1s;

    &:hover {
      opacity: 1;
      color: $dark-blue;
    }
  }
}
