@import 'src/styles/variables';

.info {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 22px;
  background: #f4f5f6;

  svg {
    flex: none;
  }

  &:global(._isBlue) {
    background-color: rgba(227, 236, 255, 1);

    svg {
      color: rgba(2, 93, 255, 1);
    }
  }

  &_text {
    font-size: 12px;
    line-height: 18px;
    overflow-wrap: anywhere;
  }

  strong {
    font-weight: 500;
  }
}
