@import 'src/styles/variables';

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;

  & .item {
    font-size: 12px;
    line-height: 18px;
    color: $text-hint;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
    transition: color 0.1s;

    &:last-child {
      pointer-events: none;
      cursor: default;
    }

    &:hover {
      color: lighten($text-hint, 10%);
    }

    &:after {
      content: '/';
      display: inline-block;
      margin-left: 8px;
      color: $text-hint;
    }

    &:last-child {
      margin-right: 8px;

      &:after {
        display: none;
      }
    }
  }
}
