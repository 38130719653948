@import 'src/styles/variables';

.campaignFilters {
  padding: 28px 0 20px 0;
}

.empty {
  text-align: center;
  display: block;
  padding: 56px 0 70px;

  & .img {
    img {
      display: block;
      margin: 0 auto;
      max-height: calc(100vh - 440px);
    }
  }

  & .title {
    font-weight: 500;
    margin-top: 24px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $black;

    & .big {
      font-weight: 500;
      font-size: 16px;
    }
  }

  & .search {
    & .header {
      margin: 0 auto 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: $black;
    }

    & .text {
      margin: 0 auto;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: $black;

      span {
        display: block;
      }
    }
  }

  & .actions {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    & .link {
      display: inline-block;
      text-align: center;
      margin: 0 8px;
    }
  }
}
