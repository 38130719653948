@import 'src/styles/variables';

.form-group {
  &:global(.partially) {
    input {
      &:not(:checked) {
        & + span {
          &:before {
            border: 1px solid $blue;
            background: $blue;
          }
          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4H9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-position: center center;
            background-size: contain;
            opacity: 1;
          }
        }
      }
    }

    input {
      &:checked {
        & + span {
          &:before {
            border: 1px solid $blue;
            background: $blue;
          }
          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4H9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-position: center center;
            background-size: contain;
            opacity: 1;
          }
        }
      }
    }
  }

  &:global(.isDisabled) {
    cursor: not-allowed !important;
    & > * {
      pointer-events: none !important;
    }
    input {
      & + span {
        cursor: not-allowed;
      }
    }

    input {
      &:checked {
        & + span {
          &:before {
            border: 1px solid #828282;
            background: #828282;
          }
        }
      }
      &:not(:checked) {
        & + span {
          &:before {
            border: 1px solid #828282;
            background: #828282;
          }
        }
      }
    }
  }

  &:global(.isEmpty) {
    input {
      &:not(:checked) {
        & + span {
          &:before {
            background: #f6f6f6;
          }
        }
      }
    }
  }

  label {
    cursor: pointer;
    min-height: 22px;
  }

  .body {
    font-size: 14px;
    line-height: 21px;
    color: $black;
    padding-left: 32px;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;

    &:global(.onlyBox) {
      width: 19px;
      height: 19px;
      padding-left: 19px;
    }

    .searchTitle {
      background: #e6fce4;
    }
    .searchTitleCapitalize {
      background: #e6fce4;
      text-transform: capitalize;
    }
    .capitalize {
      text-transform: capitalize;
    }

    &:before {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      border: 1px solid $grey;
      border-radius: 1px;
      background: $tr;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: border 0.1s, background 0.1s;
    }

    &:after {
      position: absolute;
      content: '';
      width: 11px;
      height: 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      top: 50%;
      transform: translateY(-50%);
      left: 4px;
      border-radius: 1px;
      opacity: 0;
      transition: opacity 0.1s;
    }
  }

  input {
    display: none;

    &:checked + span {
      &:before {
        border: 1px solid $blue;
        background: $blue;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  &:hover {
    .body {
      &:before {
        border: 1px solid #a7a7a7;
      }
    }
  }
}
