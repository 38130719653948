@import 'src/styles/variables';
$calcBox: calc(100vw - 750px);
$scalForBigMode: calc(#{$calcBox} / 1080);

.card {
  margin: 0 auto;
  max-width: 95vw;
  width: 100%;
  display: block;
  pointer-events: all;
  box-shadow: 0 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;
  height: 95vh;
}

.body {
  background: $white;
  padding: 24px;
  display: flex;
  height: 100%;

  & .leftPanel {
    width: 373px;
    margin-right: 26px;
    background-color: $white;
    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 44px 40px;
    height: fit-content;
    flex: none;
  }

  & .settings {
    height: auto;
    .selectInput {
      margin-bottom: 48px;
    }
  }

  & .stylePanel {
    & .header {
      display: flex;
      align-items: center;
      margin-bottom: 37px;
      svg {
        flex: none;
        margin-right: 15px;
        cursor: pointer;
      }
      span {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #222222;
      }
    }
    & .grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      margin-bottom: 36px;
    }
  }

  & .main {
    max-width: calc(100% - 410px);
    background-color: $white;
    flex: 1;
    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
    border-radius: 1px;
    padding: 20px 16px 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &_container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;

      @media screen and (max-width: 1650px) {
        margin-bottom: -70px;
      }

      @media screen and (max-width: 1280px) {
        overflow-x: scroll;
      }
    }

    & .size {
      font-size: 14px;
      line-height: 20px;
      color: #222222;
    }

    & .lego {
      margin: auto;
      flex: none;
      position: relative;
      transform-origin: 0 30%;

      @media screen and (max-width: 1650px) {
        transform: scale(0.7);
      }

      @include desktopXL {
        transform: scale(0.6);
      }

      canvas {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
    }

    .uploadItem {
      position: absolute;
      z-index: 2;
      border: 2px dotted $grey;
      cursor: pointer;
      padding: 10px 30px;
      border-radius: 2px;
      strong {
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        color: #b9b9b9;
        display: block;
        text-align: center;
        transition: 0.2s;
      }

      span {
        font-size: 13px;
        line-height: 20px;
        color: #b9b9b9;
        display: block;
        text-align: center;
      }
    }
    .uploadItemText {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      &:global(.isMode) {
        left: 100px;
        transform: translateY(-50%);
      }
    }

    .uploadItemButton {
      left: 50%;
      top: calc(50% + 150px);
      transform: translate(-50%, -50%);
      &:global(.isMode) {
        left: 100px;
        transform: translateY(-50%);
      }
    }

    .uploadItemIcon {
      left: 50%;
      top: calc(50% - 150px);
      transform: translate(-50%, -50%);
      &:global(.isMode) {
        left: 100px;
        transform: translateY(-50%);
      }
    }

    .uploadItemIllustration {
      right: 100px;
      top: 100px;
      width: 300px;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
    }

    & .legoButtons {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 50%;
      z-index: 2;

      & .uploadItem {
        border: 2px dotted $grey;
        cursor: pointer;
        padding: 10px 30px;
        border-radius: 2px;

        &:hover {
          strong {
            color: #858383;
          }
        }

        strong {
          font-weight: 500;
          font-size: 22px;
          line-height: 28px;
          color: #b9b9b9;
          display: block;
          text-align: center;
          transition: 0.2s;
        }

        span {
          font-size: 13px;
          line-height: 20px;
          color: #b9b9b9;
          display: block;
          text-align: center;
        }
      }
    }

    & .buttons {
      display: flex;
      justify-content: space-between;
    }
  }
}
