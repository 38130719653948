@import 'src/styles/variables';

.card {
  padding: 28px 24px;
}

.token {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 10px;

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 0 20px;

      svg {
        &:hover {
          color: $blue;
          cursor: pointer;

          path {
            stroke: $blue;
          }
        }
      }
    }
  }

  .body {
    font-size: 12px;
    line-height: 18px;
    word-break: break-all;
  }

  .copy_icon {
    transition: 0s;
    &:hover {
      cursor: pointer;
    }

    &:global(.isDisabled) {
      color: $green;
      pointer-events: none;
      &:hover {
        cursor: default;
      }
    }
  }
}
