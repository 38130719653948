@import 'src/styles/variables';

.filter {
  position: relative;

  & .active {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:global(.isOpened) {
      .chevron {
        transform: rotate(180deg);
      }
    }

    & .type {
      width: 12px;
      height: 12px;
      margin-left: 1px;        
      border-radius: 50%;

      &:global(.isAll) {
        background: url('./all.svg');
        background-position: center;
        background-size: cover;
      }
      &:global(.isTranslate) {
        background: #149c08;
        border: 1px solid #d1d0d0;
      }
      &:global(.isNoTranslate) {
        background: #ff3636;
        border: 1px solid #d1d0d0;

      }
      &:global(.isUnknown) {
        background: #ff9c08;
        border: 1px solid #d1d0d0;
      }
    }

    & .chevron {
      display: block;
      flex: none;
      transition: transform 0.1s;
    }
  }
}

.list {
  position: fixed;
  background: $white;
  box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
  z-index: -1;
  opacity: 0;
  transform: translateY(-20px);
  transition: transform 0.3s;

  &:global(.isOpened) {
    transform: translateY(0);
    opacity: 1;
    z-index: 1000;
  }

  & .item {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    display: flex;
    align-items: center;
    padding: 8px 17px 8px 9px;
    cursor: pointer;

    &:hover {
      background: #f6f6f6;

      .text {
        color: #222;
      }

      & .toggle {
        :global(.text) {
          color: #222;
        }
      }
    }

    &:global(.isSelected) {
      background: #F6F6F6;
    }

    & .toggle {
      :global(.text) {
        color: #828282;
        margin-left: 9px;
      }
    }

    & .type {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: url('./all.svg');
      background-position: center;
      background-size: cover;

      &:global(.no_bg) {
        border-radius: 0;
        background: none;
        width: auto;
        height: auto;
        svg {
          width: 18px;
          height: 18px;
          vertical-align: middle;
        }
      }
    }

    & .text {
      color: #828282;
      margin-left: 9px;
    }
  }
}

.checkCircle {
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    color: #149c08 !important;
    height: 20px;
    width: 20px;
  }
}
