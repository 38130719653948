@import 'src/styles/variables';

.errorNoAcess {
  .plug {
    min-height: calc(100vh - 64px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      margin-top: 30px;
    }
  }
}