@import 'src/styles/variables';

.toast {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: $black;
  width: 100%;
  word-break: break-word;
  display: flex;

  b,
  strong {
    font-weight: 700;
  }

  a {
    color: $blue;
  }

  & .icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:global(._isSuccess) {
      background: $green;
    }

    &:global(._isError) {
      background: $red;
    }
  }

  & .text {
    padding-left: 16px;
    width: calc(100% - 32px);

    span {
      color: #fff;
    }

    &:global(._isError) {
      padding-top: 8px;
    }
  }

  & .links {
    margin-top: 10px;

    a {
      color: #0a0a0a;
      line-height: 1;

      strong {
        text-decoration: underline;
        color: #025dff;
        font-weight: 400;
        line-height: 1;
        padding-bottom: 0;
      }

      span {
        margin-right: 4px;
        color: #0a0a0a !important;
      }
    }
  }
}

.card {
  margin: 0 auto;
  max-width: 774px;
  width: 100%;
  background: $white;
  display: block;
  pointer-events: all;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;
  position: relative;

  & .copying {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: $white;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    &:global(.isActive) {
      opacity: 1;
      visibility: visible;
    }

    & .title {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      color: $black;
    }

    & .progress {
      margin-top: 32px;
      margin-bottom: 16px;
      width: 494px;
      height: 6px;
      background: #e2e2e2;
      border-radius: 1px;
      position: relative;

      & .bar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: $blue;
        border-radius: 1px;
        // transition: width 0.1s;
      }
    }

    & .hint {
      font-size: 13px;
      line-height: 20px;
      color: $black;
    }
  }

  & .form {
    position: relative;
    z-index: 1;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 28px;

  & .title {
    .name {
      margin-top: 3px;
    }

    p {
      margin-top: 20px;
      font-size: 13px;
      line-height: 20px;
      color: $black;
      margin-bottom: 4px;
    }
  }

  & .close {
    margin-left: 24px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    justify-content: center;
    align-items: center;
    flex: none;

    svg {
      cursor: pointer;
      color: $black;
    }
  }
}

.content {
  padding: 0 28px;

  & .breadcrumbs {
    margin-bottom: 16px;
  }

  & .select {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &_checkbox {
      flex: 0.5;
      padding-left: 15px;
    }
  }

  & .search {
    border: 1px solid #ebebeb;
    flex: 1;
    position: relative;

    label {
      input {
        border: none;
        height: 40px;
        display: block;
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        padding: 0 15px;

        &::placeholder {
          color: #828282;
        }
      }
    }

    &_icon {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & .list {
    border: 1px solid #ebebeb;
    overflow-y: auto;
    overflow-x: hidden;
    @include fixResizeCopyModal;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $tr;
    }

    &::-webkit-scrollbar-thumb {
      background: #dddddd;
      border-radius: 15px;
    }

    & > p {
      font-size: 14px;
      line-height: 22px;
      margin: 24px 16px;
    }

    & > div {
      border-bottom: 1px solid #ebebeb;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 28px 28px;

  & .checkbox_pretarget {
    margin-left: 0;
    margin-right: auto;
  }

  & > * {
    margin-left: 20px;
  }
}

.errors {
  & .list {
    border: 1px solid #ebebeb;
    border-radius: 1px;
    overflow-y: auto;
    overflow-x: hidden;

    @include fixResizeCopyModal;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $tr;
    }

    &::-webkit-scrollbar-thumb {
      background: #dddddd;
      border-radius: 15px;
    }

    & > * {
      border-bottom: 1px solid #ebebeb;
    }
  }
}
