@import 'src/styles/variables';

.creator {
  background: #e3ecff;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #E3ECFF80;
  }

  svg {
    color: #025dff;
  }
}
