@import 'src/styles/variables';

.groupHint {
    position: fixed;
    opacity: 1;
    z-index: 1000;
    left: 1px;
    top: 1px;
    padding: 8px 14px;
    color: #fff;
    text-transform: none;
    width: fit-content;
    max-width: 300px;
    height: fit-content;
    background: #ffffff;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 4px;

    &:global(.fixTopPadding) {
        transform: translateY(50%);
    }

    .group {
        display: inline-block;
        padding: 6px 8px;
        background: #e3ecff;
        border-radius: 8px;
        margin: 4px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #222222;
    }
}