@import 'src/styles/variables';

.header {
  background: $white;
  height: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & .container {
    display: grid;
    grid-template-columns: auto auto;

    & .info {
      & .status {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-height: 40px;

        & .title {
          font-size: 24px;
          word-break: break-all;
        }
      }
    }
  }
}
