@import 'src/styles/variables';

.profile {
  & .content {
    @include campaignGrid;

    @include phone {
      grid-gap: 10px;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
    }
  }
}
