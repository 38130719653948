@import 'src/styles/variables';

.filter {
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 0 32px;

  & .items {
    display: grid;
    grid-auto-rows: auto;
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(100px, 180px));
    grid-gap: 0 24px;

    & > div {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      cursor: pointer;
      width: 100%;

      &:hover {
        div {
          svg {
            opacity: 1;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:global(.isOff) {
        .name {
          text-decoration: line-through;
        }
      }

      & .tooltip {
        &:hover {
          & > div:first-child {
            .name {
              color: $blue;
            }
          }
        }
        & > div:first-child {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
        & > div:last-child {
          width: auto;
          white-space: nowrap;
        }
      }

      & .name {
        padding-left: 8px;
        max-width: calc(100% - 16px);
        word-break: break-all;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        transition: color 0.1s;
      }

      & .checkbox {
        margin-top: 2px;
        width: 16px;
        height: 16px;
        background: $black;
        border-radius: 1px;
        position: relative;
        display: block;

        &:global(._active) {
          &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.44444 1.3335L3.94444 6.66683L1.44444 4.24259' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            position: absolute;
            z-index: 2;
            top: 0;
            left: 1px;
            bottom: 0;
            right: 0;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  & .dropdown {
    align-self: start;
    display: inline-block;
    position: relative;
    margin-bottom: 16px;

    & .selected {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      cursor: pointer;

      svg {
        margin-left: 8px;
        transition: transform 0.1s;
      }
    }

    &:global(._isOpened) {
      & .selected {
        svg {
          transform: rotate(180deg);
        }
      }
      & .list {
        pointer-events: all;
        opacity: 1;
        transform: translateY(0px);
      }
    }

    & .list {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-20px);
      top: calc(100% + 8px);
      left: 0;
      box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
      border-radius: 1px;
      z-index: 2;
      background: $white;
      padding: 2px 0;
      transition: opacity 0.1s, transform 0.1s;
      min-width: 114px;
      max-height: 220px;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: $tr;
      }

      &::-webkit-scrollbar-thumb {
        background: #dddddd;
        border-radius: 15px;
      }

      & .item {
        font-size: 14px;
        line-height: 22px;
        color: $black;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 32px;
        padding: 0 15px;
        cursor: pointer;

        &:global(._isActive) {
          color: #828282;
        }

        &:hover {
          background: #f6f6f6;
        }

        span {
          white-space: nowrap;
        }
      }
    }
  }
}
