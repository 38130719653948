@import 'src/styles/variables';

.loaderClient {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }
  &__form {
    & > * {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-size: 13px;
      line-height: 20px;
      color: $black;
    }
  }

  & :global(.react-tabs) {
    ul {
      li {
        display: flex;
        position: relative;
        align-self: stretch;
        align-items: center;

        svg {
          top: 1px;
        }

        &:global(._error) {
          color: $red;
          span {
            color: $red !important;
          }

          svg {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(120%, -80%);
          }
        }
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 24px;

    &:first-of-type {
      margin-top: 0;
    }

    &.card__row_nm {
      .card__col {
        margin-right: 0;
      }
    }

    &.card__row_input-with-select {
      .card__col {
        &:nth-child(1) {
          position: relative;
          right: -1px;
          z-index: 1;
          max-width: calc(100% - 115px);
        }

        &:nth-child(2) {
          max-width: 114px;
        }
      }
    }
  }

  &__col {
    margin-right: 20px;
    flex: 1 0 auto;
    max-width: calc(100% - 10px);

    &:global(.extend) {
      max-width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .warning {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    svg {
      vertical-align: middle;
      margin-right: 0;
    }
    span {
      color: #ff9c08;
    }
  }
}

.tabs-list {
  & .error {
    color: $red;
  }
}

.tooltip {
  &:first-child {
    margin-left: 0;
  }

  & > div {
    font-size: 13px;
  }
}

.customOptions {
  height: 40px;
  padding: 5px 11px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;

  &:hover {
    background-color: #f6f6f6;
  }

  label {
    text-transform: capitalize;
    margin-bottom: 0;
  }

  &__show {
    background: #f6f6f6;
    border-radius: 12px;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 18px;
    margin-right: 8px;
  }

  &:global(._isSub) {
    padding-left: 20px;
    font-weight: 400;
  }

  &:global(.isShow) {
    padding-left: 15px;
    height: 49px;
    cursor: auto;
  }

  &:global(.isEmpty) {
    padding: 0 0;
  }
}
